// Unobtrusive React components for Hotwire.
//
// See also ReactHelper#react_component.
//
// Usage:
// <%= react_component("filename", props: { ... }) %>
// <%= react_component("filename.ComponentName", props: { ... }) %>
//
// The former will load the default export from app/javascript/components/filename.js.
// The latter will load the named export from app/javascript/components/filename.js.
//
//
// Turbo Application Visits:
//
// Turbo has two types of Visits, Application Visits and Restoration Visits. The former
// happens when you click a link or submit a form. The latter happens when you use the
// forward or back buttons.
//
// Restoration Visits restore the DOM from Turbo's cache. Application Visits restore the
// DOM from the cache, then make a request to the server to get the latest HTML. This means
// that in an Application Visit, each React component will be mounted twice: once for the
// cached DOM, and once for the new DOM. This means that any side effects that happen on
// component mount will happen twice. Make sure to write your components with this in mind.
// E.g. you probably don't wan't to make mutating API calls on mount.
//
// 
// Differences from vanilla React:
//
// Normally, componentWillUnmount and useEffect cleanup functions are not called when you
// navigate to a new page by clicking a link. With this Stimulus controller, cleanup
// functions are called on page navigation.
//
// We do this because Turbo preserves the JavaScript context between page navigations and we
// want to make sure that components have a chance to clean up any global event listeners,
// timers, etc. that they create.

import { Controller } from "@hotwired/stimulus"

import React from "react"
// React 18:
// import * as ReactDOM from "react-dom/client"
import * as ReactDOM from "react-dom"

import * as components from "../components"

// Connects to data-controller="react"
export default class extends Controller {
  static values = { component: String, props: Object }

  connect() {
    let [namespace, name] = this.componentValue.split('.')
    name ||= "default"

    const Component = components[namespace][name]

    if (!Component) {
      console.error(`Unable to find load React component ${this.componentValue}. Is app/javascript/components/index.js up to date?`)
      return
    }

    // React 18:
    // this.root = ReactDOM.createRoot(this.element)
    // this.root.render(<component {...this.propsValue} />)

    ReactDOM.render(<Component {...this.propsValue} />, this.element)
  }

  disconnect() {
    // React 18:
    // if (this.root) {
    //   this.root.unmount()
    // }

    ReactDOM.unmountComponentAtNode(this.element)
  }
}
