import React, { useState,  useRef, useEffect } from 'react'
import _ from 'underscore'
import moment from 'moment'

import RestClient from './rest_client'
import useActionCable from './use_action_cable'
import useInterval from './use_interval'
import useTitle from './use_title'
import { playSound } from './utils'

const CELEBRATION_DURATION = 5  // Seconds.

const Api = {
  sharedTimers: new RestClient('shared_timers')
}

export default function SharedTimer ({audioRoom, setCanvasNeedsRedraw}) {
  // UI state.
  const [isLoading, setIsLoading] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [isShowingPopup, setIsShowingPopup] = useState(false)
  const [timerText, setTimerText] = useState(null)
  const [secondaryTimerText, setSecondaryTimerText] = useState(null)

  // Pomodoro state.
  const [mostRecentTimerStartedByMe, setMostRecentTimerStartedByMe] = useState(false)
  const [duration, setDuration] = useState(25)
  const [shouldRepeat, setShouldRepeat] = useState(false)
  const [breakDuration, setBreakDuration] = useState(5)
  const [startedAt, setStartedAt] = useState(null)
  const [sharedTimerId, setSharedTimerId] = useState(null)

  // Element refs.
  const durationInputRef = useRef(null)
  const breakInputRef = useRef(null)

  // Track pomodoro cycle stage to trigger sound effects.
  const wasInBreakOnPrevTick = useRef(null)
  useEffect(() => {
    if (!isActive) {
      wasInBreakOnPrevTick.current = null
    }
  }, [isActive])

  const updateTimerFromRemote = payload => {
    setDuration(payload.duration)
    setShouldRepeat(payload.repeat)
    setBreakDuration(payload.break_duration)
    setStartedAt(payload.started_at)
    setSharedTimerId(payload.id)
    // Note: this is not exactly right, since the timer may have already run
    // out. We’ll catch it in the first `useInterval` tick, so it isn’t a big
    // deal. But it would be nicer to have `isActive` derived from other state.
    setIsActive(true)
  }

  useActionCable({channel: 'SharedTimerChannel', audio_room_id: audioRoom.id}, {
    received(event) {
      switch(event.type) {
      case 'index':
        if (event.payload) {
          updateTimerFromRemote(event.payload)
        }
        setIsLoading(false)
        break
      case 'save':
        if (mostRecentTimerStartedByMe) {
          setSharedTimerId(event.payload.id)
          setMostRecentTimerStartedByMe(false)
        } else {
          updateTimerFromRemote(event.payload)
        }
        break
      case 'destroy':
        setStartedAt(null)
        setIsActive(false)
        setSharedTimerId(null)
        setTimerText(null)
        setSecondaryTimerText(null)
        break
      }
    }
  })

  const handleMainButtonClick = async () => {
    if (isActive) {
      if (!confirm('Are you sure you want to stop the timer for everyone?')) return
      try {
        await Api.sharedTimers.destroy(sharedTimerId)
      } catch (err) {
        console.log(err)
        alert('Something went wrong')
      }
    } else {
      setIsShowingPopup(x => !x)
    }
  }

  const handleDurationChange = e => {
    e.preventDefault()
    setDuration(parseInt(e.target.value, 10))
  }

  const handleShouldRepeatChange = e => {
    setShouldRepeat(e.target.checked)
  }

  const handleBreakDurationChange = e => {
    e.preventDefault()
    setBreakDuration(parseInt(e.target.value, 10))
  }

  const startTimer = async () => {
    let apiCall
    if (sharedTimerId != null) {
      apiCall = Api.sharedTimers.update.bind(null, sharedTimerId)
    } else {
      apiCall = Api.sharedTimers.create
    }

    try {
      const startedAt = new Date().toISOString()
      setIsActive(true)
      setStartedAt(startedAt)
      setMostRecentTimerStartedByMe(true)

      await apiCall({
        audio_room_id: audioRoom.id,
        duration: duration,
        repeat: shouldRepeat,
        break_duration: breakDuration,
        started_at: startedAt,
      })

    } catch (err) {
      console.log(err)
      alert("Something went wrong")

      setIsActive(false)
      setStartedAt(null)
      setMostRecentTimerStartedByMe(false)
    }
  }

  const previousMinutesRemaining = useRef(null)

  useInterval(() => {
    const elapsedTime = moment().diff(startedAt, 'seconds')
    const durationInSeconds = duration * 60
    const breakDurationInSeconds = breakDuration * 60

    let secondsRemaining = 0

    if (shouldRepeat) {
      const fullDuration = durationInSeconds + breakDurationInSeconds
      const elapsedTimeInCycle = elapsedTime % fullDuration
      const nCyclesCompleted = Math.floor(elapsedTime / fullDuration)
      const isInBreak = elapsedTimeInCycle >= durationInSeconds
      secondsRemaining = (isInBreak ? fullDuration : durationInSeconds) - elapsedTimeInCycle
      const timestamp = moment.utc(secondsRemaining * 1000).format('mm:ss')

      if (isInBreak) {
        setTimerText(`😴 ${timestamp}`)
        setSecondaryTimerText(`of ${breakDuration} min break`)
      } else {
        setTimerText(`🍅 ${timestamp}`)
        setSecondaryTimerText(`of ${duration} min timer`)
      }

      // Play sound effects if the pomodoro stage has transitioned.
      if (wasInBreakOnPrevTick.current === null) {
        // Don’t play anything if the timer just started.
        wasInBreakOnPrevTick.current = isInBreak
      } else if (isInBreak !== wasInBreakOnPrevTick.current) {
        if (isInBreak) {
          playSound('grid-world-timer-end-sound')
        } else if (nCyclesCompleted % 8 === 0) {
          playSound('grid-world-break-end-long-sound')  // ⚾️🧢🌭
        } else {
          playSound('grid-world-break-end-sound')
        }

        wasInBreakOnPrevTick.current = isInBreak
      }
    } else if (elapsedTime < durationInSeconds) {
      secondsRemaining = durationInSeconds - elapsedTime
      const timestamp = moment.utc(secondsRemaining * 1000).format('mm:ss')
      setTimerText(`⏲ ${timestamp}`)
      setSecondaryTimerText(`of ${duration} min timer`)
    } else if (elapsedTime < durationInSeconds + CELEBRATION_DURATION) {
      setTimerText(`🎉 00:00`)
      setSecondaryTimerText('congrats!')
      playSound('grid-world-timer-end-sound')
    } else {
      setTimerText(null)
      setSecondaryTimerText(null)
      setIsActive(false)
    }

    let minutesRemaining = null
    if (shouldRepeat || elapsedTime < durationInSeconds) {
      minutesRemaining = Math.floor(secondsRemaining/60)
    }

    if (minutesRemaining !== previousMinutesRemaining.current) {
      setCanvasNeedsRedraw()
    }

    previousMinutesRemaining.current = minutesRemaining

  }, isActive ? 500 : null, { immediate: true })

  useTitle(timerText)

  const extraMainButtonProps = (isLoading || !timerText) ? {} : {
    title: "Stop timer",
  }

  return <>
    <button
      className="button bg-green m-a-0 line-height-small"
      onClick={ handleMainButtonClick }
      disabled={isLoading}
      {...extraMainButtonProps}
    >
      {timerText
      ? <span className="tabular-nums">
        {timerText}
        {secondaryTimerText && <span className="font-style-italic m-l-half">{secondaryTimerText}</span>}
      </span>
      : 'Set timer'}
    </button>

    {
      (isShowingPopup && !isActive) ?
      <div className="z-100 display-flex flex-direction-column position-absolute box-shadow with-border-radius bg-tint-green p-a-2" style={{left: 0, bottom: 'calc(100% + 12px)'}}>
        <button
          className="p-a-half no-border position-absolute no-bg color-gray hover-color-darkest-gray cursor-pointer"
          style={{ top: 4, right: 4 }}
          onClick={() => setIsShowingPopup(false)}
          title="Close"
        >
          <i className="fas fa-times" />
        </button>
        <div className="color-blue font-size-xlarge p-b-2">Shared timer</div>
        <div className="display-grid p-b-2" style={{gridTemplateColumns: 'auto 1fr', gap: 12}}>
          <label htmlFor="pomodoro-duration">Duration</label>
          <div>
            <input ref={durationInputRef} id="pomodoro-duration" type="number" value={duration} size={2} onChange={handleDurationChange} step={1} min={1} max={60} />
            {' '}
            <span onClick={() => durationInputRef.current?.focus()}>min</span>
          </div>
          <label htmlFor="pomodoro-repeat">Repeat</label>
          <div>
            <input id="pomodoro-repeat" type="checkbox" className="m-l-0" checked={shouldRepeat} onChange={handleShouldRepeatChange} />
          </div>
          <label htmlFor="pomodoro-break" className={shouldRepeat ? '' : 'color-gray'}>Break</label>
          <div className={shouldRepeat ? '' : 'color-gray'}>
            <input ref={breakInputRef} id="pomodoro-break" type="number" disabled={!shouldRepeat} value={breakDuration} size={2} onChange={handleBreakDurationChange} step={1} min={1} max={30} />
            {' '}
            <span onClick={() => breakInputRef.current?.focus()}>min</span>
          </div>
        </div>
        <button className="button bg-green" style={{margin: '0 auto'}} onClick={startTimer}>Start timer</button>
      </div> :
      null
    }
  </>
}
