import React from 'react'
import _ from 'underscore'

import { classSet } from './utils';

const { PureComponent} = React

import ViewportContext from './viewport_context'

// How to place a popover:
//
// - Start with the ideal placement. This is the placement the parent
//   component specified and where you would want the popover to go if
//   there were no constraints.
//
// - Get the progression for that placement. The progression is a list
//   of all placements sorted by desirability. Each ideal placement has
//   a different progression. All progressions start with their ideal
//   placement (i.e. if your ideal placement is 'top', the first
//   placement in your progression is 'top').
//
// - Find the first placement in the progression where you can place
//   the popover without it going off the screen.
const PROGRESSIONS = {
  top: ['top', 'right', 'left', 'bottom'],
  right: ['right', 'top', 'bottom', 'left'],
  bottom: ['bottom', 'right', 'left', 'top'],
  left: ['left', 'top', 'bottom', 'right'],
}

export default class Popover extends PureComponent {
  static defaultProps = {
    placement: 'top',
  };

  static contextType = ViewportContext

  state = {
    style: null,
  }

  popoverRef = React.createRef()
  wrappedRef = React.createRef()

  componentDidMount() {

    // Ensure browser layout has completed so that widths are correct
    setTimeout(() => this.setPopoverPosition(), 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.placement !== this.props.placement || !_.isEqual(prevProps.wrapperStyle, this.props.wrapperStyle)) {
      this.setPopoverPosition()
    }
  }

  placement() {
    const popover = this.popoverRef.current
    const wrapped = this.wrappedRef.current

    const idealPlacement = this.props.placement

    if (!popover || !wrapped) {
      return idealPlacement
    }

    const wrappedRect = wrapped.getBoundingClientRect()

    const wrappedBottom = wrappedRect.top + wrappedRect.height
    const wrappedRight = wrappedRect.left + wrappedRect.width
    const wrappedCenterX = wrappedRect.left + wrappedRect.width/2
    const wrappedCenterY = wrappedRect.top + wrappedRect.height/2

    const viewportWidth = this.context.width
    const viewportHeight = this.context.height

    const fitsOnTop = wrappedRect.top - popover.offsetHeight >= 0
    const fitsOnLeft = wrappedRect.left - popover.offsetWidth >= 0
    const fitsOnRight = wrappedRight + popover.offsetWidth < viewportWidth
    const fitsOnBottom = wrappedBottom + popover.offsetHeight < viewportHeight

    const halfFitsOnTop = wrappedCenterY - popover.offsetHeight/2 >= 0
    const halfFitsOnLeft = wrappedCenterX - popover.offsetWidth/2 >= 0
    const halfFitsOnRight = wrappedCenterX + popover.offsetWidth/2 < viewportWidth
    const halfFitsOnBottom = wrappedCenterY + popover.offsetHeight/2 < viewportHeight

    const progression = PROGRESSIONS[idealPlacement]

    const placement = _.find(progression, p => {
      switch (p) {
        case 'top':
          return fitsOnTop && halfFitsOnLeft && halfFitsOnRight;
        case 'left':
          return fitsOnLeft && halfFitsOnTop && halfFitsOnBottom;
        case 'bottom':
          return fitsOnBottom && halfFitsOnLeft && halfFitsOnRight;
        case 'right':
          return fitsOnRight && halfFitsOnTop && halfFitsOnBottom;
      }
    })

    return placement || idealPlacement
  }

  setPopoverPosition() {
    const popover = this.popoverRef.current
    const wrapped = this.wrappedRef.current
    const { style } = this.state

    const placement = this.placement()

    let { offset } = this.props;

    if (offset) {
      offset = `+ ${offset}`;
    } else {
      offset = "";
    }

    if (popover && wrapped) {
      if (placement === 'top') {
        this.setState({
          style: {
            left: (wrapped.offsetWidth - popover.offsetWidth) / 2 + 'px',
            bottom: `calc(100% + 0.3rem ${offset})`,
          }
        })
      } else if (placement === 'bottom') {
        this.setState({
          style: {
            left: (wrapped.offsetWidth - popover.offsetWidth) / 2 + 'px',
            top: `calc(100% + 0.3rem ${offset})`,
          }
        })
      } else if (placement === 'right') {
        this.setState({
          style: {
            top: (wrapped.offsetHeight - popover.offsetHeight) / 2 + 'px',
            left: `calc(100% + 0.6rem ${offset})`,
          }
        })
      } else if (placement === 'left') {
        this.setState({
          style: {
            top: (wrapped.offsetHeight - popover.offsetHeight) / 2 + 'px',
            right: `calc(100% + 0.6rem ${offset})`,
          }
        })
      }

      if (this.props.fixedWidth && !this.state.width) {
        this.setState({width: popover.offsetWidth})
      }
    }
  }

  render() {
    const { content, alwaysVisible, wrapperStyle, popoverStyle } = this.props;
    const { style, width } = this.state

    const wrapperClassName = classSet({
      'popover': true,
      'popover--always-visible': alwaysVisible && style,
      [this.props.wrapperClassName]: !!this.props.wrapperClassName,
    });

    const popoverClassName = classSet({
      'popover__popover': true,
      'visibility-hidden': !style,
      [this.props.popoverClassName]: !!this.props.popoverClassName,
    });

    const contentClassName = classSet({
      "popover__wrapped": true,
      [this.props.contentClassName]: !!this.props.contentClassName,
    })

    let mergedStyles = {
      ...style,
      ...popoverStyle,
    }

    if (width) {
      mergedStyles.width = width
    }

    return (
      <div className={ wrapperClassName } style={ wrapperStyle }>
        <div className={ popoverClassName } ref={ this.popoverRef } style={ mergedStyles }>
          { content }
        </div>

        <div className={ contentClassName } ref={ this.wrappedRef }>
          { this.props.children }
        </div>
      </div>
    );
  }
}
