import React, { useState, useRef, useEffect } from 'react'

import Picker from '@emoji-mart/react'

import { Emoji, emojiSet, getEmojiSpritesheetURL } from './emoji_utils'
import useEventListener from './use_event_listener'
import { classSet } from './utils'
import ClickOutside from './click_outside'

export default function EmojiAndTextInput({emoji, text, id, placeholder, autofocus, onEmojiChange, onTextChange, onShowEmojiPicker, onHideEmojiPicker}) {
  const [showPicker, setShowPicker] = useState(false)
  const [textFieldFocused, setTextFieldFocused] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (showPicker && onShowEmojiPicker) {
      onShowEmojiPicker()
    } else if (!showPicker && onHideEmojiPicker) {
      onHideEmojiPicker()
    }
  }, [showPicker])

  function selectEmoji(emoji) {
    onEmojiChange(emoji.native)
    setShowPicker(false)
  }

  function handleTextChange(e) {
    onTextChange(e.target.value)
  }

  function handleFocus() {
    setTextFieldFocused(true)
  }

  function handleBlur() {
    setTextFieldFocused(false)
  }

  function handleKeyDown(e) {
    if (e.key === 'Escape' && showPicker) {
      setShowPicker(false)
    }
  }

  useEventListener('keydown', handleKeyDown, document)

  const classes = classSet({
    "emoji-and-text-input": true,
    "emoji-and-text-input--focused": textFieldFocused,
  })

  return (
    <div className={ classes }>
      <div className="emoji-and-text-input__button-container">
        <button
          className="emoji-and-text-input__emoji-button"
          onClick={ () => setShowPicker(!showPicker) }
          aria-label="Pick an emoji"
        >
          <Emoji
            native={emoji}
            size={20}
          />
        </button>
      </div>

      <input
        id={ id }
        type="text"
        className="emoji-and-text-input__text-field"
        value={ text }
        onChange={ handleTextChange }
        placeholder={ placeholder }
        ref={ inputRef }
        onFocus={ handleFocus }
        onBlur={ handleBlur }
      />

      {
        showPicker ?
        <ClickOutside onClick={ () => setShowPicker(false) }>
          <div className="emoji-and-text-input__picker">
            <Picker
              set={ emojiSet }
              onEmojiSelect={ selectEmoji }
              getSpritesheetURL={ getEmojiSpritesheetURL }
              autoFocus
            />
          </div>
        </ClickOutside> :
        null
      }
    </div>
  )
}
