import React, {useState, useRef, useEffect} from 'react'
import _ from 'underscore'
import YouTube from 'react-youtube'
import moment from 'moment'
import { useLocation } from "react-router-dom"

import RestClient from './rest_client'
import useActionCable from './use_action_cable'
import useInterval from './use_interval'
import useStickyState from './use_sticky_state'
import {uuid, classSet} from './utils'

import Marquee from './marquee'

const Api = {
  mediaPlayers: new RestClient('media_players')
}

const WIDTH = '100%'
const HEIGHT = '100%'

const videoOptions = {
  height: HEIGHT,
  width: WIDTH,
  playerVars: {
    controls: 0,
    disablekb: 1,
    fs: 0,
    modestbranding: 1,
    playsinline: 1,
    rel: 0,
  },
}

function offsetFromMediaPlayer(mediaPlayer, options = {}) {
  const liveBroadcast = options.liveBroadcast || false

  let offset = mediaPlayer.offset

  if (!liveBroadcast && mediaPlayer.state === 'playing' && mediaPlayer.pressed_play_at) {
    offset += moment().diff(mediaPlayer.pressed_play_at, 'seconds')
  }

  return Math.max(0, offset)
}

export function mediaPlayerIsPlaying(mediaPlayer, duration) {
  if (!mediaPlayer || mediaPlayer.state !== 'playing') {
    return false
  }

  const liveBroadcast = duration === 0

  if (liveBroadcast) {
    return true
  }

  const offset = offsetFromMediaPlayer(mediaPlayer, {liveBroadcast})

  return offset < duration
}

function currentOffset(mediaPlayer, youtubePlayer) {
  if (youtubePlayer.getPlayerState() === YouTube.PlayerState.BUFFERING || youtubePlayer.getPlayerState() === YouTube.PlayerState.UNSTARTED) {
    return offsetFromMediaPlayer(mediaPlayer, {liveBroadcast: youtubePlayer.getDuration() == 0})
  } else {
    return youtubePlayer.getCurrentTime()
  }
}

function syncPlayingState(mediaPlayer, youtubePlayer) {
  const shouldBePlaying = mediaPlayer.state == 'playing'
  const playerState = youtubePlayer.getPlayerState()

  // YouTube IFrame API says seekTo will play unless the player state is paused.
  // We have determined through experimentation that seekTo will play unless the
  // player state is PAUSED or UNSTARTED.
  const seekToWillPlay = playerState !== YouTube.PlayerState.PAUSED && playerState !== YouTube.PlayerState.UNSTARTED

  const offset = offsetFromMediaPlayer(mediaPlayer, {liveBroadcast: youtubePlayer.getDuration() == 0})
  youtubePlayer.seekTo(offset, true)

  const stateNames = _.invert(YouTube.PlayerState)
  console.log(`shouldBePlaying=${shouldBePlaying} playerState=${stateNames[playerState]} seekToWillPlay=${seekToWillPlay}`)

  if (shouldBePlaying) {
    console.log('youtubePlayer.playVideo()')
    youtubePlayer.playVideo()
  } else if (playerState !== YouTube.PlayerState.ENDED) {
    console.log('youtubePlayer.pauseVideo()')
    youtubePlayer.pauseVideo()
  }
}

export default function YoutubePlayer (props) {
  // The container shows a brief shaking animation if an invalid YouTube URL is submitted.
  const [isShaking, setIsShaking] = useState(false)
  return <div
    className={isShaking ? 'shake' : ''}
    onAnimationEnd={() => setIsShaking(false)}
  >
    <YoutubePlayerInner
      setIsShaking={setIsShaking}
      audioRoom={props.audioRoom}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      getUserHasInteracted={props.getUserHasInteracted}
    />
  </div>
}

function caller() {
  const stack = new Error().stack
  return stack.split("\n")[3].trim()
}

function YoutubePlayerInner (props) {
  const clientId = useRef(uuid())

  const [mediaPlayer, setMediaPlayer0] = useState(null)
  function setMediaPlayer(newMediaPlayer) {
    console.log("setMediaPlayer", caller())
    setMediaPlayer0(newMediaPlayer)
  }

  // LocalMediaPlayer is used for seeking. It holds state (like the seek position)
  // while the user is seeking, so that the user can have a good experience while
  // seeking without effecting the experience of other people watching.
  //
  // i.e. when you are dragging the seek bar, nobody else's seek bar should move.
  const [localMediaPlayer, setLocalMediaPlayer0] = useState(null)

  function setLocalMediaPlayer(newMediaPlayer) {
    console.log("setLocalMediaPlayer", caller())
    setLocalMediaPlayer0(newMediaPlayer)
  }

  const [loading, setLoading] = useState(true)
  const [ready, setReady] = useState(false)
  const [showingForm, setShowingForm] = useState(false)

  const [isMuted, setIsMuted] = useStickyState(false, 'YoutubePlayerInner.isMuted')
  const [volume, setVolume] = useStickyState(30, 'YoutubePlayerInner.volume')
  const isPlaying = mediaPlayer?.state === 'playing'
  const isSeeking = !!localMediaPlayer

  const [videoUrl, setVideoUrl] = useState('')
  const [title, setTitle] = useState('')

  const player = useRef(null)

  const [timestamp, setTimestamp] = useState(0)
  const [duration, setDuration] = useState(0)
  const [mediaPlayerChangedWhileSeeking, setMediaPlayerChangedWhileSeeking] = useState(false)

  const [mostRecentVideoSelectedByMe, setMostRecentVideoSelectedByMe] = useState(false)

  const [showingVideo, setShowingVideo] = useState(false)
  const location = useLocation()

  const showingPanel = location.pathname !== '/'

  function handleSeekStart() {
    setLocalMediaPlayer({
      ...mediaPlayer,
      state: 'paused',
    })
  }

  function handleSeekChange(newOffset) {
    newOffset = parseInt(newOffset, 10)
    console.log(`Setting the new offset to ${newOffset}`)
    setTimestamp(newOffset)
    setLocalMediaPlayer(prev => ({
      ...prev,
      offset: newOffset,
    }))
  }

  function handleSeekEnd() {
    if (!mediaPlayerChangedWhileSeeking) {
      const seekingToEnd = localMediaPlayer.offset >= duration

      const newState = seekingToEnd ? 'paused' : mediaPlayer.state
      console.log(`newState=${newState}`)

      if (newState === 'paused') {
        subscription.pause(localMediaPlayer.offset)
      } else {
        subscription.play(localMediaPlayer.offset)
      }

      setMediaPlayer({
        ...localMediaPlayer,
        client_id: null,
        state: newState,
        pressed_play_at: newState === 'playing' ? new Date().toISOString() : null,
      })
    }

    setLocalMediaPlayer(null)
    setMediaPlayerChangedWhileSeeking(false)
  }

  useInterval(() => {
    if (player.current && player.current.getPlayerState() === YouTube.PlayerState.PLAYING) {
      setTimestamp(player.current.getCurrentTime())
    }
  }, (isPlaying && !isSeeking) ? 250 : null)

  const subscription = useActionCable({channel: 'MediaPlayerChannel', audio_room_id: props.audioRoom.id}, {
    received(mediaPlayer) {
      setMediaPlayer(mediaPlayer)
      setTimestamp(mediaPlayer ? offsetFromMediaPlayer(mediaPlayer) : 0)
      setLoading(false)
      setShowingForm(false)

      if (localMediaPlayer) {
        setMediaPlayerChangedWhileSeeking(true)
      }
    },

    play(offset) {
      this.perform('play', {
        offset,
        duration,
        client_id: clientId.current,
        pressed_play_at: new Date().toISOString(),
      })
    },

    pause(offset) {
      this.perform('pause', {
        offset,
        duration,
        client_id: clientId.current,
      })
    },
  })

  useEffect(() => {
    if (showingForm || !mediaPlayer) {
      player.current = null
    }
  }, [showingForm, mediaPlayer])

  useEffect(() => {
    if (!player.current) return
    if (localMediaPlayer) {
      syncPlayingState(localMediaPlayer, player.current)
    } else if (mediaPlayer && mediaPlayer.client_id !== clientId.current) {
      syncPlayingState(mediaPlayer, player.current)
    }
  }, [mediaPlayer, localMediaPlayer])

  const [userHasInteracted, setUserHasInteracted] = useState(props.getUserHasInteracted())
  useEffect(() => {
    if (!userHasInteracted) {
      setUserHasInteracted(props.getUserHasInteracted())
    }
  }, [mediaPlayer, showingForm])

  const inputRef = useRef(null)

  useEffect(() => {
    if (showingForm) {
      inputRef.current?.focus()
    }
  }, [showingForm])

  useEffect(() => {
    if (!mediaPlayer) {
      setVideoUrl('')
      setTitle('')
      setDuration(0)
      setTimestamp(0)
      setLoading(true)
      setReady(false)
    }
  }, [mediaPlayer])

  if (loading) {
    return null
  }

  async function save(e) {
    e.preventDefault()

    // If we submitted the form by pressing enter, we don't
    // get the blur event on the input before unmounting the
    // input, so we need to tell CanvasWorld that it should
    // start accepting keyboard input again.
    props.onBlur()

    let apiCall
    if (mediaPlayer) {
      apiCall = Api.mediaPlayers.update.bind(null, mediaPlayer.id)
    } else {
      apiCall = Api.mediaPlayers.create
    }

    try {
      setMostRecentVideoSelectedByMe(true)

      await apiCall({
        audio_room_id: props.audioRoom.id,
        url: videoUrl,
      })
    } catch (e) {
      if (e.response?.status === 422) {
        props.setIsShaking(true)
      } else {
        alert("Something went wrong")
      }

      setMostRecentVideoSelectedByMe(false)
    }
  }

  async function destroy() {
    try {
      await Api.mediaPlayers.destroy(mediaPlayer.id)
    } catch (e) {
      alert("Something went wrong")
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Escape") {
      // We have to call onBlur explicitly here because setShowingForm(false)
      // will remove the input from the DOM before it has a chance to call it's
      // own onBlur.
      props.onBlur()
      setShowingForm(false)
    }
  }

  if (!mediaPlayer && showingForm) {
    return <form
      onSubmit={ save }
      className="full-width full-height"
    >
      <div className="display-flex justify-content-space-between align-items-stretch full-height m-l-1">
        <label className="form__label p-b-0">
          <div className="m-b-1 display-none">YouTube URL</div>
          <input
            type="text"
            className="form__input"
            value={ videoUrl }
            onChange={e => setVideoUrl(e.target.value)}
            onFocus={ props.onFocus }
            onBlur={ props.onBlur }
            onKeyDown={ handleKeyDown }
            ref={ inputRef }
            placeholder="YouTube URL"

            style={{
              width: 200,
              paddingTop: '0.3em',
              paddingBottom: '0.3em',
            }}
          />
        </label>

        <button type="submit" className="button bg-purple m-l-1" style={{height: 'auto'}}>
          Play
        </button>
      </div>
    </form>
  } else if (!mediaPlayer) {
    return <button className="button bg-purple m-l-1" onClick={() => setShowingForm(true)}>
      Share YouTube
    </button>
  }

  function handleReady(e) {
    console.log('Ready: ', e.target)
    setTitle(e.target.getVideoData().title)
    player.current = e.target

    const duration = player.current.getDuration()
    const live = duration == 0
    setDuration(duration)
    setReady(true)
    player.current.setVolume(volume)

    if (isMuted) {
      player.current.mute()
    }

    // This if is probably not necessary. I believe mediaPlayer is always
    // defined when handleReady is called - Dave
    if (mediaPlayer) {
      const offset = offsetFromMediaPlayer(mediaPlayer, {liveBroadcast: live})

      setMediaPlayer(prev => ({
        ...prev,
        client_id: null,
        state: !live && offset >= duration ? 'paused' : prev.state,
        offset: !live && offset >= duration ? 0 : prev.offset,
        pressed_play_at: offset >= duration ? null : prev.pressed_play_at,
      }))

      setTimestamp(offset >= duration ? 0 : offset)

      if (mostRecentVideoSelectedByMe) {
        setMostRecentVideoSelectedByMe(false)
        play()
      }
    }
  }

  const parsedUrl = new URL(mediaPlayer.url)
  const videoId = parsedUrl.host === 'youtu.be' ?
  parsedUrl.pathname.slice(1) :
  _.object(Array.from(new URL(mediaPlayer.url).searchParams)).v

  function mute() {
    if (!player.current) return

    player.current.mute()
    setIsMuted(true)
  }

  function unmute() {
    if (!player.current) return

    player.current.unMute()
    setIsMuted(false)
  }

  function handleMuteToggled () {
    if (isMuted) {
      unmute()
    } else {
      mute()
    }
  }

  function play () {
    if (!player.current || !mediaPlayer) return

    const offset = currentOffset(mediaPlayer, player.current)

    if (player.current.getPlayerState() === YouTube.PlayerState.ENDED) {
      setMediaPlayer(prev => ({
        ...prev,
        offset: 0,
        client_id: null,
        state: 'playing',
        pressed_play_at: new Date().toISOString(),
      }))
      setTimestamp(0)
      subscription.play(0)
    } else {
      setMediaPlayer(prev => ({
        ...prev,
        offset,
        client_id: null,
        state: 'playing',
        pressed_play_at: new Date().toISOString(),
      }))
      setTimestamp(offset)
      subscription.play(offset)
    }
  }

  function pause () {
    if (!player.current || !mediaPlayer) return
    const offset = currentOffset(mediaPlayer, player.current)

    setMediaPlayer(prev => ({
      ...prev,
      offset,
      client_id: null,
      state: 'paused',
      pressed_play_at: null,
    }))
    subscription.pause(offset)
  }

  function handlePlayToggled () {
    if (isPlaying) pause()
    else play()
  }

  function handleVolumeChanged (newVolume) {
    player.current?.setVolume(newVolume)
    setVolume(newVolume)
  }

  // We try to control player state through our own controls, but there will
  // always be opportunity for the user to surprise us. They might control the
  // video in another way, eg. clicking the video or using a browser plug-in.
  // These handlers ensure that we respond to state changes triggered from
  // outside of our custom controls.
  function handlePlay () {
    if (!isSeeking && mediaPlayer.state === "paused") {
      play()
    }
  }

  function handlePause () {
    if (!isSeeking && mediaPlayer.state === "playing") {
      pause()
    }
  }

  function handleEnd () {
    if (localMediaPlayer) return

    setMediaPlayer(prev => ({
      ...prev,
      client_id: null,
      state: 'paused',
      offset: duration,
      pressed_play_at: null,
    }))

    setTimestamp(duration)
  }

  function onToggleVideo() {
    setShowingVideo(showing => !showing)
  }

  return <>
    {userHasInteracted && <YouTube
      containerClassName={classSet("youtube-player", {"youtube-player--with-panel": showingPanel, "display-none": !showingVideo})}
      className="position-absolute"
      videoId={videoId}
      opts={videoOptions}
      onReady={handleReady}
      onPlay={handlePlay}
      onPause={handlePause}
      onEnd={handleEnd}
      // For later, maybe…
      // onError={func}
    />}

    <Controls
      onDestroy={destroy}
      onMuteToggled={handleMuteToggled}
      onUnmute={unmute}
      onVolumeChanged={handleVolumeChanged}
      onPlayToggled={handlePlayToggled}
      isMuted={isMuted}
      displayedVolume={isMuted ? 0 : volume}
      isPlaying={isPlaying}
      timestamp={timestamp}
      onSeekStart={handleSeekStart}
      onSeekChange={handleSeekChange}
      onSeekEnd={handleSeekEnd}
      onToggleVideo={ onToggleVideo }
      showingVideo={ showingVideo }
      duration={duration}
      loading={!ready}
      userHasInteracted={userHasInteracted}
      setUserHasInteracted={setUserHasInteracted}
      title={title}
    />
  </>
}

export function formatTimestamp(s, duration) {
  const seconds = (Math.floor(s) % 60).toString(10).padStart(2, 0)
  const minutes = (Math.floor(s / 60) % 60).toString(10).padStart(2, 0)
  const hours = Math.floor(s / 3600).toString(10).padStart(2, 0)

  return `${duration >= 3600 ? hours + ':' : ''}${minutes}:${seconds}`
}

function Controls ({
  onMuteToggled,
  onUnmute,
  onVolumeChanged,
  onPlayToggled,
  isMuted,
  displayedVolume,
  isPlaying,
  timestamp,
  onSeekStart,
  onSeekChange,
  onSeekEnd,
  onDestroy,
  duration,
  loading,
  userHasInteracted,
  setUserHasInteracted,
  title,
  showingVideo,
  onToggleVideo,
}) {
  const live = duration === 0 && !loading
  const disabled = !userHasInteracted

  function handleSeekStart() {
    if (live) return

    onSeekStart()
  }

  function handleSeekChange(e) {
    if (live) return

    onSeekChange(e.target.value)
  }

  function handleSeekEnd(e) {
    if (live) return

    onSeekEnd()
  }

  function handleVolumeChanged(e) {
    onVolumeChanged(parseInt(e.target.value, 10))
    onUnmute()
  }

  return <div className="m-l-2 m-r-1 position-relative display-flex align-items-center full-height">
    <div className={`display-flex justify-content-space-between align-items-center flex-wrap-wrap ${disabled ? ' visibility-hidden' : '' }`}>
      <button onClick={onPlayToggled} className="button button--link font-size-large p-a-0" disabled={disabled} style={{marginRight: '0.75rem'}}>
        <i className={isPlaying ? "fas fa-pause" : "fas fa-play"} />
      </button>

      {
        !live ?
        <div className="tabular-nums m-r-1 font-size-small">{loading ? "00:00" : formatTimestamp(timestamp, duration)}</div> :
        null
      }
      <input
        type="range"
        min={ 0 }
        max={ live ? 100 : duration }
        value={ live ? 100 : timestamp }
        onMouseDown={ handleSeekStart }
        onTouchStart={ handleSeekStart }
        onChange={ handleSeekChange }
        onMouseUp={ handleSeekEnd }
        onTouchEnd={ handleSeekEnd }
        disabled={disabled || loading}
        className="flex-1 m-r-1"
        style={{ width: live ? 240 : 200 }}
      />
      {
        live ?
        <div className="font-size-xxsmall color-white with-border-radius" style={{backgroundColor: 'red', padding: '1px 6px'}}>Live</div> :
        <div className="tabular-nums font-size-small">{loading ? "00:00" : formatTimestamp(duration, duration)}</div>
      }

      <Marquee className="m-l-2 font-size-small">
        {title}
      </Marquee>

      <div className="m-x-2 display-flex align-items-center">
        <button onClick={onMuteToggled} className="button button--link p-a-0 m-x-half" disabled={disabled}>
          <i className={isMuted ? "fas fa-volume-mute" : "fas fa-volume-up"} style={{width: 18}}/>
        </button>
        <input type="range" value={displayedVolume} onChange={handleVolumeChanged} min="0" max="100" disabled={disabled} style={{width: 100}}/>
      </div>

      <button onClick={ onToggleVideo } className="button button--link p-a-0 m-r-2" disabled={disabled}>
        <i className={`fas fa-video${showingVideo ? '-slash' : ''}`} />
      </button>

      <button onClick={() => onDestroy() } className="button button--link p-a-0" disabled={disabled}>
        <i className="fas fa-times" />
      </button>
    </div>

    {disabled && <button
      className="button position-absolute"
      onClick={ () => setUserHasInteracted(true) }
      style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
    >
      Play
    </button>}
  </div>
}
