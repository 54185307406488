import { useEffect, useRef } from 'react'

export default function useEventListener(eventName, handler, element) {
  const savedHandler = useRef(null)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (!element || !element.addEventListener) {
      return
    }

    const listener = e => savedHandler.current(e)

    element.addEventListener(eventName, listener)

    return () => {
      element.removeEventListener(eventName, listener)
    }
  }, [eventName, element])
}
