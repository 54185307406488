// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import {emojiData} from "./emoji_utils"
import {init} from "emoji-mart"
init({data: emojiData})

import "./channels"
import "./controllers"
