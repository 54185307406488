import React, {useRef, useState, useEffect} from 'react'

import { classSet } from './utils'

const SPEED = 35 // pixels per second
const DELAY = 1 // seconds

function Marquee ({width = 160, className, children}) {
  const [animating, setAnimating] = useState(false)
  const [duration, setDuration] = useState(null)
  const viewport = useRef(null)
  const content = useRef(null)
  const timeoutId = useRef(null)

  useEffect(() => {
    const viewportWidth = viewport.current.getBoundingClientRect().width
    const contentWidth = content.current.offsetWidth

    const duration = Math.max(0, contentWidth - viewportWidth) / SPEED
    setDuration(duration)

    timeoutId.current = setTimeout(() => {
      setAnimating(true)
    }, 2*DELAY*1000)

    return () => {
      setAnimating(false)
      clearTimeout(timeoutId.current)
    }
  }, [children])

  function handleAnimationEnd() {
    timeoutId.current = setTimeout(() => {
      setAnimating(false)

      timeoutId.current = setTimeout(() => {
        setAnimating(true)
      }, DELAY*1000)
    }, DELAY*1000)
  }

  if (typeof width === 'number') {
    width = `${width}px`
  }

  const vars = {
    "--marquee-width": width,
  }

  if (duration) {
    vars["--marquee-duration"] = duration + "s"
  }

  return <div
    className={classSet("marquee", className)}
    style={vars}
    title={children}
    ref={viewport}
  >
    <span
      ref={content}
      className={classSet("marquee__inner", {"marquee__inner--animating": animating})}
      onAnimationEnd={ handleAnimationEnd }
    >
      {children}
    </span>
  </div>
}

export default Marquee;
