import React, { useState,  useRef, useEffect } from 'react'
import _ from 'underscore'
import { LocalVideoTrack } from 'twilio-video'

import useActionCable from './use_action_cable'
import { classSet } from './utils'

const IDEAL_POPUP_WIDTH = 800
const IDEAL_POPUP_HEIGHT = 600
const IDEAL_POPUP_ASPECT_RATIO = IDEAL_POPUP_WIDTH / IDEAL_POPUP_HEIGHT

const videoStyle = {
  maxWidth: 267,
  maxHeight: 150,
}

export default function ScreenSharePlayer ({audioRoom, twilioRoom}) {
  const [track, setTrack] = useState(null)
  const [screenShare, setScreenShare] = useState(null)
  const host = screenShare?.host

  const videoTag = useRef(null)
  const videoPopup = useRef(null)

  useEffect(() => {
    return () => {
      if (track && track.constructor === LocalVideoTrack) {
        twilioRoom.twilioRoom.localParticipant.unpublishTrack(track)
        track.stop()
        subscription.stop()
      }

      if (track) {
        videoPopup.current?.close()
        videoPopup.current = null
      }
    }
  }, [track])

  const subscription = useActionCable({channel: 'ScreenShareChannel', audio_room_id: audioRoom.id}, {
    received(screenShare) {
      setScreenShare(screenShare)
    },

    start() {
      this.perform('start')
    },

    stop() {
      this.perform('stop')
    },
  })

  useEffect(() => {
    twilioRoom.onVideoAttach = (track) => {
      setTrack(track)

      if (videoTag.current) {
        track.attach(videoTag.current)
      }
    }

    twilioRoom.onVideoDetach = (track) => {
      setTrack(null)
    }

    return () => {
      twilioRoom.onVideoAttach = null
      twilioRoom.onVideoDetach = null
    }
  }, [setTrack])

  const handleButtonClick = async () => {
    if (track) {
      setTrack(null)
    } else {
      const stream = await navigator.mediaDevices.getDisplayMedia()
      const track = new LocalVideoTrack(stream.getVideoTracks()[0])
      twilioRoom.twilioRoom.localParticipant.publishTrack(track)
      setTrack(track)
      subscription.start()

      stream.addEventListener('inactive', () => {
        setTrack(null)
      })

      if (videoTag.current) {
        track.attach(videoTag.current)
      }
    }
  }

  const handleVideoClick = () => {
    if (track.constructor === LocalVideoTrack) {
      setTrack(null)
    } else {
      let {width, height} = track.dimensions
      const aspectRatio = width / height
      if (aspectRatio > IDEAL_POPUP_ASPECT_RATIO) {
        width = IDEAL_POPUP_WIDTH
        height = width / aspectRatio
      } else {
        height = IDEAL_POPUP_HEIGHT
        width = height * aspectRatio
      }

      if (videoPopup.current && !videoPopup.current.closed) {
        videoPopup.current.focus()
        return
      }
      videoPopup.current = window.open('/screenshare', null, `width=${width},height=${height}`)
      if (videoPopup.current) {
        videoPopup.current.addEventListener('DOMContentLoaded', function () {
          videoPopup.current.document.title = host?.name ? `${host.name}’s screen` : 'Shared screen'
          this.attachTrack(track)
        })
      }
    }
  }

  const imSharing = track?.constructor === LocalVideoTrack
  const theyAreSharing = track && !imSharing && host

  const overlayClasses = classSet("display-flex justify-content-center align-items-center position-absolute box-shadow with-border-radius bg-white", {
    "z-10": imSharing,
  })

  return <>
    <button
      className="button bg-blue m-a-0 m-l-1"
      onClick={ handleButtonClick }
      disabled={ track && subscription && !imSharing }
      style={{ minWidth: 111 }}
    >
      {
        imSharing ?
        "Stop sharing" :
        theyAreSharing ?
        `${host.name} is sharing` :
        "Share screen"
      }
    </button>

    {
      track ?
      <div className={overlayClasses} style={{left: 0, bottom: 'calc(100% + 12px)'}} onClick={handleVideoClick}>
        <video
          className="with-border-radius"
          ref={videoTag}
          style={videoStyle}
        />

        <div className="position-absolute full-width full-height color-white cursor-pointer invisible opaque-on-hover transition-opacity">
          <div className="position-absolute bg-black transparent full-width full-height with-border-radius"/>
          <div className={classSet("position-relative full-width full-height display-flex justify-content-center align-items-center flex-direction-column font-size-xsmall", {
            "p-b-3": theyAreSharing,
          })}>
            { theyAreSharing && <img className="circular-image circular-image--tiny" src={host.image_url} alt="" /> }
            { theyAreSharing && <span>{host.name}</span> }
            <span className="m-t-half font-weight-bold">{
              imSharing ?
              "Click to stop sharing" :
              "Click to expand"
            }</span>
          </div>
        </div>
      </div> :
      null
    }
  </>
}
