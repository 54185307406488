import _ from 'underscore'
import React from 'react'

import {SearchIndex} from 'emoji-mart'

// emojiData and emojiSet must be kept in sync.
import emojiData from "@emoji-mart/data/sets/14/apple.json"
const emojiSet = 'apple'

export {emojiData, emojiSet}


export function getEmojiSpritesheetURL(set) {
  return `https://cdn.jsdelivr.net/npm/emoji-datasource-${set}@14.0.0/img/${set}/sheets-256/64.png`
}

const emojiSpritesheetURL = getEmojiSpritesheetURL(emojiSet)
export { emojiSpritesheetURL }

// 👌 => 1, 👌🏻 => 2, 👌🏼 => 3, 👌🏽 => 4, 👌🏾 => 5, 👌🏿 => 6
function skinNumberFromNative(native) {
  const match = native.match(/[\u{1F3FB}-\u{1F3FF}]/u)
  if (match) {
    return match[0].codePointAt(0) - 0x1F3FB + 2
  } else {
    return 1
  }
}

function emojiSkinFromNative(native) {
  const emoji = SearchIndex.get(native)
  if (!emoji) {
    return null
  }

  return emoji.skins[skinNumberFromNative(native) - 1]
}

export function Emoji({native, size}) {
  emojiSkin = emojiSkinFromNative(native)

  return (
    <div
      title={emojiSkin.shortcodes}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${emojiSpritesheetURL})`,
        backgroundSize: `${100 * emojiData.sheet.cols}% ${100 * emojiData.sheet.rows}%`,
        backgroundPosition: `
          ${(100 / (emojiData.sheet.cols - 1)) * emojiSkin.x}%
          ${(100 / (emojiData.sheet.rows - 1)) * emojiSkin.y}%
        `,
      }}
    />
  )
}


const COORDINATE_CACHE = {}

export function emojiSheetCoordinatesFromNative(native) {
  if (_.has(COORDINATE_CACHE, native)) {
    return COORDINATE_CACHE[native]
  }

  const emojiSkin = emojiSkinFromNative(native)

  if (!emojiSkin) {
    COORDINATE_CACHE[native] = null
    return null
  }

  COORDINATE_CACHE[native] = {x: emojiSkin.x, y: emojiSkin.y}
  return COORDINATE_CACHE[native]
}
