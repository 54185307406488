import { useEffect, useRef } from 'react'

export default function useInterval(func, delay, { immediate } = {}) {
  const handler = useRef(null)
  handler.current = func

  useEffect(() => {
    if (!delay) {
      return
    }

    if (immediate) handler.current()

    const intervalId = setInterval(() => {
      handler.current()
    }, delay)

    return () => { clearInterval(intervalId) }
  }, [delay])
}
