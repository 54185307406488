import _ from 'underscore'

import { singularize } from './utils'

function stringOrStrings(thing) {
  if (_.isString(thing)) {
    return [thing];
  } else {
    return thing || [];
  }
}

function csrfToken() {
  const element = document.querySelector('meta[name="csrf-token"]')
  return element && element.content
}

function mergeQueryParams(url, data) {
  url = new URL(url, window.location.origin)
  const entries = [...url.searchParams.entries()].concat(_.pairs(data));
  const queryString = entries.map(([k, v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
  url.search = '?' + queryString;

  return url.toString();
}

function extractActions(only, except) {
  let actions = ['index', 'show', 'create', 'update', 'destroy'];

  actions = _.difference(actions, except);

  if (!_.isEmpty(only)) {
    actions = _.intersection(actions, only);
  }

  return actions;
}

function genPath(parts, id) {
  return parts.map((part) => {
    if (_.isNumber(part)) {
      return id;
    } else {
      return part;
    }
  }).join('/');
}

function genActions(api, descriptions, paramName, accept) {
  _.each(descriptions, (desc, action) => {
    api[action] = function (paramsOrId, queryParams) {
      let data = {};
      let id;
      let params;

      if (_.some(desc.path, _.isNumber)) {
        id = paramsOrId;
        params = queryParams;
      } else {
        params = paramsOrId;
      }

      if (!_.isEmpty(params) && _.contains(['PUT', 'PATCH', 'POST'], desc.method)) {
        data[paramName] = params;
      } else {
        data = params;
      }

      let options = {
        method: desc.method,
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
      }

      if (accept) {
        options.headers['Accept'] = accept
      }

      let path = genPath(desc.path, id);

      if (desc.method === 'GET' || desc.method === 'HEAD') {
        path = mergeQueryParams(path, data)
      } else {
        options.headers['Content-Type'] = 'application/json'
        options.body = JSON.stringify(data)
      }

      return fetch(path, options).then(response => {
        if (!response.ok) {
          const error = new Error(`Response was ${response.status} ${response.statusText}`)
          error.response = response
          throw error
        }

        return response
      })
    }
  });
}

function defaultDescriptions(prefix, name) {
  return {
    index: {
      method: 'GET',
      path: [prefix, name],
    },
    show: {
      method: 'GET',
      path: [prefix, name, 0],
    },
    create: {
      method: 'POST',
      path: [prefix, name],
    },
    update: {
      method: 'PATCH',
      path: [prefix, name, 0],
    },
    destroy: {
      method: 'DELETE',
      path: [prefix, name, 0],
    },
  };
}

function singularDescriptions(prefix, name) {
  return {
    show: {
      method: 'GET',
      path: [prefix, name],
    },
    create: {
      method: 'POST',
      path: [prefix, name],
    },
    update: {
      method: 'PATCH',
      path: [prefix, name],
    },
    destroy: {
      method: 'DELETE',
      path: [prefix, name],
    },
  }
}

function shallowDescriptions(prefix, parent, name) {
  return {
    ...defaultDescriptions(prefix, name),
    index: {
      method: 'GET',
      path: [prefix, parent, 0, name],
    },
    create: {
      method: 'POST',
      path: [prefix, parent, 0, name],
    },
  };
}

function RestClient(name, options={}) {
  const {
    only,
    except,
    prefix='',
    parentRoute='',
    collection={},
    member={},
    json=false,
    shallow=false,
    paramName,
    singular=false,
    accept=null,
  } = options;

  if (!name) {
    throw 'RestClient must be passed a resource name as its first argument';
  }

  if (shallow && !parentRoute) {
    throw "RestClient can't generate shallow routes without a parent route";
  }

  if (except && only) {
    throw "RestClient cannot take both 'only' and 'except' options";
  }

  if (shallow && singular) {
    throw "RestClient cannot take both 'shallow' and 'singular' options";
  }

  const actions = extractActions(
    stringOrStrings(only),
    stringOrStrings(except)
  );

  let descriptions;
  if (shallow) {
    descriptions = shallowDescriptions(prefix, parentRoute, name);
  } else if (singular) {
    descriptions = singularDescriptions(prefix, name);
  } else {
    descriptions = defaultDescriptions(prefix, name);
  }
  descriptions = _.pick(descriptions, actions);

  _.each(collection, function (method, action) {
    descriptions[action] = {
      method,
      path: shallow ? [prefix, parentRoute, 0, name, action] : [prefix, name, action],
    };
  });

  _.each(member, function (method, action) {
    descriptions[action] = {
      method,
      path: [prefix, name, 0, action],
    };
  });

  genActions(this, descriptions, paramName || singularize(name), accept);
}

export default RestClient;
