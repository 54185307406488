import React from 'react'
import { useDropzone } from 'react-dropzone'

import { classSet } from './utils'

export default function ImageUploader({id, src, onUpload, uploading, progress}) {
  function onDropAccepted(files) {
    onUpload(files[0])
  }

  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, inputRef} = useDropzone({
    onDropAccepted,
    maxFiles: 1,
    disabled: uploading,
    accept: {'image/*': ['.png', '.gif', '.jpeg', '.jpg']}
  })

  const classNames = classSet("image-uploader__drop-frame", {
    'image-uploader__progress-area': uploading,
    'image-uploader__dropzone': !uploading && (!src || isDragActive),
    "image-uploader__dropzone--drag-accept": isDragAccept,
  })

  function openFilePicker() {
    inputRef.current.click()
  }

  return <div className="image-uploader">
    <div {...getRootProps({className: classNames})}>
      <input {...getInputProps({id})} />
      {
        uploading ?
        <ProgressMeter progress={progress} /> :
        isDragReject ?
        <>
          <i className="image-uploader__error-icon" />
          <div className="image-uploader__title">Invalid file</div>
          <div className="image-uploader__sub-title">must be an image</div>
        </> :
        isDragActive || !src ?
        <>
          <i className="image-uploader__icon" />
          <div className="image-uploader__title">Select an image to upload</div>
          <div className="image-uploader__sub-title">or drag and drop it here</div>
        </> :
        <img src={src} className="image-uploader__image" />
      }
    </div>

    <div className="display-flex flex-direction-row-reverse">
      <button className="button" onClick={openFilePicker}>Select image…</button>
    </div>
  </div>
}

function ProgressMeter({progress}) {
  const strokeWidth = 4
  const strokeOffset = strokeWidth/2
  const r = 40
  const r1 = r-strokeOffset
  const d = 2*r
  const c = 2*Math.PI*r

  return <>
    <div className="image-uploader__progress-meter">
      <svg width={d} height={d}>
        <circle strokeWidth={strokeWidth} r={r1} cx={r} cy={r} className="image-uploader__progress-ring" />
        <circle
          strokeWidth={strokeWidth}
          r={r1}
          cx={r}
          cy={r}
          strokeDasharray={c}
          strokeDashoffset={c - c*progress/100}
          className="image-uploader__progress-ring-full"
        />
      </svg>
      <div className="image-uploader__progress-amount">{progress}%</div>
    </div>
    <div className="font-weight-bold font-size-large color-dark-gray">
      {
        progress === 100 ?
        <span>Done!</span> :
        <span>Uploading file…</span>
      }
    </div>
  </>
}
