import _ from 'underscore'
import React, { useState, useRef, useEffect, useLayoutEffect, useCallback, useContext } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route, Link as RouterLink, NavLink, useLocation } from "react-router-dom"
import { CSSTransition } from 'react-transition-group'
import moment from 'moment'
import Twilio from 'twilio-video'
import Heap from 'heap'
import {unified} from 'unified'
import remarkParse from 'remark-parse'
import remark2react from 'remark-react'
import remarkExternalLinks from 'remark-external-links'
import GraphemeSplitter from 'grapheme-splitter'
import { get, patch } from '@rails/request.js'

import ResizeObserver from 'resize-observer-polyfill'

const { PureComponent } = React

import consumer from './channels/consumer'

import ViewportContext from './viewport_context'

import Tooltip from './tooltip'
import Popover from './popover'
import Modal from './modal'
import ClickOutside from './click_outside'
import RestClient from './rest_client'
import { betterModulo, transformValues, pluralize, classSet, requestDesktopNotificationPermission, sendDesktopNotification, capitalize, dig, uuid, replaceBy, playSound } from './utils'
import useEventListener from './use_event_listener'
import usePrevious from './use_previous'
import useInterval from './use_interval'
import useActionCable from './use_action_cable'
import EmojiAndTextInput from './emoji_and_text_input'
import DatePicker, {TimePicker} from './date_picker'
import YoutubePlayer, {mediaPlayerIsPlaying} from './youtube_player'
import ScreenSharePlayer from './screenshare_player'
import SharedTimer from './shared_timer'
import NGWBanner from './components/ngw_banner'

import ImageUploader from './image_uploader'
import useDirectUpload from './use_direct_upload'

import { Emoji, emojiSheetCoordinatesFromNative } from './emoji_utils'
import { emojiSpritesheetURL } from './emoji_utils'

const EmojiSheet = new Image()
EmojiSheet.src = emojiSpritesheetURL

const EMOJI_SIZE = 64

const WIDTH = 27;
const HEIGHT = 27;
const N_FRAMES = 3
const VIEWPORT_BUFFER = 3

const MESSAGE_TIMEOUT = moment.duration(5, 'seconds')
const MESSAGE_HOVER_TIMEOUT = moment.duration(1, 'hour')

const LOCAL_STORAGE_KEY_TWILIO_ROOM_ID = 'twilioRoomId'
const LOCAL_STORAGE_KEY_TWILIO_ROOM_LAST_ACTIVE_AT = 'twilioRoomLastActiveAt'

const HELP_URL = 'https://www.notion.so/recurse/Help-695cc163c76c47449347bd97a6842c3b'

const Api = {
  zoomLinks: new RestClient('zoom_links'),
  notes: new RestClient('notes'),
  links: new RestClient('links'),
  walls: new RestClient('walls'),
  users: new RestClient('users'),
  avatars: new RestClient('avatars', {
    collection: {currently_at_rc: 'GET'}
  }),
  audioBlocks: new RestClient('audio_blocks'),
  desks: new RestClient('desks', {
    member: {cleanup: 'PATCH'}
  }),
  status: new RestClient('status', {
    singular: true,
  }),
  rcCalendars: new RestClient('calendars', {
    prefix: '/rc',
  }),
  messages: new RestClient('messages'),

  alternateZoomIds: new RestClient('alternate_zoom_ids', {
    parentRoute: 'users',
    shallow: true,
  }),
  alternateZoomNames: new RestClient('alternate_zoom_names', {
    parentRoute: 'users',
    shallow: true,
  }),

  coffeeChatPreference: new RestClient('coffee_chat_preference', {
    singular: true,
  }),
  zoomUsers: new RestClient('zoom_users'),
  guestUsers: new RestClient('guest_users', {
    member: {resend_invite: 'POST'}
  }),

  slackCredential: new RestClient('slack_credential', {
    singular: true,
  }),
};

function manhattanDistance(p1, p2) {
  return Math.abs(p1.x - p2.x) + Math.abs(p1.y - p2.y)
}

function parseQueryParams(search) {
  return _.object(
    decodeURIComponent(search)
      .slice(1)
      .split('&')
      .map(pair => pair.split('='))
  )
}


class DefaultDict {
  constructor(defaultValue) {
    this.default = defaultValue
    this.store = {}
  }

  get(key) {
    if (_.has(this.store, key)) {
      return this.store[key]
    } else {
      return this.default
    }
  }

  set(key, value) {
    this.store[key] = value
  }
}

function astar(currentPos, destPos, canMoveTo) {
  let gScore = new DefaultDict(Infinity)
  let fScore = new DefaultDict(Infinity)
  gScore.set(currentPos, 0)
  fScore.set(currentPos, 0)
  let opened = new Set()
  let prev = {}
  let Q = new Heap((p1, p2) => fScore.get(p1) - fScore.get(p2))
  Q.push(currentPos)
  opened.add(currentPos)

  while (!Q.empty()) {
    const u = Q.pop()

    if (_.isEqual(destPos, u)) {
      break;
    }

    function neighbors(u) {
      const pos = parseIndexKey(u)
      const candidates = [
        {x: pos.x-1, y: pos.y},
        {x: pos.x+1, y: pos.y},
        {x: pos.x, y: pos.y-1},
        {x: pos.x, y: pos.y+1},
      ]

      return _.filter(candidates, p => canMoveTo(p)).map(indexKey)
    }

    for (let v of neighbors(u)) {
      const newG = gScore.get(u) + 1 // manhattan distance to neighbor is always 1
      if (newG < gScore.get(v)) {
        prev[v] = u
        gScore.set(v, newG)
        fScore.set(v, newG + manhattanDistance(parseIndexKey(v), parseIndexKey(destPos)))
        if (!opened.has(v)) {
          opened.add(v)
          Q.push(v)
        } else {
          Q.updateItem(v)
        }
      }
    }
  }
  let steps = []
  let pos = destPos

  if (!prev[pos] && !_.isEqual(pos, currentPos)) {
    return null
  }

  while (pos) {
    steps.unshift(pos)
    pos = prev[pos]
  }

  return steps.slice(1).map(parseIndexKey)
}

const SPEED = 2;
const MAX_JIGGLE = 2;

function jiggle({offsetX, offsetY, vx, vy}) {
  if (Math.random() < 0.3) {
    if (Math.abs(offsetX) >= MAX_JIGGLE && Math.sign(offsetX) === Math.sign(vx)) {
      vx *= -1;
    }

    offsetX += vx;
  }

  if (Math.random() < 0.3) {
    if (Math.abs(offsetY) >= MAX_JIGGLE && Math.sign(offsetY) === Math.sign(vy)) {
      vy *= -1;
    }

    offsetY += vy;
  }

  if (Math.random() < 0.2) { vx = vx * -1; }
  if (Math.random() < 0.2) { vy = vy * -1; }

  return {offsetX, offsetY, vx, vy};
}

function isAvatar(entity) {
  return _.contains(['Avatar', 'UnknownAvatar'], entity.type)
}

function isBot(entity) {
  return entity.type === "Bot"
}

function isAgent(entity) {
  return _.contains(['Avatar', 'Bot'], entity.type)
}

function drawRoundedRectPath(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
}

class Dialog extends PureComponent {
  render() {
    const { message, confirmText, cancelText, onConfirm, onCancel, noescape, confirmClassName, disabled } = this.props

    const confirmClasses = classSet("button", "bg-green", {[confirmClassName]: !!confirmClassName})

    return (
      <Modal onClose={ noescape ? null : onCancel }>
        <p className="m-t-0 m-b-3" style={{maxWidth: 400}}>{ message }</p>
        <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
          <button className={ confirmClasses } disabled={ disabled }onClick={ onConfirm }>{ confirmText }</button>

          {
            cancelText ?
            <button className="button button--link without-focus-ring" disabled={ disabled } onClick={ onCancel }>{ cancelText }</button> :
            null
          }
        </div>
      </Modal>
    );
  }
}


class LinkEditor extends PureComponent {
  state = {
    url: this.props.block.url || "",
    submitting: false,
    error: null,
  }

  componentDidMount() {
    this.input.select();
  }

  stopEditing = (e) => {
    this.props.onClose();
  }

  handleChange = (e) => {
    this.setState({url: e.target.value});
  }

  save = async (e) => {
    const { block } = this.props;
    const { url } = this.state;

    this.setState({submitting: true});

    try {
      await Api.links.update(block.id, {url: url})
      this.stopEditing()
    } catch (e) {
      this.setState({submitting: false, error: "Something went wrong while updating this block."})
    }
  }

  render() {
    const { url, submitting, error } = this.state;

    return (
      <Modal onClose={ this.stopEditing }>
        <div className="display-flex flex-direction-column form">
          <label className="form__label">
            <div className="m-b-1">URL</div>
            <input type="url" value={ url } onChange={ this.handleChange } className="form__input" ref={ el => this.input = el }/>
          </label>

          {
            error ?
            <div className="font-size-xsmall color-pink">{ error }</div> :
            null
          }

          <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
            <button className="button bg-green" onClick={ this.save } disabled={ submitting }>Save</button>
            <button className="button button--link without-focus-ring" onClick={ this.stopEditing } disabled={ submitting }>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }
}

class AudioBlockEditor extends PureComponent {
  state = {
    name: this.props.block.name || "",
    muteOnEntry: this.props.block.mute_on_entry || false,
    submitting: false,
    error: null,
  }

  componentDidMount() {
    this.nameInput.select()
  }

  stopEditing = (e) => {
    this.props.onClose()
  }

  handleNameChange = (e) => {
    this.setState({name: e.target.value})
  }

  handleMuteOnEntryChange = (e) => {
    this.setState({muteOnEntry: e.target.checked})
  }


  save = async (e) => {
    const { block } = this.props;
    const { name, muteOnEntry } = this.state

    this.setState({submitting: true})

    try {
      await Api.audioBlocks.update(block.id, {audio_block_name: name, audio_room_mute_on_entry: muteOnEntry})
      this.stopEditing()
    } catch (e) {
      this.setState({submitting: false, error: "Something went wrong while updating this block."})
    }
  }

  render() {
    const { name, muteOnEntry, submitting, error } = this.state;

    return (
      <Modal onClose={ this.stopEditing }>
        <div className="display-flex flex-direction-column form">
          <label className="form__label">
            <div className="m-b-1">Name</div>
            <input type="text" value={ name } onChange={ this.handleNameChange } className="form__input" ref={ el => this.nameInput = el }/>
          </label>

          <label className="form__checkbox p-t-half">
            <input checked={muteOnEntry} type="checkbox" onChange={ this.handleMuteOnEntryChange } /> Mute people when they enter
          </label>

          {
            error ?
            <div className="font-size-xsmall color-pink">{ error }</div> :
            null
          }

          <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
            <button className="button bg-green" onClick={ this.save } disabled={ submitting }>Save</button>
            <button className="button button--link without-focus-ring" onClick={ this.stopEditing } disabled={ submitting }>Cancel</button>
          </div>
        </div>
      </Modal>
    )
  }
}

class OrgWideZoomLinkEditor extends PureComponent {
  state = {
    zoomUserId: this.props.block.zoom_user?.id,
    submitting: false,
    error: null,
    zoomUsers: [],
  }

  stopEditing = (e) => {
    this.props.onClose();
  }

  handleSelectionChange = (e) => {
    this.setState({zoomUserId: parseInt(e.target.value, 10)});
  }

  onSubmit = (e) => {
    const { block } = this.props;
    const { zoomUserId } = this.state;

    const zoomUser = _.findWhere(this.state.zoomUsers, {id: zoomUserId})

    if (zoomUser.zoom_link_id && zoomUser.zoom_link_id !== block.id) {
      this.setState({showConfirmDialog: true})
    } else {
      this.save()
    }
  }

  save = async (e) => {
    const { block } = this.props;
    const { zoomUserId } = this.state;

    this.setState({submitting: true});

    try {
      await Api.zoomLinks.update(block.id, {zoom_user_id: zoomUserId})
      this.stopEditing()
    } catch (e) {
      this.setState({submitting: false, error: "Something went wrong while updating this block."})
    }
  }

  async componentDidMount() {
    try {
      const resp = await Api.zoomUsers.index()
      const zoomUsers = await resp.json()

      this.setState({
        zoomUsers,
        zoomUserId: this.state.zoomUserId || zoomUsers[0]?.id
      })
    } catch (e) {
      this.setState({error: "Something went wrong when fetching Zoom users"})
    }
  }

  render() {
    const { zoomUsers, zoomUserId, submitting, error, showConfirmDialog } = this.state;

    if (showConfirmDialog) {
      const zoomUser = _.findWhere(this.state.zoomUsers, {id: zoomUserId})

      return (
        <Dialog
          message={ `${zoomUser.display_name} is already associated with a block. Would you like to move that account to this block instead?` }
          confirmText="Move"
          cancelText="Cancel"
          onConfirm={ this.save }
          onCancel={ () => this.setState({showConfirmDialog: false}) }
        />
      )
    }

    return (
      <Modal onClose={ this.stopEditing }>
        <div className="display-flex flex-direction-column form">
          <label className="form__label">
            <div className="m-b-1">Zoom</div>

            <select name="zoomUserId" value={ zoomUserId } onChange={ this.handleSelectionChange } className="form__input">
              {
                zoomUsers.map(u =>
                  <option key={u.id} value={u.id}>{ u.display_name }</option>
                )
              }
            </select>
          </label>

          {
            error ?
            <div className="font-size-xsmall color-pink">{ error }</div> :
            null
          }

          <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
            <button className="button bg-green" onClick={ this.onSubmit } disabled={ submitting }>Save</button>
            <button className="button button--link without-focus-ring" onClick={ this.stopEditing } disabled={ submitting }>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }
}

function IndividualZoomLinkEditor(props) {
  const [zoomUser, setZoomUser] = useState(null)
  const [loadingZoomUser, setLoadingZoomUser] = useState(true)

  useActionCable("MyZoomUserChannel", {
    received(user) {
      setZoomUser(user)
      setLoadingZoomUser(false)
    }
  })

  const [credential, setCredential] = useState(null)
  const [loadingCredential, setLoadingCredential] = useState(true)

  useActionCable("MyZoomCredentialChannel", {
    received(cred) {
      if (!cred || cred.deauthorized || !cred.current) {
        setCredential(null)
      } else {
        setCredential(cred)
      }

      setLoadingCredential(false)
    }
  })

  const [zoomLink, setZoomLink] = useState(props.block)

  useActionCable({channel: "ZoomLinkChannel", id: props.block.id}, {
    received(zoomLink) {
      setZoomLink(zoomLink)
    }
  })

  if (loadingZoomUser || loadingCredential) {
    return null
  }

  return (
    <Modal onClose={ props.onClose } maxWidth={ 400 }>
      {
        zoomLink.zoom_user && zoomUser && zoomLink.zoom_user.id === zoomUser.id ?
        <MyZoomUserEditor
          zoomUser={ zoomUser }
          onClose={ props.onClose }
        /> :
        zoomLink.zoom_user ?
        <SomeoneElsesZoomUserEditor
          onClose={ props.onClose }
        /> :
        zoomUser?.zoom_link_id ?
        <SwitchZoomLinkConfirmation
          zoomLink={ zoomLink }
          zoomUser={ zoomUser }
          onClose={ props.onClose }
        /> :
        <ClaimZoomLink
          zoomUser={ zoomUser }
          credential={ credential }
          zoomLink={ zoomLink }
          onClose={ props.onClose }
        />
      }
    </Modal>
  )
}

function SomeoneElsesZoomUserEditor(props) {
  const user = props.zoomUser

  return (
    <div>
      <div className="m-b-2">You can’t edit this block because it's connected to someone else's Zoom account.</div>

      <div className="display-flex justify-content-flex-end">
        <button className="button bg-green" onClick={ props.onClose }>OK</button>
      </div>
    </div>
  )
}

function MyZoomUserEditor(props) {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [displayName, setDisplayName] = useState(props.zoomUser.display_name)

  const input = useRef(null)

  useEffect(() => {
    input.current.focus()
    input.current.select()
  }, [])

  async function submit() {
    try {
      setSubmitting(true)
      await Api.zoomUsers.update(user.id, {display_name: displayName})
      props.onClose()
    } catch (e) {
      setSubmitting(false)
      if (!e.response?.status === 422) {
        setError("Something went wrong")
        return
      }

      const errors = await e.response.json()

      if (errors.display_name) {
        setError("Meeting room name " + errors.display_name[0])
      } else {
        setError("Something went wrong")
      }
    }
  }

  function handleDisplayNameChange(e) {
    setDisplayName(e.target.value)
  }

  const user = props.zoomUser

  return (
    <div>
      <div className="display-flex flex-direction-column form">
        <label className="form__label">
          <div className="m-b-1">Name your meeting room</div>

          <input
            type="text"
            className="form__input"
            value={ displayName }
            onChange={ handleDisplayNameChange }
            ref={ input }
          />
        </label>
      </div>

      {
        error ?
        <div className="m-t-half font-size-xsmall color-pink">{ error }</div> :
        null
      }

      <div className="m-t-1 font-size-small color-gray">{ [user.first_name, user.last_name].join(' ') }</div>
      <div className="font-size-small color-gray truncate">{ user.email }</div>
      <div className="font-size-small color-gray font-style-italic truncate">{ capitalize(user.plan_type).replace('_', '-') } account</div>


      <div className="display-flex justify-content-flex-start flex-direction-row-reverse m-t-2">
        <button
          className="button bg-green"
          onClick={ submit }
          disabled={ submitting }
        >
          Save
        </button>

        <button
          className="button button--link without-focus-ring"
          onClick={ props.onClose }
          disabled={ submitting }
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

function SwitchZoomLinkConfirmation(props) {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  async function submit() {
    try {
      setSubmitting(true)
      await Api.zoomLinks.update(props.zoomLink.id, {zoom_user_id: props.zoomUser.id})
      props.onClose()
    } catch {
      setError("Something went wrong while connecting to Zoom.")
      setSubmitting(false)
    }
  }

  return (
    <div>
      <div className="m-b-1">Your Zoom is already linked elsewhere. Do you want to move it here?</div>

      {
        error ?
        <div className="font-size-xsmall color-pink">{ error }</div> :
        null
      }

      <div className="display-flex justify-content-flex-start flex-direction-row-reverse m-t-2">
        <button
          className="button bg-green"
          onClick={ submit }
          disabled={ submitting }
        >
          Move
        </button>

        <button
          className="button button--link without-focus-ring"
          onClick={ props.onClose }
          disabled={ submitting }
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

function ClaimZoomLink(props) {
  const environment = useContext(EnvironmentContext)
  const [claimingZoomLink, setClaimingZoomLink] = useState(false)
  const [error, setError] = useState(null)

  const popup = useRef(null)

  function openZoomPopup() {
    const path = `/zoom/auth?zoom_link_id=${props.zoomLink.id}`

    // Even though we use http for our Redirect URL in development
    // Zoom redirects us to https, which doesn't work. When you open
    // a popup in a new window, you can't edit the location bar, so
    // we can never complete the OAuth flow with Zoom. Opening in a
    // new tab is a worse user experience, but we need to do it to make
    // running this code in development work.
    if (environment === 'production') {
      popup.current = window.open(
        path,
        null,
        'width=800,height=600'
      )
    } else {
      popup.current = window.open(path)
    }
  }

  async function claimZoomLink() {
    try {
      setClaimingZoomLink(true)
      await Api.zoomLinks.update(props.zoomLink.id, {zoom_user_id: props.zoomUser.id})
    } catch {
      setError("Something went wrong while connecting to Zoom.")
      setClaimingZoomLink(false)
    }
  }

  function submit() {
    if (props.zoomUser) {
      claimZoomLink()
    } else {
      openZoomPopup()
    }
  }

  useEffect(() => {
    return () => {
      if (popup.current) {
        popup.current.close()
      }
    }
  }, [])

  const waitingForOauth = !!props.credential && !props.zoomUser
  const submitting = claimingZoomLink || waitingForOauth

  let style
  if (submitting) {
    style = {
      paddingLeft: 6,
      paddingRight: 6,
    }
  } else {
    // The loading indicator + its padding is 20, therefore
    // when it's not showing, we add 10px of padding to each
    // side to compensate.
    style = {
      paddingLeft: 16,
      paddingRight: 16,
    }
  }

  return (
    <div>
      <div className="m-b-1">
        Connect to show Zoom participants, topics, and join links for meetings you host.
      </div>

      {
        error ?
        <div className="font-size-xsmall color-pink">{ error }</div> :
        null
      }

      <div className="display-flex justify-content-flex-start flex-direction-row-reverse m-t-2">
        <button
          className="button bg-blue"
          style={ style }
          onClick={ submit }
          disabled={ submitting }
        >
          Connect with Zoom
          {
            submitting ?
            <i className="fas fa-circle-notch fa-spin m-l-half" /> :
            null
          }
        </button>

        <button
          className="button button--link without-focus-ring"
          onClick={ props.onClose }
          disabled={ submitting }
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

function ZoomLinkEditor(props) {
  const currentRealm = useContext(CurrentRealmContext)

  if (currentRealm.zoom_authentication_type === 'account') {
    return <OrgWideZoomLinkEditor {...props} />
  } else {
    return <IndividualZoomLinkEditor {...props} />
  }
}

class NoteEditor extends PureComponent {
  MAX_LENGTH = 2048;

  state = {
    noteText: this.props.block.note_text || "",
    submitting: false,
    error: null,
  }

  componentDidMount() {
    this.textarea.select();
  }

  stopEditing = (e) => {
    this.props.onClose();
  }

  handleChange = (e) => {
    if (e.target.value.length <= this.MAX_LENGTH) {
      this.setState({noteText: e.target.value});
    }
  }

  save = async (e) => {
    const { block } = this.props;
    const { noteText } = this.state;

    this.setState({submitting: true});

    try {
      await Api.notes.update(block.id, {note_text: noteText})
      this.stopEditing()
    } catch (e) {
      this.setState({submitting: false, error: "Something went wrong while updating this block."})
    }
  }

  render() {
    const { noteText, submitting, error } = this.state;

    return (
      <Modal onClose={ this.stopEditing }>
        <div className="display-flex flex-direction-column form">
          <label className="form__label">
            <div className="m-b-1">Note</div>
            <textarea value={ noteText } onChange={ this.handleChange } className="form__input" ref={ el => this.textarea = el }/>
          </label>

          <div className="display-flex flex-direction-column-reverse">
            <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
              <button className="button bg-green" onClick={ this.save } disabled={ submitting }>Save</button>
              <button className="button button--link without-focus-ring" onClick={ this.stopEditing } disabled={ submitting }>Cancel</button>
            </div>

            <div>
              <div className="font-size-xsmall">
                Use <a href="https://daringfireball.net/projects/markdown/basics" target="_blank" rel="noopener noreferrer">Markdown</a> for links, bold, and italics.
              </div>

              {
                error ?
                <div className="font-size-xsmall color-pink">{ error }</div> :
                null
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function ConfirmSwitchDeskEditor({onClose, onConfirm}) {
  return (
    <div>
      <p style={{maxWidth: 400}} className="m-t-0">You already have a desk somewhere else. Do you want to switch to this desk?</p>
      <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
        <button className="button bg-green" onClick={ onConfirm }>Switch</button>
        <button className="button button--link without-focus-ring" onClick={ onClose }>Cancel</button>
      </div>
    </div>
  )
}

function DeskStatusEditor({desk, onClose, onShowSubModal, onHideSubModal, status}) {
  const avatarId = useContext(OurAvatarIdContext)

  const isOurDesk = desk.owner?.id === avatarId
  const [text, setText] = useState(status?.text || "")

  const dateFormat = 'MMMM Do, YYYY';
  const [customDate, setCustomDate] = useState(moment().format(dateFormat))
  const [customTime, setCustomTime] = useState(() => {
    // Defaults to 30 minutes from now, rounded to the nearest 30 minute increment.
    const futureTime = moment().add(30, 'minutes')
    const roundedMinutes = Math.round(futureTime.minutes() / 30) * 30
    futureTime.minutes(0).add(roundedMinutes, 'minutes')
    return [futureTime.hours(), futureTime.minutes()]
  })

  let options = [
    ["Don’t clear", () => null],
    ["10 minutes",  () => moment().add(10, 'minutes').toISOString()],
    ["30 minutes",  () => moment().add(30, 'minutes').toISOString()],
    ["1 hour",      () => moment().add(1, 'hour').toISOString()],
    ["4 hours",     () => moment().add(4, 'hours').toISOString()],
    // Slack truncates to the nearest second, so we will too.
    ["Today",       () => moment().endOf('day').milliseconds(0).toISOString()],
    // Moment's week ends on Sunday but Slack's ends on Saturday. We'll
    // use Slack's.
    ["This week",   () => moment().endOf('week').subtract(1, 'day').milliseconds(0).toISOString()],
    ["Choose date and time", () => {
      const deordinalized = customDate.replace(/(\d+)(st|nd|rd|th)/, (match, num) => num)

      return moment(new Date(deordinalized)).hours(customTime[0]).minutes(customTime[1]).toISOString()
    }],
  ]

  const existingExpiresAt = status?.expires_at
  const matchesExisting = _.any(options, ([label, f]) => f() === existingExpiresAt)

  let formattedExistingExpiresAt
  if (status && !matchesExisting) {
    if (moment().isSame(existingExpiresAt, 'day')) {
      formattedExistingExpiresAt = moment(existingExpiresAt).format('h:mma')
    } else {
      formattedExistingExpiresAt = moment(existingExpiresAt).format('MMMM Do [at] h:mma')
    }

    options.unshift([formattedExistingExpiresAt, () => existingExpiresAt])
  }

  const [expiresAt, setExpiresAt] = useState(() => {
    if (!status) {
      return "30 minutes"
    } else {
      const [matchingLabel] = options.find(([label, f]) => f() === status.expires_at)

      return matchingLabel || formattedExistingExpiresAt
    }
  })
  const [emoji, setEmoji] = useState(status?.emoji || "💻")

  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  async function save() {
    setSubmitting(true)

    try {
      const timestamp = options.find(([label, f]) => label === expiresAt)[1]()

      await Api.desks.update(desk.id, {desk_status: text, desk_status_expires_at: timestamp, desk_emoji: emoji})
      onClose()
    } catch (e) {
      setError("Something went wrong while updating your status.")
      setSubmitting(false)
    }
  }

  async function clearStatus() {
    setSubmitting(true)

    try {
      await Api.status.destroy()
      onClose()
    } catch (e) {
      setError("Something went wrong while clearing your status.")
      setSubmitting(false)
    }
  }

  function handleShowSubModal() {
    if (onShowSubModal) {
      onShowSubModal()
    }
  }

  function handleHideSubModal() {
    if (onHideSubModal) {
      onHideSubModal()
    }
  }

  return (
    <div className="display-flex flex-direction-column form">
      <div className="m-b-1">
        <label htmlFor="desk-status-text-input" className="form__label">Set a status</label>
        <EmojiAndTextInput
          id="desk-status-text-input"
          emoji={ emoji }
          text={ text }
          onEmojiChange={ setEmoji }
          onTextChange={ setText }
          onShowEmojiPicker={ handleShowSubModal }
          onHideEmojiPicker={ handleHideSubModal }
          placeholder="What’s your status?"
          autofocus
        />
      </div>

      <label className="form__label">
        <div className="m-b-half">Clear after</div>
        <select
          className="form__input"
          value={ expiresAt }
          onChange={ e => setExpiresAt(e.target.value) }
        >
          {
            options.map(([label, _]) =>
              <option key={ label } value={ label }>
                { label }
              </option>
            )
          }
        </select>
      </label>

      { expiresAt === "Choose date and time" && <div className="display-flex m-t-half">
        <label className="form__label m-r-2 flex-1">
          <div className="m-b-half">Date</div>

          <DatePicker
            value={customDate}
            onChange={setCustomDate}
            format={dateFormat}
            onShow={ handleShowSubModal }
            onHide={ handleHideSubModal }
          />
        </label>

        <label className="form__label flex-1">
          <div className="m-b-half">Time</div>
          <TimePicker time={customTime} onChange={setCustomTime} className="flex-1" />
        </label>
      </div>}

      {
        error ?
        <div className="font-size-xsmall color-pink">{ error }</div> :
        null
      }

      <div className="display-flex flex-direction-row-reverse justify-content-space-between m-t-2">
        <div className="display-flex flex-direction-row-reverse justify-content-flex-start">
          <button className="button bg-green" onClick={ save } disabled={ submitting }>{isOurDesk ? "Set status" : "Use this desk"}</button>
          <button className="button button--link without-focus-ring" onClick={ onClose } disabled={ submitting }>Cancel</button>
        </div>

        <div>
          {
            status && isOurDesk ?
            <button className="button" onClick={ clearStatus } disabled={ submitting }>Clear status</button> :
            null
          }
        </div>
      </div>
    </div>
  )
}

function SomeoneElsesDeskEditor({desk, onClose}) {
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  async function cleanup() {
    setSubmitting(true)
    try {
      await Api.desks.cleanup(desk.id)
      onClose()
    } catch (e) {
      setError("Something went wrong while cleaning up this desk.")
      setSubmitting(false)
    }
  }

  return (
    <div>
      <div>This is {desk.owner.name}’s desk</div>
      {
        error ?
        <div className="font-size-xsmall color-pink p-t-1">{ error }</div> :
        null
      }

      <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
        <button className="button bg-green" onClick={ onClose } disabled={ submitting }>OK</button>
        <button className="button button--link without-focus-ring" onClick={ cleanup } disabled={ submitting }>Clean up desk</button>
      </div>
    </div>
  )
}

function deskWithoutExpiredData(desk) {
  if (!desk) {
    return null
  } else if (isExpired(getExpiresAt(desk))) {
    return {...desk, status: null, emoji: null}
  } else {
    return desk
  }
}

function DeskEditor({block, onClose, getOurDesk}) {
  const avatarId = useContext(OurAvatarIdContext)

  const [showingSubModal, setShowingSubModal] = useState(false)

  const desk = deskWithoutExpiredData(block)
  const ourDesk = deskWithoutExpiredData(getOurDesk())
  const isOurDesk = desk.owner?.id === avatarId

  const weHaveAnotherDesk = ourDesk && !isOurDesk
  const [showConfirmSwitchDialog, setShowConfirmSwitchDialog] = useState(weHaveAnotherDesk && !desk.owner)

  const [status, setStatus] = useState(null)
  const [loadingStatus, setLoadingStatus] = useState(true)

  useActionCable("StatusChannel", {
    received(status) {
      setStatus(status)
      setLoadingStatus(false)
    }
  })

  if (loadingStatus) {
    return null
  }

  return (
    <Modal onClose={ showingSubModal ? null : onClose } noscroll>
      {
        showConfirmSwitchDialog ?
        <ConfirmSwitchDeskEditor
          onClose={ onClose }
          onConfirm={ () => setShowConfirmSwitchDialog(false) }
        /> :
        isOurDesk || !desk.owner ?
        <DeskStatusEditor
          desk={ desk }
          onClose={ onClose }
          status={ status }
          onShowSubModal={ () => setShowingSubModal(true) }
          onHideSubModal={ () => setShowingSubModal(false) }
        /> :
        <SomeoneElsesDeskEditor desk={ desk } onClose={ onClose } />
      }
    </Modal>
  )
}

function eventIsHappeningNow(event) {
  const now = moment()

  return now.isSameOrAfter(moment(event.start_time)) && now.isSameOrBefore(moment(event.end_time))
}

function calendarHasEventNow(calendar) {
  return _.any(calendar.calendar_data, eventIsHappeningNow)
}

class RCCalendarEditor extends PureComponent {
  MAX_LENGTH = 140;

  constructor(props) {
    super(props);

    this.state = {
      calendarZoomUserId: props.block.zoom_user?.id,
      submitting: false,
      error: null,
      zoomUsers: [],
    }
  }

  async componentDidMount() {
    try {
      const resp = await Api.zoomUsers.index()
      const zoomUsers = await resp.json()

      this.setState({
        zoomUsers,
        calendarZoomUserId: this.state.calendarZoomUserId || zoomUsers[0]?.id
      })
    } catch (e) {
      this.setState({error: "Something went wrong when fetching Zoom users"})
    }
  }


  stopEditing = (e) => {
    this.props.onClose();
  }

  handleChange = (e) => {
    switch (e.target.name) {
    case "calendarZoomUserId":
      this.setState({calendarZoomUserId: parseInt(e.target.value, 10)});
      break;
    }
  }

  save = (e) => {
    const { block } = this.props;
    const { calendarZoomUserId } = this.state;

    this.setState({submitting: true});

    Api.rcCalendars.update(block.id, {
      zoom_user_id: calendarZoomUserId,
    }).then(
      () => {
        this.stopEditing();
      },
      () => {
        this.setState({submitting: false, error: "Something went wrong while updating this calendar."});
      }
    )
  }

  render() {
    const { zoomUsers, calendarZoomUserId, submitting, error } = this.state;

    return (
      <Modal onClose={ this.stopEditing }>
        <div className="m-t-2 display-flex flex-direction-column form">
          <label className="form__label m-b-1">
            <div className="m-b-1">Show events for</div>

            <select name="calendarZoomUserId" value={ calendarZoomUserId } onChange={ this.handleChange } className="form__input">
            {
              zoomUsers.map(u =>
                <option key={u.id} value={u.id}>{ u.display_name }</option>
              )
            }
            </select>
          </label>

          {
            error ?
            <div className="font-size-xsmall color-pink m-t-1">{ error }</div> :
            null
          }

          <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
            <button className="button bg-green" onClick={ this.save } disabled={ submitting }>Save</button>
            <button className="button button--link without-focus-ring" onClick={ this.stopEditing } disabled={ submitting }>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }
}


class WallEditor extends PureComponent {
  state = {
    text: this.props.block.wall_text || "",
  };

  componentDidMount() {
    const { text } = this.state;

    document.addEventListener('keydown', this.handleKeyDown);

    this.input.focus();
    this.input.setSelectionRange(text.length, text.length);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    switch (e.key) {
    case "Escape":
      this.stopEditing();
      break;
    case "Enter":
      this.save();
      break;
    }
  }

  handleChange = (e) => {
    const splitter = new GraphemeSplitter()
    if (splitter.countGraphemes(e.target.value) > 1) {
      return;
    }

    this.setState({text: e.target.value});
  }

  async save() {
    const { block } = this.props;
    const { text } = this.state;

    try {
      await Api.walls.update(block.id, {wall_text: text})
      this.stopEditing()
    } catch (e) {
      alert("Something went wrong while updating this block");
    }
  }

  stopEditing = () => {
    this.props.onClose();
  }

  render() {
    const { worldToViewport, block } = this.props;
    const { text } = this.state;

    const viewportPos = worldToViewport(block.pos);

    const x = viewportPos.x * WIDTH;
    const y = viewportPos.y * HEIGHT;

    return (
      <ClickOutside onClick={ this.stopEditing } trigger="mousedown">
        <div className="position-absolute" style={{top: y, left: x}}>
          <input
            type="text"
            className="wall-editor"
            style={{width: WIDTH, height: HEIGHT}}
            ref={ input => this.input = input }
            value={ text }
            onChange={ this.handleChange }
          />
        </div>
      </ClickOutside>
    );
  }
}

function PhotoBlockEditor({block, onClose}) {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const [photoBlock, setPhotoBlock] = useState(null)
  const loading = !photoBlock

  const [blob, setBlob] = useState(null)
  const [upload, uploading, progress] = useDirectUpload()

  useEffect(() => {
    async function fetchPhotoBlock() {
      const response = await get(`/photo_blocks/${block.id}`, {responseKind: 'json'})

      if (!response.ok) {
        alert("Something went wrong while fetching this photo.")
        onClose()
        return
      }

      try {
        const json = await response.json
        setPhotoBlock(json)
      } catch (e) {
        console.error(e)
        alert("Something went wrong while decoding this photo.")
        onClose()
      }
    }

    fetchPhotoBlock()
  }, [])

  async function uploadFile(file) {
    if (!file) {
      console.error("uploadFile called but file is not present")
      setError("Something went wrong while uploading this photo.")
      return
    }

    if (blob) {
      const response = await blob.destroy()

      if (!response.ok) {
        console.error("could not destroy existing blob")
        setError("Something went wrong while uploading this photo.")
        return
      }
    }

    try {
      const newBlob = await upload(file)
      setBlob(newBlob)
      setError(null)
    } catch (e) {
      console.error("could not upload file", e)
      setError("Something went wrong while uploading this photo.")
    }
  }

  async function save() {
    setSubmitting(true)

    let body = {
      photo_caption: photoBlock.photo_caption
    }

    if (blob) {
      body.photo = blob.signedId
    }

    const response = await patch(`/photo_blocks/${block.id}`, {
      body,
      contentType: 'application/json',
      responseKind: 'json',
    })

    if (response.ok) {
      onClose()
    } else if (response.statusCode === 422) {
      const errors = await response.json
      setSubmitting(false)
      setError(errors.join(". "))
    } else {
      setSubmitting(false)
      setError("Something went wrong while updating this block.")
    }
  }

  async function cancel() {
    if (blob) {
      const response = await blob.destroy()
      if (!response.ok) {
        console.error("could not destroy existing blob while closing the window")
      }
    }

    onClose()
  }

  function updateCaption(e) {
    setPhotoBlock({
      ...photoBlock,
      photo_caption: e.target.value,
    })
  }

  if (loading) {
    return null
  }

  return (
    <Modal onClose={ cancel } maxWidth={400}>
      <div className="form">
        <label className="form__label" htmlFor="photo_block_photo">Photo</label>
        <ImageUploader
          id="photo_block_photo"
          src={blob?.url || photoBlock.photo_url}
          onUpload={uploadFile}
          uploading={uploading}
          progress={progress}
        />

        <label className="form__label">
          <div className="m-b-1">Caption</div>
          <input value={photoBlock.photo_caption || "" } className="form__input" onChange={updateCaption} />
        </label>

        {
          error ?
          <div className="font-size-xsmall color-pink">{ error }</div> :
          null
        }

        <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
          <button className="button bg-green" onClick={ save } disabled={ submitting || uploading }>Save</button>
          <button className="button button--link without-focus-ring" onClick={ cancel } disabled={ submitting || uploading }>Cancel</button>
        </div>
      </div>
    </Modal>
  )
}

const EDITORS = {
  ZoomLink: ZoomLinkEditor,
  Note: NoteEditor,
  Link: LinkEditor,
  "RC::Calendar": RCCalendarEditor,
  Wall: WallEditor,
  Desk: DeskEditor,
  AudioBlock: AudioBlockEditor,
  PhotoBlock: PhotoBlockEditor,
}

function isEditable(block) {
  return !!EDITORS[block.type];
}

function indexKey(pos) {
  return `${pos.x},${pos.y}`;
}

function parseIndexKey(indexKey) {
  const [x, y] = indexKey.split(",").map((s) => parseInt(s, 10))
  return {x, y}
}

function createPosIndex(entities) {
  return _.object(_.map(_.flatten(_.values(entities)), e => [e.id, indexKey(e.pos)]));
}

function addOrUpdateEntity(entities, entity) {
  let clone = entities ? entities.slice() : [];

  const i = _.findIndex(clone, e => e.id === entity.id);

  if (i === -1) {
    clone.push(entity);
  } else {
    clone[i] = entity;
  }

  return clone;
}

class ImageCache {
  constructor(brokenImageSrc) {
    this.images = {};
    this.loaded = {};
    this.brokenImage = new Image()
    this.brokenImage.src = brokenImageSrc
  }

  load(src, callback) {
    if (this.isLoaded(src) || this.alreadyRequested(src)) {
      return;
    }

    const image = new Image();
    image.src = src
    this.images[src] = image;

    image.addEventListener('load', () => {
      this.loaded[src] = true;

      callback(image);
    });

    image.addEventListener('error', () => {
      this.loaded[src] = true
      this.images[src] = this.brokenImage

      callback(this.brokenImage)
    });

  }

  get(src) {
    return this.images[src];
  }

  isLoaded(src) {
    return !!this.loaded[src];
  }

  alreadyRequested(src) {
    return !!this.images[src];
  }
}


const DIRECTION_TO_PLACEMENT = {
  up: 'top',
  right: 'right',
  down: 'bottom',
  left: 'left',
}

function Desk({entity, visibleOwner, worldToViewport, overlayPlacement, posFacing, spotlightEntity, walkToAndEdit, clearSelectedPos}) {
  // visibleOwner is the avatar that owns this desk (their full
  // representation, with position, zoom room, etc., as opposed to the
  // reduced representation on desk.owner) if that avatar is visible
  // (i.e. in CanvasWorld#state.entities), otherwise it's null

  const ourAvatarId = useContext(OurAvatarIdContext)

  const desk = deskWithoutExpiredData(entity)
  const viewportPos = worldToViewport(desk.pos)

  const [lastSeen, setLastSeen] = useState(null)

  async function fetchLastSeen() {
    if (desk.owner) {
      setLastSeen(null)
      const resp = await Api.avatars.show(desk.owner.id)
      const owner = await resp.json()
      setLastSeen(owner.last_seen_at)
    }
  }

  useEffect(() => { fetchLastSeen() }, [desk?.owner?.id])

  function spotlightOwner(e) {
    e.preventDefault()

    clearSelectedPos()

    spotlightEntity(visibleOwner)
  }

  function editStatus(e) {
    e.preventDefault()

    // visibleOwner will always be our avatar, since we only call
    // editStatus if desk.owner.id is equal to our avatar id (as fetched
    // from OurAvatarIdContext)
    walkToAndEdit(desk.pos, visibleOwner)
  }

  if (desk.owner) {
    const statusExpiresAt = desk.status && desk.expires_at && moment(desk.expires_at)
    const doesStatusExpireToday = statusExpiresAt && statusExpiresAt.isSame(moment(), 'day')

    const popoverContent = (
      <div className="display-flex pointer-events-auto">
        <div>
          <img className="circular-image" src={ desk.owner.image_url } />

          {
            desk.emoji ?
            <div style={{ marginTop: -20, zIndex: 2, position: 'relative' }}>
              <Emoji native={desk.emoji} size={32} />
            </div> :
            null
          }
        </div>

        <div className="display-flex flex-direction-column m-l-1" style={{ minWidth: 275 }}>
          <div className="display-flex align-items-baseline">
            <h3 className="m-y-0">{ desk.owner.name }</h3>
            {
              desk.profile_url ?
              <a className="font-style-italic font-size-xsmall m-l-1 color-green" href={ desk.profile_url } onClick={ clearSelectedPos } target="_blank" rel="noopener noreferrer">view profile</a> :
              null
            }
          </div>

          <div className="font-size-small color-gray">
            {
              visibleOwner ?
              <a className="color-blue text-decoration-none" href="" onClick={ spotlightOwner }><i className="fa fa-search"></i> <span className="underline">Show on map</span></a> :
              <em className="color-gray">{ lastSeen ? `Last seen ${moment(lastSeen).fromNow()}` : <>&nbsp;</> }</em>
            }
          </div>

          <div className="m-t-1">{ desk.status ? desk.status : <em className="color-gray">No status set</em> }</div>

          <div className="flex-1" />

          <div className="display-flex">
            {
              statusExpiresAt ?
              <em className="color-gray font-size-xsmall m-r-1">until { doesStatusExpireToday ?
              statusExpiresAt.format('h:mma') :
              statusExpiresAt.format('MMMM Do [at] h:mma') }</em> :
              null
            }

            {
              desk.owner?.id == ourAvatarId ?
              <a className="font-size-xsmall color-green" href="#" onClick={ editStatus }>edit status</a> :
              null
            }
          </div>

        </div>
      </div>
    )

    return (
      <Popover
        content={ popoverContent }
        alwaysVisible
        placement={ overlayPlacement }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    )
  } else {
    const interaction = _.isEqual(posFacing, entity.pos) ? "Press `e`" : "Double-click"

    return (
      <Tooltip
        alwaysVisible
        text={ `Unused desk. ${interaction} to use this desk` }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  }
}

function AudioBlock({entity, posFacing, worldToViewport, overlayPlacement}) {
  const audioBlock = entity
  const viewportPos = worldToViewport(audioBlock.pos)

  const popoverContent = (
    <div className="white-space-nowrap pointer-events-auto">
      <div className="centered-text font-weight-bold font-size-large">{ audioBlock.name || "Audio block" }</div>
      <div className="centered-text font-size-small color-gray">
        {
          audioBlock.mute_on_entry ?
          <>Mics muted on entry, press <kbd className="kbd">m</kbd> to unmute</> :
          "Audio block"
        }
      </div>
    </div>
  )

  return (
    <Popover
      content={ popoverContent }
      alwaysVisible
      placement={ overlayPlacement }
      wrapperClassName="position-absolute z-10 pointer-events-none"
      wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
    />
  )
}

function PhotoBlock({entity, posFacing, worldToViewport, overlayPlacement}) {
  const [photoBlock, setPhotoBlock] = useState(null)
  const loading = entity.photo_attached && !photoBlock

  useEffect(() => {
    async function fetchPhotoBlock() {
      const response = await get(`/photo_blocks/${entity.id}`, {responseKind: 'json'})

      if (!response.ok) {
        alert("Something went wrong while fetching this photo.")
        return
      }

      try {
        const json = await response.json
        setPhotoBlock(json)
      } catch (e) {
        console.error(e)
        alert("Something went wrong while decoding this photo.")
      }
    }

    if (entity.photo_attached) {
      fetchPhotoBlock()
    }

    return () => { setPhotoBlock(null) }
  }, [entity])


  if (loading) {
    return null
  }

  const viewportPos = worldToViewport(entity.pos)

  if (entity.photo_attached) {
    return <Popover
      alwaysVisible
      placement={ overlayPlacement }
      wrapperClassName="position-absolute z-10 pointer-events-none"
      wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      content={
        <div style={{width: 500}}>
          <img src={photoBlock.photo_url} className="full-width" />
          <div>
            { !_.isEmpty(photoBlock.photo_caption) && <span className="color-dark-gray font-size-medium">{photoBlock.photo_caption} </span> }
            <span className="color-gray font-size-xsmall">
              Uploaded by {photoBlock.photo_uploaded_by.person_name} on {moment(photoBlock.photo_uploaded_by).format("MMM Do, YYYY")}
            </span>
          </div>
        </div>
      }
    />
  } else {
    const interaction = _.isEqual(posFacing, entity.pos) ? "Press `e`" : "Double-click"

    return (
      <Tooltip
        alwaysVisible
        text={ `${interaction} to upload a photo` }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  }
}

function meetingTopicForDisplay(user, meeting) {
  if (meeting.meeting_type === "personal" && user.display_name.indexOf(user.first_name) !== -1) {
    return "Personal Meeting"
  } else if (meeting.meeting_type === "personal") {
    return `${user.first_name}’s Personal Meeting`
  } else {
    return meeting.topic
  }
}

function formatShortTime(timestamp) {
  const m = moment(timestamp)

  if (m.minutes() === 0) {
    return m.format("ha")
  } else {
    return m.format("h:mma")
  }
}


function MainMeeting(props) {
  const { user, meeting, allMeetings, clearSelectedPos, handleButtonKeyDown } = props

  return (
    <div>
      {
        allMeetings.length > 1 ?
        <div>
          <div className="centered-text" style={{margin: '0 auto'}}>{ meetingTopicForDisplay(user, meeting) }</div>
          {
            meeting.start_time && meeting.end_time ?
            <div className="m-b-half color-dark-gray font-size-small centered-text">
              {formatShortTime(meeting.start_time)} – {formatShortTime(meeting.end_time)}
            </div> :
            meeting.start_time ?
            <div className="m-b-half color-dark-gray font-size-small">{formatShortTime(meeting.start_time)}</div> :
            null
          }
        </div> :
        null
      }

      <div className="centered-text font-size-small color-gray p-b-1">{ pluralize(meeting.participants.length, 'person') } on Zoom</div>
      <div className="display-flex m-b-1">
        <ul className="font-size-small list-style-none m-a-0 p-a-0 overflow-y-scroll full-width centered-text" style={{maxHeight: 300}}>
          { meeting.participants.map(p => <li key={p.id}>{p.person_name}</li>) }
        </ul>
      </div>

      <div className="display-flex justify-content-space-around">
        <a
          href={ meeting.url }
          className="button bg-light-blue text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
          onClick={ clearSelectedPos }
        >
          Join on Zoom
        </a>
      </div>
    </div>
  )
}

function MeetingTime({meeting}) {
  return (
    <div className="color-dark-gray font-size-xsmall">
      {
        meeting.start_time && meeting.end_time ?
        formatShortTime(meeting.start_time) + " – " + formatShortTime(meeting.end_time) :
        meeting.start_time ?
        formatShortTime(meeting.start_time) :
        meeting.meeting_type === "recurring_no_fixed_time" ?
        "Recurring" :
        "Ad-hoc"
      }
    </div>
  )
}

function AllMeetings(props) {

  return (
    <div className="display-flex flex-direction-column overflow-y-auto m-t-1" style={{maxHeight: 300}}>
      {
        props.meetings.map(m => (<div key={m.id} className="display-flex flex-direction-row justify-content-space-between  m-b-1">
          <div>
            <MeetingTime meeting={m} />
            <div className="font-size-small">{meetingTopicForDisplay(props.user, m)}</div>
          </div>
          <div className="m-l-half">
            <a
              href={ m.url }
              className="button bg-light-blue text-decoration-none font-size-xsmall m-t-half"
              target="_blank"
              rel="noopener noreferrer"
              onClick={ props.clearSelectedPos }
            >
              Join
            </a>
            <div className="font-size-xxsmall color-gray">{ pluralize(m.participants.length, 'person')}</div>
          </div>
        </div>))
      }
    </div>
  )
}

function ZoomLink(props) {
  const { overlayPlacement, worldToViewport, entity, posFacing, onPopoverButtonFocusChanged, clearSelectedPos } = props

  const requestedZoomUserId = entity.zoom_user?.id
  const viewportPos = worldToViewport(entity.pos);
  const joinButton = useRef(null)

  const [meetings, setMeetings] = useState(null)
  const [user, setUser] = useState(null)

  const [showAllMeetings, setShowAllMeetings] = useState(false)

  function toggleShowAllMeetings() {
    setShowAllMeetings(!showAllMeetings)
  }

  useEffect(() => {
    setMeetings(null)
    setUser(null)
    setShowAllMeetings(false)
  }, [requestedZoomUserId])

  useActionCable({channel: "ZoomUserChannel", id: requestedZoomUserId}, {
    connected: () => {
      // intentionally blank
    },

    received(event) {
      switch (event.type) {
        case 'initial_data':
          setUser(event.payload.user)
          setMeetings(event.payload.meetings)
          break
        case 'user':
          setUser(event.payload)
          break
        case 'meeting_deleted':
          setMeetings(_.reject(meetings, m => m.id === event.payload.id))
          break
        case 'meeting': {
          setMeetings(replaceBy(meetings, event.payload, 'id'))
          break
        }
      }
    }
  }, !!requestedZoomUserId)

  function isHappeningNowOrSoon(meeting) {
    if (meeting.start_time) {
      const start = moment(meeting.start_time).subtract(10, 'minutes')
      const end = meeting.end_time ? moment(meeting.end_time) : moment(meeting.start_time).add(1, 'hour')
      return start.isBefore(moment()) && end.isAfter(moment())
    } else {
      return false
    }
  }

  function mostRelevantMeeting(meetings) {
    const withPeople = meetings.filter(m => m.participants.length > 0)

    if (withPeople.length > 0) {
      return _.first(_.sortBy(withPeople, m => -m.participants.length))
    }

    const happeningNow = meetings.filter(isHappeningNowOrSoon)

    if (happeningNow.length > 0) {
      return _.first(_.sortBy(happeningNow, m => -moment(m.start_time).unix()))
    }

    const personal = _.findWhere(meetings, {meeting_type: 'personal'})

    if (personal) {
      return personal
    } else {
      return _.first(meetings)
    }
  }

  function shouldShowScheduled(meeting) {
    const start = moment(meeting.start_time)
    const end = meeting.end_time ? moment(meeting.end_time) : moment(meeting.start_time).add(1, 'hour')
    const hasParticipants = meeting.participants.length > 0
    return hasParticipants || (end.isAfter(moment()) && start.isBefore(moment().add(12, 'hours')))
  }

  function sortedMeetingsToShow(meetings) {
    const unscheduledWithPeople = []
    const scheduled = []
    const personal = []

    const timeSorted = _.sortBy(meetings, m => m.start_time ? moment(m.start_time).unix() : 0)

    for (let meeting of timeSorted) {
      if (!meeting.start_time && meeting.participants.length > 0) {
        unscheduledWithPeople.push(meeting)
      } else if (meeting.start_time && shouldShowScheduled(meeting)) {
        scheduled.push(meeting)
      } else if (meeting.meeting_type === "personal" && meeting.participants.length === 0) {
        personal.push(meeting)
      }
    }

    return unscheduledWithPeople.concat(scheduled).concat(personal)
  }

  // We're waiting for the user to load, so don't render anything
  if (requestedZoomUserId && !user) {
    return null
  }

  if (user && meetings) {
    const meetingsToShow = sortedMeetingsToShow(meetings)
    const meeting = mostRelevantMeeting(meetingsToShow)

    const popoverContent = (
      <div className="pointer-events-auto">
        <div className="centered-text font-weight-bold font-size-large">{ user.display_name }</div>

        {
          showAllMeetings ?
          <AllMeetings
            user={ user }
            meetings={ meetingsToShow }
            clearSelectedPos={ clearSelectedPos }
          /> :
          meeting ?
          <MainMeeting
            user={ user }
            allMeetings={ meetingsToShow }
            meeting={ meeting }
            clearSelectedPos={ clearSelectedPos }
          /> :
          <div className="color-gray centered-text font-size-small">No meetings</div>
        }

        {
          meetingsToShow.length > 1 ?
          <div className="m-t-1 display-flex justify-content-center">
            <button
              className="button button--link font-size-xsmall color-dark-gray no-border p-a-0 underline"
              onClick={ toggleShowAllMeetings }
            >
              {
                showAllMeetings ? "Show main meeting" : "Show all meetings"
              }
            </button>
          </div> :
          null
        }
      </div>
    )

    return (
      <Popover
        content={ popoverContent }
        alwaysVisible
        popoverStyle={{width: 200}}
        placement={ overlayPlacement }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    )
  } else {
    const interaction = _.isEqual(posFacing, entity.pos) ? "Press `e`" : "Double-click"

    return (
      <Tooltip
        alwaysVisible
        text={`${interaction} to set up Zoom` }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  }
}

function Bot({entity, worldToViewport}) {
  const bot = entity
  const viewportPos = worldToViewport(bot.pos)

  return (
    <Tooltip
      alwaysVisible
      text={ `${bot.person_name} - ${bot.app.name}` }
      wrapperClassName={ `position-absolute z-10 pointer-events-none` }
      wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
    />
  )
}

function zoomStatus(display_name, topic) {
  if(topic && display_name) {
    return `${display_name} for ${topic}`
  } else if (display_name) {
    return display_name
  } else {
    return null
  }
}


function Avatar(props) {
  const { worldToViewport, entity } = props
  const viewportPos = worldToViewport(entity.pos)

  let text = entity.person_name
  if (entity.flair) {
    text += ` (${entity.flair})`
  }
  if (entity.muted) {
    text += " (muted)"
  }
  if (entity.zoom_user_display_name) {
    text += ` (${zoomStatus(entity.zoom_user_display_name, entity.zoom_meeting_topic)})`
  }

  return (
    <Tooltip
      alwaysVisible
      text={ text }
      wrapperClassName={ `position-absolute z-10 pointer-events-none` }
      wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
    />
  )
}

function FormattedMessage(props) {
  return (
    <span>
      {
        extractChatMessageSegments(props.message).map((segment, i) => {
          if (_.includes(['mention', 'zoom'], segment.type)) {
            return <a
              key={ i }
              href="#"
              className={ classSet("color-blue", "text-decoration-none", {"font-weight-bold": segment.type === "zoom"}) }
              onClick={ (e) => {e.preventDefault(); props.onEntityClick(segment.entityId) }}
            >
              { segment.text }
            </a>
          } else if (segment.type === 'pos') {
            return <a
              key={ i }
              href="#"
              className={ classSet("color-blue", "text-decoration-none") }
              onClick={ (e) => {e.preventDefault(); props.onPosClick(segment.pos) }}
            >
              { segment.text }
            </a>
          } else {
            return <span key={ i }>{ segment.text }</span>
          }
        })
      }
    </span>
  )
}

function Message(props) {
  const { worldToViewport, avatar, ourAvatar } = props
  const viewportPos = worldToViewport(avatar.pos)
  const { message } = avatar
  const isMentioned = _.any(message.mentioned_entity_ids, id => id === ourAvatar().id)

  let name = avatar.person_name;
  if (avatar.muted) {
    name += " (muted)"
  }

  if (avatar.zoom_user_display_name) {
    name += ` (${avatar.zoom_user_display_name})`
  }

  const content = <>
    <div className="font-size-small">
      <div style={{ width: 250 }}>
        <span className="color-gray">{ moment(message.sent_at).format('h:mma') } </span>

        <FormattedMessage
          message={ message }
          onEntityClick={ id => props.spotlightEntity(props.getEntityById(id)) }
          onPosClick={props.spotlightPos}
        />
      </div>
    </div>

    <div className="right-text font-size-xsmall m-t-half">– {name}</div>
  </>

  if (isMentioned) {
    return (
      <Popover
        alwaysVisible
        content={ content }
        wrapperClassName="position-absolute z-100"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    )
  } else {
    return (
      <div className="position-absolute z-100 font-size-xxlarge" style={{top: (viewportPos.y-1)*HEIGHT + 0.18*HEIGHT, left: (viewportPos.x*WIDTH) + 0.74*WIDTH}} >
        <i className="fas fa-comment-dots"></i>
      </div>
    )
  }
}

function UnknownAvatar(props) {
  const { worldToViewport, entity } = props;
  const viewportPos = worldToViewport(entity.pos);

  return (
    <Tooltip
      alwaysVisible
      text={ `${entity.person_name} (${zoomStatus(entity.zoom_user_display_name, entity.zoom_meeting_topic)})` }
      wrapperClassName={ `position-absolute z-10 pointer-events-none` }
      wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
    />
  );
}

function Link(props) {
  const { overlayPlacement, worldToViewport, entity, posFacing, onPopoverLinkFocusChanged } = props
  const viewportPos = worldToViewport(entity.pos);

  const { url } = entity;
  const link = useRef(null)

  function handleKeyDown(e) {
    if (e.key !== "Tab") {
      return
    }

    e.preventDefault()

    if (link.current && document.activeElement === link.current) {
      link.current.blur()
      onPopoverLinkFocusChanged(false)
    } else if (link.current) {
      link.current.focus()
      onPopoverLinkFocusChanged(true)
    }
  }

  useEventListener('keydown', handleKeyDown, document)

  // Normally, only "Enter" opens a focused link. We want Space to do it too.
  function handleLinkKeyDown(e) {
    if (link.current && e.key === " ") {
      link.current.click()
    }
  }

  if (url) {
    const popoverContent = (
      <div className="white-space-nowrap pointer-events-auto">
        <div className="display-flex justify-content-space-around">
          <a
            href={ url }
            target="_blank"
            rel="noopener noreferrer"
            ref={ link }
            onKeyDown={ handleLinkKeyDown }
          >
            { url }
          </a>
        </div>
      </div>
    )

    return (
      <Popover
        content={ popoverContent }
        alwaysVisible
        placement={ overlayPlacement }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  } else {
    const interaction = _.isEqual(posFacing, entity.pos) ? "Press `e`" : "Double-click"

    return (
      <Tooltip
        alwaysVisible
        text={ `${interaction} to add a link` }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  }
}

function Note(props) {
  const { worldToViewport, entity, posFacing, overlayPlacement } = props;
  const viewportPos = worldToViewport(entity.pos);

  const { note_text, updated_by, note_updated_at } = entity;

  if (note_text) {
    const popoverContent = (
      <div className="pointer-events-auto p-a-half font-size-xsmall">
        <div className="no-outer-paragraph-margin" style={{ minWidth: 275 }}>
          {
            unified()
              .use(remarkParse)
              .use(remarkExternalLinks)
              .use(remark2react, React)
              .processSync(note_text).result
          }
        </div>

        {
          updated_by && note_updated_at ?
          <div className="right-text color-gray m-t-1 font-style-italic">
            Updated { moment(note_updated_at).fromNow() } by { updated_by.name }
          </div> :
          null
        }
      </div>
    )

    return (
      <Popover
        alwaysVisible
        content={ popoverContent }
        placement={ overlayPlacement }
        popoverClassName="bg-light-yellow border-yellow"
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  } else {
    const interaction = _.isEqual(posFacing, entity.pos) ? "Press `e`" : "Double-click"

    return (
      <Tooltip
        alwaysVisible
        text={ `${interaction} to write a note` }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  }
}

function RCCalendar(props) {
  const { worldToViewport, entity, posFacing, overlayPlacement } = props;
  const viewportPos = worldToViewport(entity.pos);

  const { calendar_data, zoom_user, updated_at } = entity;

  const updatedAt = moment(updated_at)

  if (zoom_user) {
    const popoverContent = (
      <div className="pointer-events-auto" style={{width: 200}}>
        <div className="centered-text font-weight-bold font-size-large">{ zoom_user.display_name }</div>
        <div className="centered-text font-size-small color-gray m-b-1">Scheduled events</div>
        {
          !_.isEmpty(calendar_data) ?
          <ol className="list-style-none color-gray m-a-0 p-a-0 overflow-y-scroll" style={{maxHeight: 300}}>
              {
                calendar_data.map((event) => {
                  const classNames = classSet({
                    'p-b-1': true,
                    'bg-light-yellow': eventIsHappeningNow(event),
                  })

                  return (
                    <li key={ event.url } className={ classNames } style={{paddingLeft: '0.75rem', paddingRight: '0.75rem'}}>
                      <div className="font-size-xsmall">
                        { moment(event.start_time).format('h:mma') } – { moment(event.end_time).format('h:mma')}
                      </div>
                      <div className="font-size-small"><a href={ event.url } target="_blank" rel="noopener noreferrer">{ event.title }</a></div>
                    </li>
                  )
                })
              }
          </ol> :
          <div className="color-gray display-flex justify-content-center">
            <div>No events on <a href="https://www.recurse.com/calendar" target="_blank" rel="noopener noreferrer">the calendar</a>.</div>
          </div>
        }
      </div>
    )

    return (
      <Popover
        content={ popoverContent }
        alwaysVisible
        placement={ overlayPlacement }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
        popoverClassName="p-x-0"
      />
    );
  } else {
    const interaction = _.isEqual(posFacing, entity.pos) ? "Press `e`" : "Double-click"

    return (
      <Tooltip
        alwaysVisible
        text={ `${interaction} to set up calendar` }
        wrapperClassName="position-absolute z-10 pointer-events-none"
        wrapperStyle={{top: viewportPos.y*HEIGHT, left: viewportPos.x*WIDTH, width: WIDTH, height: HEIGHT}}
      />
    );
  }
}

function EntityHoverOverlay({pos, entitiesAt, worldToViewport, ourAvatar}) {
  const entities = entitiesAt(pos)
  if (!entities) return null

  const us = ourAvatar()
  // In order of descending preference, we highlight:
  //  • Your own avatar
  //  • Someone else’s Avatar or UnknownAvatar
  //  • Bot
  // Note: keep this order in sync with the order in CanvasWorld’s draw() method.
  function scoreEntity(entity) {
    if (entity === us) return 3
    if (entity.type === 'Avatar' || entity.type === 'UnknownAvatar') return 2
    if (entity.type === 'Bot') return 1
    return 0
  }
  const entity = _.last(entities.map(entity => ({
    entity,
    score: scoreEntity(entity),
  })).sort((a, b) => a.score - b.score))?.entity

  if (!entity) {
    return null
  }

  if (entity.type === 'Avatar') {
    return <Avatar entity={ entity } worldToViewport={ worldToViewport } />
  } else if (entity.type === 'UnknownAvatar') {
    return <UnknownAvatar entity={ entity } worldToViewport={ worldToViewport } />
  } else if (entity.type === 'Bot') {
    return <Bot entity={ entity } worldToViewport={ worldToViewport } />
  }


  return null
}

function EntityOverlay({pos, posFacing, overlayPlacement, entitiesAt, getEntityById, worldToViewport, clearSelectedPos, shouldSuppressSpaceAndEnter, setShouldSuppressSpaceAndEnter, spotlightEntity, walkToAndEdit }) {
  const entities = entitiesAt(pos) || [];

  useEffect(() => {
    return () => setShouldSuppressSpaceAndEnter(false)
  }, [])

  function handleKeyDown(e) {
    let keys = _.keys(CanvasWorld.KEYDOWN_HANDLERS)

    if (shouldSuppressSpaceAndEnter) {
      keys = _.without(keys, ' ', 'Enter')
    }

    if (_.contains(keys, e.key)) {
      clearSelectedPos()
    }
  }

  useEventListener('keydown', handleKeyDown, document)

  for (const entity of entities) {
    if (entity.type === 'ZoomLink') {
      return <ZoomLink entity={ entity }
                       posFacing={ posFacing }
                       worldToViewport={ worldToViewport }
                       overlayPlacement={ overlayPlacement }
                       clearSelectedPos={ clearSelectedPos }
                       onPopoverButtonFocusChanged={ setShouldSuppressSpaceAndEnter } />;
    } else if (entity.type === 'Link') {
      return <Link
               entity={ entity }
               posFacing={ posFacing }
               worldToViewport={ worldToViewport }
               overlayPlacement={ overlayPlacement }
               onPopoverLinkFocusChanged={ setShouldSuppressSpaceAndEnter } />;
    } else if (entity.type === 'Desk') {
      return <Desk
               entity={ entity }
               visibleOwner={ entity.owner ? getEntityById(entity.owner.id) : null }
               posFacing={ posFacing }
               worldToViewport={ worldToViewport }
               overlayPlacement={ overlayPlacement }
               spotlightEntity={ spotlightEntity }
               clearSelectedPos={ clearSelectedPos }
               walkToAndEdit={ walkToAndEdit } />;
    } else if (entity.type === 'RC::Calendar') {
      return <RCCalendar
               entity={ entity }
               posFacing={ posFacing }
               worldToViewport={ worldToViewport }
               overlayPlacement={ overlayPlacement } />;
    } else if (entity.type === 'Note') {
      return <Note
               entity={ entity }
               posFacing={ posFacing }
               worldToViewport={ worldToViewport }
               overlayPlacement={ overlayPlacement } />;
    } else if (entity.type === 'AudioBlock') {
      return <AudioBlock
               entity={ entity }
               posFacing={ posFacing }
               worldToViewport={ worldToViewport }
               overlayPlacement={ overlayPlacement } />
    } else if (entity.type === 'PhotoBlock') {
      return <PhotoBlock
               entity={ entity }
               posFacing={ posFacing }
               worldToViewport={ worldToViewport }
               overlayPlacement={ overlayPlacement } />
    }
  }

  return null;
}

// TODO: When a user has two windows open, and they're in an audio room,
// every time they move they disconnect and reconnect.
class TwilioRoom {
  constructor(room) {
    this.room = room;
    this.state = 'not_connected';
    this.promise = null;
    this.muteOnReconnect = false

    this.onConnect = null
    this.onReconnecting = null
    this.onDisconnect = null

    this.disconnectCounter = 0

    this.localStorageInterval = null

    this.volume = 1
    this.audioElements = []
  }

  // this returns the RC Together room ID, not the Twilio room ID
  entityId() {
    return this.room.id
  }

  continuouslyConnected() {
    return this.disconnectCounter === 0
  }

  resetDisconnectCounter() {
    this.disconnectCounter = 0
  }

  connect(token, options={}) {
    if (this.state === 'connecting' || this.state === 'connected') {
      return;
    }
    this.state = 'connecting';
    this.promise = Twilio.connect(token, {
      name:`grid-world-audio-room-${this.room.id}`,
      audio: true,
      video: false,
    }).then(twilioRoom => {
      if (this.state === "disconnected") {
        // Sometimes, if you step into a room and then step out of it
        // very soon after the server replies to tell you it sees you there,
        // the resulting call to TwilioRoom#disconnect doesn't actually cancel
        // the promise successfully. It's not clear why this happens, we suspect
        // a bug in the twilio JS library. This code handles this case correctly.
        // If the state is disconnected when the promise completes, it means that
        // a call to disconnect happened while the promise was in-flight, but didn't
        // successfully cancel the promise, so we need to disconnect immediately here
        twilioRoom.disconnect();
        return
      }
      this.twilioRoom = twilioRoom;
      this.state = 'connected';
      this.attachRoom(twilioRoom);
      playSound('grid-world-join-sound');
      if (options.startMuted || this.muteOnReconnect) {
        this.mute()
      }

      if (this.onConnect) {
        this.onConnect(this)
      }

      localStorage.setItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_ID, this.entityId())
      localStorage.setItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_LAST_ACTIVE_AT, moment().toISOString())

      this.startLocalStorageInterval()
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
      if (error.name === "NotAllowedError") {
        alert("Unable to connect to audio room. Check your browser’s microphone permissions.")
      }
    });
  }

  startLocalStorageInterval() {
    this.localStorageInterval = setInterval(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_LAST_ACTIVE_AT, moment().toISOString())
    }, 5000)
  }

  disconnect() {
    switch (this.state) {
    case 'connected':
      this.twilioRoom.disconnect();
      playSound('grid-world-leave-sound');
      break;
    case 'connecting':
      this.promise.cancel();
      this.promise = null;
      break;
    }
    this.state = 'disconnected';

    localStorage.removeItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_ID)
    localStorage.removeItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_LAST_ACTIVE_AT)
    clearInterval(this.localStorageInterval)
  }

  unmute() {
    this.muteOnReconnect = false

    if (this.state !== "connected") {
      return
    }

    this.twilioRoom.localParticipant.audioTracks.forEach(publication => {
      publication.track.enable()
    })
  }

  mute() {
    this.muteOnReconnect = true

    if (this.state !== "connected") {
      return
    }

    this.twilioRoom.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable()
    })
  }

  setVolume(volume) {
    this.volume = volume

    for (let el of this.audioElements) {
      el.volume = this.volume
    }
  }

  attachRoom(twilioRoom) {
    twilioRoom.on('participantConnected', (p) => {
      playSound('grid-world-join-sound')
      this.attachParticipant(p)
    })

    twilioRoom.on('participantDisconnected', () => {
      playSound('grid-world-leave-sound')
    })

    twilioRoom.on('participantReconnecting', () => {
      playSound('grid-world-leave-sound')
    })

    twilioRoom.participants.forEach(participant => {
      this.attachParticipant(participant);
    });

    const disconnectRoom = () => twilioRoom.disconnect();
    window.addEventListener('beforeunload', disconnectRoom);

    twilioRoom.once('disconnected', () => {
      this.disconnectCounter += 1
      clearInterval(this.localStorageInterval)

      window.removeEventListener('beforeunload', disconnectRoom);
      if (this.onDisconnect) {
        this.onDisconnect(this)
      }
    })

    twilioRoom.on('reconnecting', () => {
      this.disconnectCounter += 1
      clearInterval(this.localStorageInterval)

      playSound('grid-world-leave-sound')
      if (this.onReconnecting) {
        this.onReconnecting(this)
      }
    })

    twilioRoom.on('reconnected', () => {
      playSound('grid-world-join-sound')
      this.startLocalStorageInterval()

      if (this.muteOnReconnect) {
        this.mute()
      }

      if (this.onConnect) {
        this.onConnect(this)
      }
    })
  }

  attachParticipant = (participant) => {
    participant.audioTracks.forEach(publication => {
      if (publication.isSubscribed) {
        this.attachTrack(publication.track);
      }
    });
    participant.on('trackSubscribed', this.attachTrack);
    participant.on('trackUnsubscribed', this.detachTrack);
  }

  attachTrack = (track) => {
    if (track.kind === "video") {
      this.onVideoAttach?.(track)
      return
    }

    const audio = track.attach()
    audio.volume = this.volume

    this.audioElements.push(audio)

    document.getElementById('twilio-audio-root').appendChild(audio);
  }

  detachTrack = (track) => {
    if (track.kind === "video") {
      this.onVideoDetach?.(track)
      return
    }

    track.detach().forEach(el => {
      const i = this.audioElements.indexOf(el)

      if (i != -1) {
        this.audioElements.splice(i, 1)
      }

      el.remove()
    });
  }
}

const BLOCK_TYPE_TO_ICON = {
  'Note': 'fas fa-sticky-note',
  'Link': 'fas fa-external-link-alt',
  'RC::Calendar': 'far fa-calendar-alt',
  'ZoomLink': 'fas fa-video',
  'Desk': 'fas fa-user-cog',
  'AudioBlock': 'fas fa-volume-up',
  'PhotoBlock': 'fas fa-camera-retro',
}

const BLOCK_TYPE_TO_LABEL = {
  'Note': 'Note',
  'Link': 'Link',
  'RC::Calendar': 'Calendar',
  'ZoomLink': 'Zoom',
  'Desk': 'Desk',
  'AudioBlock': 'Audio',
  'Wall': 'Wall',
  'PhotoBlock': 'Photo',
}


function BlockTypePicker({block, types, onClose, onChange, worldToViewport, getSelectedColor}) {
  const [hoveredBlockType, setHoveredBlockType] = useState(null)
  const ref = useRef(null)

  useLayoutEffect(() => {
    const viewportPos = worldToViewport(block.pos)

    if (ref.current) {
      ref.current.style.left = `${viewportPos.x*WIDTH - ref.current.offsetWidth/2 + WIDTH/2 }px`

      if (ref.current.offsetHeight > viewportPos.y*HEIGHT) {
        ref.current.style.top = `${(viewportPos.y-1)*HEIGHT + ref.current.offsetHeight + 3}px`
      } else {
        ref.current.style.top = `${viewportPos.y*HEIGHT - ref.current.offsetHeight - 3}px`
      }
    }
  })

  function handleKeyDown(e) {
    const keysInUse = _.keys(CanvasWorld.KEYDOWN_HANDLERS)
    const shouldClose = _.without(keysInUse, 't', 'T', 'Shift')

    if (_.contains(shouldClose, e.key)) {
      onClose()
    }
  }

  useEventListener('keydown', handleKeyDown, document)

  return (
    <ClickOutside onClick={ onClose } trigger="mousedown">
      <div
        ref={ ref }
        className="block-type-picker"
      >
        <div className="display-flex">
          {
            types.map(t => {
              const highlighted = hoveredBlockType === t || (!hoveredBlockType && t === block.type)

              const className = classSet({
                'block-type-picker__item': true,
                [`block-type-picker__item--${t.toLowerCase().replace(/::/g, "-")}`]: true,
                [`bg-${getSelectedColor()}`]: t === 'Wall',
                'block-type-picker__item--highlighted': highlighted,
              })

              return (
                <div
                  key={t}
                  className="block-type-picker__item-wrapper"
                  onMouseEnter={ () => setHoveredBlockType(t) }
                  onMouseLeave={ () => setHoveredBlockType(null) }
                  onClick={ () => onChange(t) }
                >
                  <div className={ className }>
                    {
                      BLOCK_TYPE_TO_ICON[t] ?
                      <i className={ BLOCK_TYPE_TO_ICON[t] } /> :
                      null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="font-size-small centered-text m-t-half">{ BLOCK_TYPE_TO_LABEL[hoveredBlockType || block.type] || (hoveredBlockType || block.type) }</div>
      </div>
    </ClickOutside>
  )
}

function DisconnectedNotice({title, children}) {
  return (
    <div className="position-absolute full-width full-height z-100000 display-flex justify-content-center align-items-center" style={{top: 0, left: 0}}>
      <div className="bg-white with-border-radius p-a-2" style={{maxWidth: 375}}>
        <div className="font-weight-bold centered-text m-b-2">{ title }</div>

        <div className="font-size-small">{ children }</div>
      </div>
    </div>
  )
}

function Panel(props) {
  const [messages, setMessages] = useState([])
  const avatarId = useContext(OurAvatarIdContext)

  useActionCable("MessagesChannel", {
    connected: () => {
      // intentionally blank
    },

    received(event) {
      switch (event.type) {
        case 'messages':
          setMessages(event.payload)
          break
        case 'message':
          setMessages(_.sortBy([...messages, event.payload], (m) => moment(m.created_at).unix()))
          break
      }
    },
  })

  const [lastReadAtByScope, setLastReadAtByScope] = useState(_.mapObject(props.initialLastReadMessagesAtByScope, (t, _) => moment(t)))

  const lastReadAtSubscription = useActionCable("LastReadMessagesAtChannel", {
    connected: () => {
      // intentionally blank
    },

    received(newLastReadAtByScope) {
      const moments = _.mapObject(newLastReadAtByScope, (timestamp, scope) => moment(timestamp))
      const newer = _.pick(moments, (newTime, scope) => newTime.isAfter(lastReadAtByScope[scope]))

      setLastReadAtByScope({...lastReadAtByScope, ...newer})
    }
  })

  const [chatScope, setChatScope] = useState('EVERYONE')
  useEffect(() => {
    setChatScope(props.audioRoom ? 'ROOM' : 'EVERYONE')
  }, [props.audioRoom?.id])

  function messageScope(m) {
    if (m.audio_room && props.audioRoom && m.audio_room.id === props.audioRoom.id) {
      return "ROOM"
    } else if (!m.audio_room) {
      return "EVERYONE"
    } else {
      return null;
    }
  }

  const unreadMessagesFromOthers = messages.filter(m => {
    const scope = messageScope(m)
    const isInScope = !!scope

    const notSentByMe = m.sender.id !== avatarId
    const isUnreadForScope = isInScope && moment(m.created_at).isAfter(lastReadAtByScope[scope])

    return notSentByMe && isUnreadForScope
  })

  const hasUnreadMessages = unreadMessagesFromOthers.length > 0

  const unreadMentionCount = unreadMessagesFromOthers.filter(m => _.includes(m.mentioned_entity_ids, avatarId)).length

  const [invertChatIcon, setInvertChatIcon] = useState(false)

  useInterval(() => { setInvertChatIcon(x => !x) }, 3000)

  function markMessagesAsRead(scope) {
    if (hasUnreadMessages) {
      setLastReadAtByScope({
        ...lastReadAtByScope,
        [scope]: moment()
      })
      lastReadAtSubscription.perform("mark_as_read", {scope})
    }
  }

  const panelTabs = [
    {
      title: "Chat",
      path: "/chat",
      iconProps: {
        className: classSet("fa-comment-dots", {
          fas: !hasUnreadMessages || (hasUnreadMessages && !invertChatIcon),
          far: hasUnreadMessages && invertChatIcon,
        }),
      },
    },
    {
      title: "Who’s here",
      path: "/here",
      iconProps: {
        className: classSet("fas", "fa-users"),
      },
    },
    {
      title: "Settings",
      path: "/settings",
      iconProps: {
        className: classSet("fas", "fa-cog"),
        style: { transform: 'translate(0, 1px)' },
      },
    },
  ]
  const location = useLocation()
  const panelPaths = panelTabs.map(p => p.path)
  const classNames = classSet("panel", {"panel--is-open": _.includes(panelPaths, location.pathname)})

  return (
    <div className={ classNames }>
      <div className={classSet("panel__tab", {"panel__tab--with-ngw-banner": props.showingNgwBanner})}>
        {panelTabs.map(({ title, path, iconProps = {} }) => <RouterLink
          key={ path }
          title={ title }
          to={ location.pathname === path ? "/" : path }
          className={ `panel__tab-button panel__tab-button--${path.slice(1)}` }
        >
          <i { ...iconProps } />
        </RouterLink>)}

        <a
          href={ HELP_URL }
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none panel__tab-button panel__tab-button--help"
          title="Help &amp; getting started"
        >
          <i className="fa fa-question-circle" />
        </a>

        {
          unreadMentionCount > 0 ?
          <span className="unread-count">{ unreadMentionCount }</span>
          : null
        }
      </div>

      <Switch>
        <Route path={ panelPaths }>
          <div className={ `panel__body panel__body--${location.pathname.slice(1)}` }>
            <div className="panel__content">
              { props.render({messages, markMessagesAsRead, chatScope, setChatScope}) }
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  )
}

function PanelTitle(props) {
  return (
    <div className="display-flex justify-content-space-between m-y-1 m-x-2 align-items-center">
      <Switch>
        <Route path="/chat">
          <div className="font-size-xlarge color-dark-gray"><i className="fas fa-comment color-light-blue m-r-half" />Chat</div>
        </Route>

        <Route path="/here">
          <div className="font-size-xlarge color-dark-gray"><i className="fas fa-users color-light-green m-r-half" />Who’s here</div>
        </Route>

        <Route path="/settings">
          <div className="font-size-xlarge color-dark-gray"><i className="fas fa-cog color-light-purple m-r-half" />Settings</div>
          {
            props.showLogoutButton ?
            <a href="/logout" className="text-decoration-none color-pink m-l-2" title="Logout"><i className="fa fa-sign-out-alt" /></a> :
            null
          }
        </Route>
      </Switch>
    </div>
  )
}

function ZoomUserEditDialog(props) {
  const { user } = props

  const [displayName, setDisplayName] = useState(user.display_name)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const input = useRef(null)

  useEffect(() => {
    input.current.focus()
    input.current.select()
  }, [])

  function handleDisplayNameChange(e) {
    setDisplayName(e.target.value)
  }

  async function save() {
    try {
      setSubmitting(true)
      await Api.zoomUsers.update(user.id, {display_name: displayName})
      props.onClose()
    } catch (e) {
      setSubmitting(false)
      if (e.response?.status !== 422) {
        setError("Something went wrong")
        return
      }

      const errors = await e.response.json()

      if (errors.display_name) {
        setError("Meeting room name " + errors.display_name[0])
      } else {
        setError("Something went wrong")
      }
    }
  }

  return (
    <Modal onClose={ props.onClose }>
      <div className="display-flex flex-direction-column form">
        <label className="form__label">
          <div className="m-b-1">{ [user.first_name, user.last_name].join(' ') }’s meeting room name</div>

          <input
            type="text"
            className="form__input"
            value={ displayName }
            onChange={ handleDisplayNameChange }
            ref={ input }
          />
        </label>
      </div>

      {
        error ?
        <div className="m-t-half font-size-xsmall color-pink">{ error }</div> :
        null
      }

      <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
        <button className="button bg-green" disabled={ submitting } onClick={ save }>Save</button>
        <button className="button button--link without-focus-ring" onClick={ props.onClose }>Cancel</button>
      </div>
    </Modal>
  )
}

function LinkedZoomUser(props) {
  const { user } = props

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)

  const [submittingDelete, setSubmittingDelete] = useState(false)

  function showEdit() {
    setShowEditDialog(true)
    props.onDialogShow()
  }

  function hideEdit() {
    setShowEditDialog(false)
    props.onDialogHide()
  }

  function showDelete() {
    setShowDeleteDialog(true)
    props.onDialogShow()
  }

  function hideDelete() {
    setShowDeleteDialog(false)
    props.onDialogHide()
  }

  async function deleteUser() {
    setSubmittingDelete(true)
    try {
      await Api.zoomUsers.destroy(user.id)
      props.onDialogHide()
    } catch {
      alert("Something went wrong while deleting a user.")
      setSubmittingDelete(false)
    }
  }

  return (
    <div className="m-y-1">
      {
        showDeleteDialog ?
        <Dialog
          message={`Are you sure you want to remove ${user.first_name}’s Zoom account from RC Together?`}
          confirmText="Remove"
          disabled={ submittingDelete }
          cancelText="Cancel"
          onConfirm={ deleteUser }
          onCancel={ hideDelete }
          confirmClassName="bg-pink"
        /> :
        null
      }

      {
        showEditDialog ?
        <ZoomUserEditDialog user={ user } onClose={ hideEdit }/> :
        null
      }

      <div className="display-flex justify-content-space-between">
        <div>{ [user.first_name, user.last_name].join(' ') }</div>

        <div className="display-flex">
          <button className="button button--link p-a-0 no-border m-r-1" onClick={ showEdit } title="Edit">
            <i className="fas fa-pencil-alt color-green hover-color-dark-green font-size-xxsmall" />
          </button>

          <button className="button button--link p-a-0 no-border display-flex align-items-flex-end" onClick={ showDelete } title="Remove">
            <i className="fas fa-times" />
          </button>
        </div>
      </div>

      <div className="font-size-small color-dark-gray truncate">{ user.display_name }</div>
      <div className="font-size-small color-gray font-style-italic truncate">{ user.email }</div>
      <div className="font-size-small color-gray font-style-italic truncate">{ capitalize(user.plan_type).replace('_', '-') } account</div>
    </div>
  )
}

function ZoomSettings(props) {
  const [email, setEmail] = useState("")
  const [users, setUsers] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  useActionCable("ZoomSettingsChannel", {
    received(event) {
      switch (event.type)  {
        case "users":
          setUsers(event.payload)
          break
        case "user_deleted":
          setUsers(_.reject(users, u => u.id === event.payload.id))
          break
        case "user":
          setUsers(replaceBy(users, event.payload, 'id'))
          break
      }
    }
  })

  function handleEmailChange(e) {
    setEmail(e.target.value)
  }

  function submitOnEnter(e) {
    if (e.key === 'Enter') {
      createUser()
    }
  }

  async function createUser() {
    try {
      setSubmitting(true)
      await Api.zoomUsers.create({email: email.trim()})
      setEmail("")
      setError(null)
    } catch (e) {
      console.log("Error while creating Zoom User")
      console.log(e.response)

      if (e.response?.status !== 422) {
        setError("Something went wrong")
        return
      }

      const errors = await e.response.json()
      console.log(errors)

      if (errors.email) {
        setError(errors.email.map(e => "Email " + e + ".").join(" "))
      } else {
        const message = _.flatten(_.map(errors, (messages, attr) => messages.map(e => capitalize(attr.replace('_', ' ')) + " " + e + "."))).join(" ")

        setError(message)
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="form p-a-1 m-x-2 m-b-2 with-border-radius bg-white">
      <h2 className="font-weight-normal font-size-large m-y-0 color-dark-gray">Zoom</h2>

      { props.authedWithZoom ?
        <>
          <div className="color-gray m-t-2 m-b-1"><i className="fas fa-user-plus color-blue m-r-half" /> Link a Zoom account</div>
          <div className="display-flex m-t-1">
            <input
              type="text"
              value={ email }
              placeholder="Enter work email address"
              onChange={ handleEmailChange }
              onFocus={ props.inputFocused }
              onBlur={ props.inputBlurred }
              onKeyDown={ submitOnEnter }
              className="form__input flex-1 m-r-1"
            />
            <button className="button" onClick={ createUser } disabled={ submitting }>Add</button>
          </div>

          {
            error ?
            <div className="m-t-half font-size-xsmall color-pink">{ error }</div> :
            null
          }

          <div className="m-t-2 color-gray"><i className="fas fa-link color-blue m-r-half" /> Linked Zoom accounts</div>
          <div>
            {
              _.sortBy(users, (u) => -moment(u.created_at).unix()).map(u =>
                <LinkedZoomUser
                  key={ u.id }
                  user={ u }
                  onDialogShow={ props.inputFocused }
                  onDialogHide={ props.inputBlurred }
                />
              )
            }
          </div>
        </> :
        <div>
          <p className="color-gray font-size-small">Authenticate with Zoom below to enable Zoom integration.</p>
          <p className="color-gray font-size-small">You must have admin priviledges for <strong>your company's</strong> Zoom account to do this.</p>

          <div className="display-flex justify-content-space-around">
            <a href="/zoom/auth" className="button bg-green">Auth with Zoom</a>
          </div>
        </div>
      }
    </div>
  )
}

function GuestUser(props) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [submittingDelete, setSubmittingDelete] = useState(false)

  const [showResendDialog, setShowResendDialog] = useState(false)
  const [submittingResend, setSubmittingResend] = useState(false)

  function showDelete() {
    setShowDeleteDialog(true)
    props.onDialogShow()
  }

  function hideDelete() {
    setShowDeleteDialog(false)
    props.onDialogHide()
  }

  function showResend() {
    setShowResendDialog(true)
    props.onDialogShow()
  }

  function hideResend() {
    setShowResendDialog(false)
    props.onDialogHide()
  }

  async function deleteUser() {
    setSubmittingDelete(true)
    try {
      await Api.guestUsers.destroy(props.user.id)
      props.onDialogHide()
    } catch {
      alert("Something went wrong while deleting a user.")
      setSubmittingDelete(false)
    }
  }

  async function resendInvite() {
    setSubmittingResend(true)
    try {
      await Api.guestUsers.resend_invite(props.user.id)
      hideResend()
    } catch {
      alert("Something went wrong while sending the invitation.")
    } finally {
      setSubmittingResend(false)
    }
  }

  return (
    <div className="m-y-1">
      {
        showDeleteDialog ?
        <Dialog
          message={`Are you sure you want to remove ${props.user.name}’s guest account from RC Together?`}
          confirmText="Remove"
          disabled={ submittingDelete }
          cancelText="Cancel"
          onConfirm={ deleteUser }
          onCancel={ hideDelete }
          confirmClassName="bg-pink"
        /> :
        null
      }

      {
        showResendDialog ?
        <Dialog
          message={`Are you sure you want to resend an invitation to ${props.user.email}?`}
          confirmText="Resend"
          disabled={ submittingResend }
          cancelText="Cancel"
          onConfirm={ resendInvite }
          onCancel={ hideResend }
          confirmClassName="bg-green"
        /> :
        null
      }

      <div className="display-flex justify-content-space-between">
        <div>{ props.user.name }</div>

        <div className="display-flex">
          <button className="button button--link p-a-0 no-border m-r-1" title="Resend invite" onClick={ showResend }>
            <i className="far fa-paper-plane font-size-small" />
          </button>

          <button className="button button--link p-a-0 no-border" title="Remove" onClick={ showDelete }>
            <i className="fas fa-times" />
          </button>
        </div>
      </div>

      <div className="font-size-small color-dark-gray truncate">{ props.user.email }</div>
      <div className="font-size-small color-gray font-style-italic truncate">Invited by { props.user.invited_by.name }</div>
    </div>
  )
}

function GuestSettings(props) {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [users, setUsers] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})

  useActionCable("GuestUsersChannel", {
    received(event) {
      switch (event.type)  {
        case "users":
          setUsers(event.payload)
          break
        case "user_deleted":
          setUsers(_.reject(users, u => u.id === event.payload.id))
          break
        case "user":
          setUsers(replaceBy(users, event.payload, 'id'))
          break
      }
    }
  })

  function handleEmailChange(e) {
    setEmail(e.target.value)
  }

  function handleNameChange(e) {
    setName(e.target.value)
  }

  async function submit() {
    try {
      setSubmitting(true)
      await Api.guestUsers.create({email: email.trim(), name})
      setEmail("")
      setName("")
      setErrors({})
    } catch (e) {
      if (!e.response?.status === 422) {
        setErrors({"base": ["Something went wrong"]})
        return
      }

      const errors = await e.response.json()

      if (_.isEmpty(_.intersection(_.keys(errors), ["email", "name", "base"]))) {
        setErrors({"base": ["Something went wrong"]})
      } else {
        setErrors(errors)
      }
    } finally {
      setSubmitting(false)
    }
  }

  function formatErrors(prefix, errors) {
    let p
    if (prefix) {
      p = prefix + " "
    } else {
      p = ""
    }
    return errors.map((e) => p + e).join(", ")
  }

  return (
    <div className="form p-a-1 m-x-2 m-b-2 with-border-radius bg-white">
      <h2 className="font-weight-normal font-size-large m-y-0 color-dark-gray">Guests</h2>

      <div className="color-gray m-t-2 m-b-1"><i className="fas fa-user-plus color-purple m-r-half" /> Invite a guest to your space</div>

      <div className="form">
        <label className="form__label">
          Full name
          <input
            type="text"
            value={ name }
            autoComplete="off"
            onChange={ handleNameChange }
            onFocus={ props.inputFocused }
            onBlur={ props.inputBlurred }
            className="form__input"
          />
        </label>

        {
          errors["name"] ?
          <div className="m-b-1 font-size-xsmall color-pink">{ formatErrors("Name", errors["name"]) }</div> :
          null
        }

        <label className="form__label m-t-1">
          Email
          <input
            type="email"
            value={ email }
            autoComplete="off"
            onChange={ handleEmailChange }
            onFocus={ props.inputFocused }
            onBlur={ props.inputBlurred }
            className="form__input"
          />
        </label>

        {
          errors["email"] ?
          <div className="m-t-half font-size-xsmall color-pink">{ formatErrors("Email", errors["email"]) }</div> :
          null
        }

        {
          errors["base"] ?
          <div className="m-t-half font-size-xsmall color-pink">{ formatErrors(null, errors["base"]) }</div> :
          null
        }

        <div className="display-flex justify-content-flex-end m-t-1">
          <button className="button" onClick={ submit } disabled={ submitting }>Invite</button>
        </div>
      </div>

      {
        users.length > 0 ?
        <div className="color-gray m-t-2 m-b-1"><i className="fas fa-user color-purple m-r-half" /> Existing guests</div> :
        null
      }

      {
        users.map(u => <GuestUser key={ u.id } user={ u } onDialogShow={ props.inputFocused } onDialogHide={ props.inputBlurred } />)
      }

    </div>
  )
}

function CoffeeChatSettings(props) {
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

  const [preferences, setPreferences] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const successCount = useRef(0)
  const errorCount = useRef(0)

  async function loadPreference() {
    try {
      const resp = await Api.coffeeChatPreference.show()
      const p = await resp.json()
      setPreferences(p)
    } catch {
      alert("Something went wrong while loading coffee chat preference")
    }
  }

  useEffect(() => {
    loadPreference()
  }, [])

  function handlePreferenceChange(e) {
    const { name } = e.target
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

    updatePreference(name, value)
  }

  function handleRestrictMatchesChange(id, restrict_coffee_chat_matches) {
    updatePreference("group_memberships_attributes", [{id, restrict_coffee_chat_matches}])
  }

  async function updatePreference(name, value) {
    try {
      const resp = await Api.coffeeChatPreference.update({[name]: value})
      const updatedPrefs = await resp.json()

      const key = name.endsWith("_attributes") ? name.replace(/_attributes$/, "") : name

      setPreferences({
        ...preferences,
        [key]: updatedPrefs[key],
        next_match_day: updatedPrefs.next_match_day,
        skipped_match_day: updatedPrefs.skipped_match_day,
      })

      setShowSuccess(true)
      successCount.current += 1

      setTimeout(() => {
        successCount.current -= 1

        if (successCount.current === 0) {
          setShowSuccess(false)
        }
      }, 2000)
    } catch {
      setShowError(true)
      errorCount.current += 1

      setTimeout(() => {
        errorCount.current -= 1

        if (errorCount.current === 0) {
          setShowError(false)
        }
      }, 2000)
    }
  }

  return (
    <div className="form p-a-1 m-x-2 m-b-2 with-border-radius bg-white">
      <div className="m-b-2 display-flex align-items-baseline justify-content-space-between">
        <h2 className="font-weight-normal font-size-large m-y-0 color-dark-gray">Coffee chats</h2>

        <label>
          <input
            className="display-none"
            type="checkbox"
            name="enabled"
            disabled={ !preferences }
            checked={ (preferences && preferences.enabled) || false }
            onChange={ handlePreferenceChange }
          />

          <div className="display-none">Enable coffee chats</div>

          {
            preferences && preferences.enabled ?
            <i className="fas fa-toggle-on font-size-xxlarge position-relative color-light-green" style={{top: 3}}/> :
            <i className="fas fa-toggle-off font-size-xxlarge position-relative color-gray" style={{top: 3}}/>
          }
        </label>
      </div>

      <div className="m-b-2 font-size-xsmall font-style-italic">Get randomly matched for short, one-on-one conversations (<a href={HELP_URL} target="_blank" rel="noopener noreferrer">learn more</a>).</div>

      <div className="">
        <div className="m-b-1 color-gray"><i className="fa fa-calendar-check p-r-half color-light-green" /> Match me for chats on</div>

        <div className="display-flex flex-direction-column" style={{ lineHeight: "1.5rem"}}>
          {
            days.map(day =>
              <label key={ day } className={classSet("display-flex", "align-items-center", "m-r-2", "font-size-small", {"color-gray": !preferences || !preferences.enabled})}>
                <input
                  className="form__input m-r-1"
                  type="checkbox"
                  name={ day }
                  onChange={ handlePreferenceChange }
                  checked={ (preferences && preferences[day]) || false }
                  disabled={ !preferences || !preferences.enabled }
                /> { capitalize(day) }
              </label>
            )
          }
        </div>
      </div>

      {
        _.any(preferences?.group_memberships || []) ?
        <>
          <div className="m-t-2">
            <div className="m-b-1 color-gray"><i className="fa fa-user-friends p-r-half color-light-green" /> Only match me with</div>
            {
              preferences.group_memberships.map((membership) =>
                <label key={ membership.id } className={classSet("display-flex", "align-items-center", "font-size-small", {"color-gray": !preferences || !preferences.enabled})}>
                  <input
                    className="form__input m-r-1"
                    type="checkbox"
                    name="group_memberships_attributes"
                    checked={ (preferences && membership.restrict_coffee_chat_matches) || false }
                    onChange={ (e) => handleRestrictMatchesChange(membership.id, e.target.checked) }
                    disabled={ !preferences || !preferences.enabled }
                  /> { membership.name }
                </label>
              )
            }
            </div>
        </> :
        null
      }

      <div className="m-t-2">
        <div className="m-b-1 color-gray display-flex align-items-baseline">
          <i className="fa fa-recycle p-r-half color-light-green" />
          Skip anyone I‘ve matched with in&nbsp;the&nbsp;past
        </div>

        <label className={classSet("display-flex","align-items-center", "font-size-small", {"color-gray": !preferences|| !preferences.enabled})}>
          <select
            className="form__input color-darkest-gray"
            disabled={ !preferences || !preferences.enabled }
            name="no_duplicate_matches_within"
            value={ (preferences && preferences.no_duplicate_matches_within) || "" }
            onChange={ handlePreferenceChange }
          >
            <option value="P7D">1 week</option>
            <option value="P14D">2 weeks</option>
            <option value="P21D">3 weeks</option>
            <option value="P28D">4 weeks</option>
            <option value="P35D">5 weeks</option>
            <option value="P42D">6 weeks</option>
            <option value="P49D">7 weeks</option>
            <option value="P56D">8 weeks</option>
            <option value="P63D">9 weeks</option>
            <option value="P70D">10 weeks</option>
            <option value="P77D">11 weeks</option>
            <option value="P84D">12 weeks</option>
            <option value="P100Y">Ever</option>
         </select>
        </label>
      </div>

      {
        preferences && preferences.enabled ?
        <div className={classSet("m-t-2", "m-b-1", "p-a-1", "m-t-2", "font-size-small", "font-style-italic", !preferences.next_match_day ? "bg-tint2-pink" : "bg-tint2-green")}>
          {
            !preferences.next_match_day ?
            <span className="color-dark-pink">Please select at least one day to be matched for a coffee chat.</span> :
            preferences.skipping ?
            <>You're skipping { moment(preferences.skipped_match_day).format('dddd')}'s match. Your next match will be on { moment(preferences.next_match_day).format('dddd, MMMM Do') } (<button className="button button--link without-focus-ring p-a-0 color-green text-decoration-underline" onClick={ () => updatePreference("skipping", false) }>don't skip</button>)</> :
            <>Your next match will be on { moment(preferences.next_match_day).format('dddd, MMMM Do') } (<button className="button button--link without-focus-ring p-a-0 no-border font-size-small color-green text-decoration-underline font-style-italic" onClick={ () => updatePreference("skipping", true) }>skip next match</button>)</>
          }
        </div> :
        null
      }

      <CSSTransition
        in={ showSuccess }
        classNames="flash"
        timeout={200}
        unmountOnExit
      >
        <div className="flash flash--success">
          <div className="flash__content m-a-1">Saved!</div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={ showError }
        classNames="flash"
        timeout={200}
        unmountOnExit
      >
        <div className="flash flash--error">
          <div className="flash__content m-a-1">Something went wrong</div>
        </div>
      </CSSTransition>
    </div>
  )
}

function SlackSettings(props) {
  const [credential, setCredential] = useState(null)
  const [loadingCredential, setLoadingCredential] = useState(true)

  const [desk, setDesk] = useState(null)
  const [loadingDesk, setLoadingDesk] = useState(true)

  const popup = useRef(null)

  useActionCable("SlackCredentialChannel", {
    received(cred) {
      setCredential(cred)
      setLoadingCredential(false)
    }
  })

  useActionCable("MyDeskChannel", {
    received(desk) {
      setDesk(desk)
      setLoadingDesk(false)
    }
  })

  function spotlightDesk(e) {
    e.preventDefault()

    props.spotlightPos(desk.pos)
  }

  function openSlackPopup(e) {
    e.preventDefault()

    popup.current = window.open(
      '/slack/auth',
      null,
      'width=800,height=800'
    )
  }

  function setSlackStatusSync (shouldSync) {
    try {
      Api.slackCredential.update({sync_status: shouldSync})
    } catch {
      alert("Something went wrong while setting Slack sync preferences")
    }
  }

  useEffect(() => (
    () => popup.current?.close()
  ), [])

  const loading = loadingDesk || loadingCredential

  return (
    <div className="form p-a-1 m-x-2 m-b-2 with-border-radius bg-white">
      <div className="display-flex justify-content-space-between align-items-baseline">
        <h2 className="font-weight-normal font-size-large m-y-0 color-dark-gray">Sync status with Slack</h2>

        {
          credential ?
          <label>
            <input
              className="display-none"
              type="checkbox"
              checked={credential.sync_status}
              onChange={e => setSlackStatusSync(e.target.checked)}
            />

            <div className="display-none">Sync status with Slack</div>

            {
              credential.sync_status ?
              <i className="fas fa-toggle-on font-size-xxlarge position-relative color-light-green" style={{top: 3}}/> :
              <i className="fas fa-toggle-off font-size-xxlarge position-relative color-gray" style={{top: 3}}/>
            }
          </label> :
          null
        }
      </div>

      {
        loading ?
        null :
        credential ?
        <div>
          {
            desk && credential.sync_status ?
            <p className="color-gray font-size-small">Updating the status on <a href="" onClick={ spotlightDesk } className="color-blue">your desk</a> will update your Slack status, and vise versa.</p> :
            credential.sync_status ?
            <p className="color-gray font-size-small">Claim a desk to view and update your Slack&nbsp;status.</p> :
            <p className="color-gray font-size-small">Slack status sync is <strong>disabled</strong>.</p>
          }
        </div> :
        <div>
          <p className="color-gray font-size-small">Authenticate below to enable two-way sync between your desk and Slack status.</p>

          <div className="display-flex justify-content-space-around">
            <a onClick={openSlackPopup} href="/slack/auth"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
          </div>
        </div>
      }
    </div>
  )
}

const OurAvatarIdContext = React.createContext(null)
const CurrentRealmContext = React.createContext(null)
const EnvironmentContext = React.createContext(null)

class GridWorldUI extends PureComponent {
  state = {
    mousePos: null,
    posFacing: null,
    selectedPos: null,
    overlayPlacement: null,
    blockBeingEdited: null,
    connected: false,
    hasConnectedOnce: false,
    twilioConnected: false,
    showingReloadNotice: false,
    clientClockSkew: 0,
    inputFocused: false,
    agentsWithMessages: [],
    agents: [],
    blockForTypePicker: null,
    shouldSuppressSpaceAndEnter: false,
    viewport: this.props.initialViewport,
    volume: 1,
    volumeSetAt: null,
    micMuted: false,
    showingNgwBanner: this.props.realm.authentication_method === "AuthenticationMethod::RC" && new Date("2022-05-09").getTime() < new Date().getTime() && new Date().getTime() < new Date("2022-05-14").getTime(),
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  editBlock(block) {
    if (EDITORS[block.type]) {
      this.setState({blockBeingEdited: block});
    }
  }

  stopEditing = () => {
    this.setState({blockBeingEdited: null});
    this.props.stopEditing();
  }

  stopEditingAvatar = () => {
    this.setState({avatarBeingEdited: null})
  }

  isEditingBlock() {
    return !!this.state.blockBeingEdited;
  }

  shouldSuppressInput() {
    return this.isEditingBlock() || this.state.inputFocused || this.state.dialog || this.state.avatarBeingEdited;
  }

  shouldSuppressSpaceAndEnter() {
    return this.state.shouldSuppressSpaceAndEnter
  }

  setShouldSuppressSpaceAndEnter = (state) => {
    this.setState({shouldSuppressSpaceAndEnter: state})
  }

  inputFocused = () => {
    this.setState({inputFocused: true})
  }

  inputBlurred = () => {
    this.setState({inputFocused: false})
  }

  showReloadNotice = () => {
    this.setState({showingReloadNotice: true})
  }

  showBlockTypePicker(block) {
    this.setState({blockForTypePicker: block})
  }

  hideBlockTypePicker = () => {
    this.setState({blockForTypePicker: null})
  }

  selectBlockType = (type) => {
    this.props.selectBlockType(type, {showPicker: false})
    this.setState({blockForTypePicker: null})
  }

  clearSelectedPos = () => {
    this.setState({selectedPos: null})
  }

  mouseMoved(mousePos) {
    this.setState({mousePos});
  }

  posFacingChanged(posFacing) {
    this.setState({posFacing});
  }

  showDialog(dialog) {
    this.setState({dialog})
  }

  hideDialog = () => {
    this.setState({dialog: null})
  }

  closeNgwBanner = () => {
    this.setState({showingNgwBanner: false})
  }

  displayMessageForAgent(agent) {
    const agents = _.reject(this.state.agentsWithMessages, a => a.id === agent.id)
    this.setState({agentsWithMessages: [...agents, agent]})

    setTimeout(() => {
      const agents = _.reject(this.state.agentsWithMessages, a => a.id === agent.id && _.isEqual(a.message, agent.message))
      this.setState({agentsWithMessages: agents})
    }, MESSAGE_TIMEOUT.asMilliseconds())
  }

  handleDialogConfirm({onConfirm}) {
    onConfirm()
    this.hideDialog()
  }

  render() {
    const { connected, mousePos, posFacing, blockBeingEdited, avatarBeingEdited, showingReloadNotice, agentsWithMessages, blockForTypePicker, dialog, selectedPos, overlayPlacement, shouldSuppressSpaceAndEnter, twilioConnected, hasConnectedOnce, clientClockSkew, volume, volumeSetAt, twilioRoom, micMuted, chatScope, showingNgwBanner } = this.state
    const { entitiesAt, getEntityById, worldToViewport, autoLogin, getBlockTypes, getSelectedColor, getOurDesk, spotlightEntity, clearSpotlight, walkToAndEdit, realm, getMentionableEntities, initialLastReadMessagesAtByScope, currentUser, spotlightPos, environment, ourAvatar, currentAudioRoom, getUserHasInteracted, getZoomLinks, onMicMutedChange, setCanvasNeedsRedraw } = this.props

    let Editor;
    if (blockBeingEdited) {
      Editor = EDITORS[blockBeingEdited.type];
    }

    const audioRoom = currentAudioRoom(ourAvatar(), {ignoringServer: true})

    return (
      <Router>
        <EnvironmentContext.Provider value={ environment }>
          <CurrentRealmContext.Provider value={ realm }>
            <ViewportContext.Provider value={ this.state.viewport }>
              <div className="full-height">
                {
                  !connected ?
                  <div className="position-absolute full-width full-height z-100000 bg-darkest-gray transparent display-flex justify-content-center align-items-center" style={{top: 0, left: 0}}>
                  </div> :
                  null
                }

                {
                  hasConnectedOnce && !connected && twilioConnected ?
                  <DisconnectedNotice title="You’re still in an audio chat">
                    But we’ve lost connection to RC Together. Your audio chat will continue while we try to reconnect.
                  </DisconnectedNotice> :

                  hasConnectedOnce && !connected ?
                  <DisconnectedNotice title="Please wait while we try to reconnect">
                    If you’re able to load other websites and this problem persists, let us know: <a href="mailto:ops@recurse.com">ops@recurse.com</a>.
                  </DisconnectedNotice> :
                  null
                }

                <VolumeLevel volume={volume} setAt={volumeSetAt} />

                {
                  audioRoom && twilioRoom && twilioConnected ?
                  <div className="position-fixed bg-white display-flex box-shadow p-a-1 m-r-2" style={{bottom: 48, left: 48, zIndex: 2000}}>
                    <button
                      className="button button--link font-size-large p-a-0 m-r-1 display-flex justify-content-center align-items-center"
                      style={{ width: 24 }}
                      title={ micMuted ? "Unmute" : "Mute" }
                      onClick={ onMicMutedChange }
                    >
                      <i className={classSet("fas", {"fa-microphone": !micMuted, "fa-microphone-slash": micMuted})} />
                    </button>


                    <SharedTimer audioRoom={audioRoom} setCanvasNeedsRedraw={ setCanvasNeedsRedraw }/>
                    <ScreenSharePlayer
                      audioRoom={audioRoom}
                      twilioRoom={twilioRoom}
                    />
                    <YoutubePlayer
                      audioRoom={audioRoom}
                      onFocus={ this.inputFocused }
                      onBlur={ this.inputBlurred }
                      getUserHasInteracted={ getUserHasInteracted }
                    />
                  </div> :
                  null
                }

                <Panel audioRoom={ audioRoom } initialLastReadMessagesAtByScope={ initialLastReadMessagesAtByScope } showingNgwBanner={ showingNgwBanner } render={data => (
                  <>
                    <PanelTitle showLogoutButton={ !autoLogin || currentUser.is_guest } />
                    <Switch>
                      <Route path="/chat">
                        <Chat
                          messages={ data.messages }
                          onMessagesRead={ data.markMessagesAsRead }
                          getMentionableEntities={ getMentionableEntities }
                          inputFocused={ this.inputFocused }
                          inputBlurred={ this.inputBlurred }
                          realm={ realm }
                          spotlightEntity={ spotlightEntity }
                          clearSpotlight={ clearSpotlight }
                          getEntityById={ getEntityById }
                          spotlightPos={ spotlightPos }
                          audioRoom={ audioRoom }
                          chatScope={ data.chatScope }
                          setChatScope={ data.setChatScope }
                        />
                      </Route>

                      <Route path="/here">
                        <OnlineNow
                          ourAvatar={ ourAvatar }
                          getPeopleOnline={ this.props.getOnlineAvatarsWithDeskKey }
                          getZoomLinks={ getZoomLinks }
                          spotlightEntity={ spotlightEntity }
                          walkToAndEdit={ walkToAndEdit }
                        />
                      </Route>

                      <Route path="/settings">
                        <div className="overflow-auto">
                          {
                            currentUser.show_discord_bot_button ?
                            <div className="p-a-1 m-x-2 m-b-2 with-border-radius bg-white">
                              <h2 className="font-weight-normal font-size-large m-y-0 color-dark-gray">
                                Add Discord bot
                              </h2>

                              <p className="color-gray font-size-small">
                                Adding the RC Together bot to your Discord server will sync server nicknames to RC Together.
                              </p>

                              <div className="display-flex justify-content-center">
                                <a href="/discord/auth" className="button bg-green">Add Discord bot</a>
                              </div>
                            </div> :
                            null
                          }

                          {
                            realm.coffee_chats_enabled && !currentUser.is_guest ?
                            <CoffeeChatSettings /> :
                            null
                          }
                          {
                            realm.authentication_method == 'AuthenticationMethod::Slack' ?
                            <SlackSettings spotlightPos={ spotlightPos } /> :
                            null
                          }
                          {
                            currentUser.is_admin && realm.zoom_authentication_type == "account" ?
                            <ZoomSettings authedWithZoom={ realm.has_zoom_credential } inputFocused={ this.inputFocused } inputBlurred={ this.inputBlurred } /> :
                            null
                          }
                          {
                            currentUser.is_admin ?
                            <GuestSettings inputFocused={ this.inputFocused } inputBlurred={ this.inputBlurred } /> :
                            null
                          }
                        </div>
                      </Route>
                    </Switch>
                  </>
                )}/>

                { dialog ? <Dialog {...dialog} onConfirm={ () => this.handleDialogConfirm(dialog) } onCancel={this.hideDialog} /> : null }

                {
                  showingReloadNotice ?
                  <div className="reload-notice">
                    We’ve deployed new code. Please <a className="color-blue" href="#" onClick={ () => { window.location.reload(true) } }>reload the page</a>.
                  </div> :
                  null
                }

                {
                  showingNgwBanner ?
                  <NGWBanner onClose={ this.closeNgwBanner } /> :
                  null
                }

                {
                  // clientClockSkew is in seconds
                  Math.abs(clientClockSkew/60) > 10 ?
                  <div className="reload-notice">
                    Your computer’s clock is { Math.abs(Math.round(clientClockSkew/60)) } minutes { clientClockSkew > 0 ? "fast" : "behind" }. Please fix your clock and reload this page.
                  </div> :
                  null
                }

                {
                  blockForTypePicker ?
                  <BlockTypePicker
                    block={ blockForTypePicker }
                    types={ getBlockTypes() }
                    onClose={ this.hideBlockTypePicker }
                    onChange={ this.selectBlockType }
                    worldToViewport={ worldToViewport }
                    getSelectedColor={ getSelectedColor }
                  /> :
                  null
                }

                {
                  mousePos && !_.isEqual(mousePos, blockForTypePicker?.pos) ?
                  <EntityHoverOverlay
                    pos={ mousePos }
                    entitiesAt={ entitiesAt }
                    worldToViewport={ worldToViewport }
                    ourAvatar={ ourAvatar }
                  /> :
                  null
                }

                {
                  selectedPos ?
                  <EntityOverlay
                    pos={ selectedPos }
                    posFacing={ posFacing }
                    overlayPlacement={ overlayPlacement }
                    entitiesAt={ entitiesAt }
                    getEntityById={ getEntityById }
                    worldToViewport={ worldToViewport }
                    clearSelectedPos={ this.clearSelectedPos }
                    setShouldSuppressSpaceAndEnter={ this.setShouldSuppressSpaceAndEnter}
                    shouldSuppressSpaceAndEnter={ shouldSuppressSpaceAndEnter }
                    spotlightEntity={ spotlightEntity }
                    walkToAndEdit={ walkToAndEdit }
                  /> :
                  null
                }

                {
                  avatarBeingEdited ?
                  <AvatarEditor
                    avatar={ avatarBeingEdited }
                    onClose={ this.stopEditingAvatar }
                  /> :
                  null
                }

                {
                  Editor ?
                  <Editor
                    block={ blockBeingEdited }
                    onClose={ this.stopEditing }
                    worldToViewport={ worldToViewport }
                    getOurDesk={ getOurDesk }
                  /> :
                  null
                }

                {
                  agentsWithMessages.map(a =>
                    <Message
                      key={ a.id }
                      avatar={ a }
                      ourAvatar={ ourAvatar }
                      worldToViewport={ worldToViewport }
                      spotlightEntity={ spotlightEntity }
                      getEntityById={ getEntityById }
                      spotlightPos={ spotlightPos }
                    />
                  )
                }
              </div>
            </ViewportContext.Provider>
          </CurrentRealmContext.Provider>
        </EnvironmentContext.Provider>
      </Router>
    )
  }
}

function VolumeLevel({volume, setAt}) {
  const levels = 16
  const [isVisible, setIsVisible] = useState(false)
  const volumeTimeout = useRef(null)

  useEffect(() => {
    if (setAt == null) return
    setIsVisible(true)
    clearTimeout(volumeTimeout.current)
    volumeTimeout.current = setTimeout(() => setIsVisible(false), 2500)
  }, [volume, setAt])

  if (!isVisible) return null

  return (
    <div className="volume-level bg-white with-border border-dark-gray with-border-radius">
      <div className="centered-text m-a-1 color-dark-gray">
        <i className={`fa fa-volume-${ volume === 0 ? 'mute' : 'up'} font-size-xlarge`} />
      </div>
      <div className="volume-level__container display-flex with-border-radius">
        {
          _.times(levels, n => <div key={ n } className={`volume-level__notch border-dark-gray ${n < volume*levels ? 'bg-white' : 'bg-dark-gray'}`} />)
        }
      </div>
    </div>
  )
}

function AvatarEditor(props) {
  const { avatar } = props

  if (avatar.type === "Avatar") {
    return KnownAvatarEditor(props)
  } else {
    return UnknownAvatarEditor(props)
  }
}

function KnownAvatarEditor({avatar, onClose}) {
  const [alternateIds, setAlternateIds] = useState([])
  const [alternateNames, setAlternateNames] = useState([])
  const [error, setError] = useState(null)

  async function fetchAlternateIds() {
    let resp
    try {
      resp = await Api.alternateZoomIds.index(avatar.user_id)
    } catch (e) {
      setError("Something went wrong while loading")
      return
    }

    const ids = await resp.json()

    setAlternateIds(ids)
  }

  async function fetchAlternateNames() {
    let resp
    try {
      resp = await Api.alternateZoomNames.index(avatar.user_id)
    } catch (e) {
      setError("Something went wrong while loading")
      return
    }

    const names = await resp.json()

    setAlternateNames(names)
  }

  useEffect(() => {
    fetchAlternateIds()
    fetchAlternateNames()
  }, [])

  async function destroyAlternateName(name) {
    try {
      await Api.alternateZoomNames.destroy(name.id)
    } catch (e) {
      setError("Something went wrong while removing an alternate name")
      return
    }

    setAlternateNames(_.reject(alternateNames, n => n.id === name.id))
    setError(null)
  }

  async function destroyAlternateId(id) {
    try {
      await Api.alternateZoomIds.destroy(id.id)
    } catch (e) {
      setError("Something went wrong while removing an alternate id")
      return
    }

    setAlternateIds(_.reject(alternateIds, i => i.id === id.id))
    setError(null)
  }

  return (
    <Modal onClose={ onClose }>
      <div className="form">
        <div className="form__label m-b-1">{ avatar.person_name }</div>

        <em>Alternate Zoom names</em>
        <ul>
          {
            alternateNames.map(name =>
              <li key={name.id}>{ name.zoom_name } – <button className="button button--link color-green p-a-0" onClick={ () => destroyAlternateName(name) }>Remove</button></li>
            )
          }
        </ul>

        <em>Alternate Zoom ids</em>
        <ul>
          {
            alternateIds.map(id =>
              <li key={id.id} >{ id.zoom_id } – <button className="button button--link color-green p-a-0" onClick={ () => destroyAlternateId(id) }>Remove</button></li>
            )
          }
        </ul>

        {
          error ?
          <div className="font-size-xsmall color-pink m-t-2">{ error }</div> :
          null
        }

        <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
          <button className="button" onClick={ onClose }>Close</button>
        </div>
      </div>
    </Modal>
  )
}

function UnknownAvatarEditor({avatar, onClose}) {
  const [users, setUsers] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [error, setError] = useState(null)

  const identifierLabel = `"${avatar.email_from_zoom || avatar.zoom_id_of_user || avatar.person_name}"`;

  useEffect(() => {
    async function fetchUsers() {
      const resp = await Api.users.index()
      const users = await resp.json()
      setUsers(users)
    }
    fetchUsers()
  }, [])

  function handleSelection(e) {
    setSelectedUserId(parseInt(e.target.value, 10))
  }

  async function handleSave() {
    if (avatar.zoom_id_of_user) {
      try {
        await Api.alternateZoomIds.create(selectedUserId, {zoom_id: avatar.zoom_id_of_user, unknown_avatar_id: avatar.id})

        onClose()
      } catch (e) {
        setError("Something went wrong while adding an alternative Zoom id.")
      }
    } else {
      try {
        await Api.alternateZoomNames.create(selectedUserId, {zoom_name: avatar.person_name, unknown_avatar_id: avatar.id})

        onClose()
      } catch (e) {
        setError("Something went wrong while adding an alternative Zoom name.")
      }
    }
  }

  return (
    <Modal onClose={ onClose }>
      <div className="form m-t-2">
        <label className="form__label">
          <div className="m-b-1">Choose a user to associate with {identifierLabel}</div>

          <select value={ selectedUserId || '' } onChange={ handleSelection } className="form__input">
            <option value="" disabled></option>
            { users.map(u => <option key={u.id} value={u.id}>{u.name}</option>) }
          </select>
        </label>

        {
          error ?
          <div className="font-size-xsmall color-pink m-t-2">{ error }</div> :
          null
        }

        <div className="display-flex flex-direction-row-reverse justify-content-flex-start m-t-2">
          <button className="button bg-green" onClick={ handleSave } disabled={ selectedUserId === null }>Save</button>
          <button className="button button--link without-focus-ring" onClick={ onClose }>Cancel</button>
        </div>
      </div>
    </Modal>
  )
}

function AvatarAutocompleteOption(props) {
  const classNames = classSet("presence-indicator", {
    "presence-indicator--active": props.option.is_active,
    "presence-indicator--idle": !props.option.is_active && props.option.is_idle,
    "presence-indicator--selected": props.selected,
  })

  return <div className="display-flex align-items-center"><div className={ classNames }></div> <span>{ props.option.person_name }<span className={ classSet("font-size-xxsmall", {"color-gray": !props.selected, "color-white": props.selected})}>{ props.option.zoom_user_display_name ? " – #" + props.option.zoom_user_display_name : null}</span></span> </div>
}

function ZoomLinkAutocompleteOption(props) {
  return <><span>{ props.option.zoom_user.display_name }</span> <span className={ classSet("font-size-xxsmall", {"color-gray": !props.selected, "color-white": props.selected})}>{ props.option.participant_count === 0 ? "Empty" : pluralize(props.option.participant_count, 'person') }</span></>
}

function Autocomplete(props) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [results, setResults] = useState([])
  const prevText = usePrevious(props.text)

  // By default, if you've typed a query, and there are matches,
  // the autocomplete is shown. The hidden state lets the user
  // override this by pressing escape to hide the autocomplete
  // even if there are results. It is reset every time
  // props.text changes.
  const [hidden, setHidden] = useState(false)
  const ref = useRef(null)
  const asyncRequestId = useRef(null)

  const visible = results.length !== 0 && !hidden
  const prevVisible = usePrevious(visible)

  // searchKey can be either a string or an array of strings.
  // Here, we make sure we always have an array.
  const searchKey = [].concat(props.searchKey)

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.style.top = `-${ref.current.offsetHeight - 2}px`
    }
  })

  useEffect(() => {
    setHidden(false)
  }, [props.text])

  function handleTextChange(queryStart, query) {
    let newResults

    if (queryStart === -1) {
      newResults = []
    } else if (query === "") {
      newResults = _.sortBy(props.getOptions(), o => dig(o, ...searchKey))
    } else {
      newResults = _.select(props.getOptions(), o => dig(o, ...searchKey).toLowerCase().indexOf(query) === 0)
    }

    newResults = _.first(newResults, 15)
    newResults = _.sortBy(newResults, e => !e.isInOurAudioRoom)

    if (!_.isEqual(newResults, results)) {
      setResults(newResults)
      setSelectedIndex(0)
    }
  }

  async function handleTextChangeAsync(queryStart, query) {
    let newResults
    let requestId = uuid()
    asyncRequestId.current = requestId

    if (queryStart === -1) {
      newResults = []
    } else if (query === "") {
      const options = await props.getAsyncOptions(query)
      newResults = _.sortBy(options, o => dig(o, ...searchKey))
    } else {
      const options = await props.getAsyncOptions(query)
      newResults = _.select(options, o => dig(o, ...searchKey).toLowerCase().indexOf(query) === 0)
    }

    // Only use newResults if the response we're processing
    // is from the latest async request. This ensures we don't
    // show the wrong results if responses arrive out of order.
    if (asyncRequestId.current !== requestId) {
      return
    }

    newResults = _.first(newResults, 15)

    if (!_.isEqual(newResults, results)) {
      setResults(newResults)
      setSelectedIndex(0)
    }
  }

  if (props.text !== prevText) {
    const queryStart = props.text.lastIndexOf(props.activationCharacter)
    const query = props.text.slice(queryStart+1).toLowerCase()

    if (props.getAsyncOptions) {
      handleTextChangeAsync(queryStart, query)
    } else {
      handleTextChange(queryStart, query)
    }
  }

  function handleKeyDown(e) {
    if (!visible) {
      return
    }

    switch (e.key) {
    case 'ArrowUp':
      setSelectedIndex(betterModulo(selectedIndex-1, results.length))
      break
    case 'ArrowDown':
      setSelectedIndex(betterModulo(selectedIndex+1, results.length))
      break
    case 'Escape':
      setHidden(true)
      break
    case 'Tab':
    case 'Enter':
      props.onChange(results[selectedIndex])
      break
    }
  }

  useEventListener('keydown', handleKeyDown, document)

  useEffect(() => {
    if (prevVisible !== visible) {
      props.onVisiblityChange(visible)
    }
  })

  if (hidden || results.length === 0) {
    return null
  }

  return (
    <div ref={ ref } className="autocomplete">
      <ul className="p-a-0 m-a-0 list-style-none" >
        {
          results.map((o, i) => {
            const classNames = classSet({
              "autocomplete__item": true,
              "autocomplete__item--selected": i === selectedIndex,
            })

            return (
              <li
                key={ o.id }
                onMouseEnter={ () => setSelectedIndex(i) }
                onClick={ () => props.onChange(results[i]) }
                className={ classNames }
              >
                <props.optionComponent option={ o } selected={ i === selectedIndex }/>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

Autocomplete.RESERVED_KEYS = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter', 'Tab']

function Chat(props) {
  const messagesRef = useRef(null)
  const chatBoxInputRef = useRef(null)
  const [mentionedEntityOutsideOfAudioRoom, setMentionedEntityOutsideOfAudioRoom] = useState(null)
  const avatarId = useContext(OurAvatarIdContext)
  const { messages } = props
  const [isShowingNotificationPrompt, setIsShowingNotificationPrompt] = useState(
    window.Notification && Notification.permission !== 'granted'
  )

  const scrolledToBottom = useRef(false)
  const didProgrammaticScroll = useRef(false)

  function scrollToBottom() {
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    didProgrammaticScroll.current = true
  }

  function handleScroll() {
    const el = messagesRef.current
    scrolledToBottom.current = el.scrollHeight - el.scrollTop - el.clientHeight < 1

    if (!didProgrammaticScroll.current) {
      props.onMessagesRead(props.chatScope)
    }

    didProgrammaticScroll.current = false
  }

  const previousMessages = usePrevious(messages)

  useLayoutEffect(() => {
    const messagesLoaded = _.isEmpty(previousMessages) && !_.isEmpty(messages)
    const newMessage = _.last(previousMessages)?.id !== _.last(messages)?.id

    if (messagesLoaded || (newMessage && scrolledToBottom.current)) {
      scrollToBottom()
    }
  })

  const audioRoomId = (props.chatScope === 'ROOM' && props.audioRoom?.id) || null
  useEffect(() => {
    if (audioRoomId) {
      props.spotlightEntity(props.audioRoom, {permanent: true})
    }

    return () => props.clearSpotlight({permanent: true})
  }, [audioRoomId])

  const filteredMessages = messages.filter(m => {
    const id = m.audio_room?.id || null

    return id === audioRoomId
  })

  const groupedMessages = _.reduce(filteredMessages, (groups, message) => {
    if (_.isEmpty(groups)) {
      return [[message]]
    } else if (_.last(groups)[0].sender.id == message.sender.id) {
      _.last(groups).push(message)
      return groups
    } else {
      return groups.concat([[message]])
    }
  }, [])

  useEffect(() => {
    props.onMessagesRead(props.chatScope)
  }, [])

  function handleKeyDown(e) {
    if (_.contains(["ArrowDown", "ArrowUp"], e.key)) {
      e.preventDefault()
    }
  }

  function handleAgentAutoCompleted(entity) {
    if (props.chatScope === "ROOM" && !entity.isInOurAudioRoom) {
      setMentionedEntityOutsideOfAudioRoom(entity)
    }
  }

  return (
    <div className="display-flex flex-direction-column flex-1 overflow-hidden p-x-2 p-b-2">
      {isShowingNotificationPrompt && (
        <div className="bg-tint-yellow p-a-1 p-t-half m-b-1 with-border-radius">
          <div className="display-flex justify-content-space-between align-items-baseline">
            <span className="font-size-small">
              Enable notifications to get alerts when you are @-mentioned in chat.
            </span>
            <button
              className="color-black button button--link p-a-0 p-l-1"
              onClick={() => { setIsShowingNotificationPrompt(false); }}
            >
              <i className="fas fa-times" />
            </button>
          </div>
          <div className="display-flex justify-content-flex-end">
            <button
              className="button bg-green font-size-xsmall display-block m-t-1"
              onClick={() => {
                requestDesktopNotificationPermission();
                setIsShowingNotificationPrompt(false);
              }}
            >
              Enable notifications
            </button>
          </div>
        </div>
      )}
      <div className="bg-white flex-1 overflow-auto p-a-1 m-b-1 with-border-radius without-focus-ring" style={{fontSize: '0.85rem'}} ref={ messagesRef } onClick={ () => props.onMessagesRead(props.chatScope) } onScroll={ handleScroll } onKeyDown={ handleKeyDown } tabIndex="-1">
        {
          groupedMessages.map((group, i) => {
            const sender = group[0].sender
            const groupKey = group[0].id

            return (
              <div key={groupKey} className="m-b-1">
                <div className="display-flex justify-content-space-between align-items-baseline">
                  <strong>{ sender.name }</strong>
                  <span className="color-gray font-size-xsmall m-l-half">{ moment(group[0].created_at).format("h:mma") }</span>
                </div>
                {
                  group.map((message, j) => {
                    const classNames = classSet("display-flex", "justify-content-space-between", "align-items-baseline", "m-l-1", {"bg-tint2-pink": _.contains(message.mentioned_entity_ids, avatarId)})

                    return (
                      <div key={message.id} className={ classNames } style={{ marginTop: "0.2rem", marginBottom: "0.2rem" }}>
                        <FormattedMessage
                          message={ message }
                          onEntityClick={ id => props.spotlightEntity(props.getEntityById(id)) }
                          onPosClick={ props.spotlightPos }
                        />
                        {
                          j !== 0 && !moment(message.created_at).isSame(group[j-1].created_at, 'minute') ?
                          <div className="color-gray font-size-xsmall m-l-half">{ moment(message.created_at).format("h:mma") }</div> :
                          null
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>

      {
        props.audioRoom ?
        <label className="display-flex align-items-center m-b-1 color-gray font-size-xsmall">
          Message
          <select className="form__input m-l-1" value={ props.chatScope } onChange={ e => props.setChatScope(e.target.value) }>
            <option value="EVERYONE">Everyone</option>
            <option value="ROOM">Audio chat only</option>
          </select>
        </label> :
        null
      }

      <ChatBox
        inputRef={chatBoxInputRef}
        getMentionableEntities={ props.getMentionableEntities }
        onFocus={ props.inputFocused }
        onBlur={ props.inputBlurred }
        onMessagesRead={ props.onMessagesRead }
        onSend={ () => setMentionedEntityOutsideOfAudioRoom(null) }
        onAgentAutoCompleted={ handleAgentAutoCompleted}
        chatScope={ props.chatScope }
        audioRoomId={ audioRoomId }
      />

      {mentionedEntityOutsideOfAudioRoom && <div className="m-t-1 p-a-1 bg-tint-yellow font-size-xsmall with-border-radius">
        You mentioned <strong>@{ mentionedEntityOutsideOfAudioRoom.person_name}</strong>, who is not in this audio room. Would you like to send this message to everyone?
        <div className="display-inline-flex justify-content-flex-end float-right m-l-1">
          <button
            className="button button--link font-size-xsmall color-green p-a-0 m-a-0 no-border"
            onClick={() => {
              setMentionedEntityOutsideOfAudioRoom(null)
              chatBoxInputRef.current.focus()
            }}
          >
            No
          </button>
          <button
            className="button button--link font-size-xsmall color-green p-a-0 m-a-0 m-l-1 no-border"
            onClick={() => {
              setMentionedEntityOutsideOfAudioRoom(null)
              props.setChatScope("EVERYONE")
              chatBoxInputRef.current.focus()
            }}
          >
            Yes
          </button>
        </div>
      </div>}

      <div className="color-gray font-size-xsmall m-t-1 font-style-italic " style={{marginLeft: 4}}>
        Messages disappear after 24 hours.
      </div>
    </div>
  )
}

function ChatBox(props) {
  const { onFocus, onBlur, onSend, audioRoomId } = props
  const [msg, setMsg] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [autocompleteIsVisible, setAutocompleteIsVisible] = useState(false)

  async function sendMsg() {
    if (msg.match(/^\s*$/) || submitting) {
      return
    }

    props.onMessagesRead(props.chatScope)

    setSubmitting(true)

    try {
      await Api.messages.create({text: msg, audio_room_id: audioRoomId})
      onSend()
      setMsg("")
    } catch (e) {
      alert("Error: Sorry, something went wrong while sending your message. Please reload the page and try again.")
    } finally {
      setSubmitting(false)
    }
  }

  function handleKeyDown(e) {
    if (autocompleteIsVisible && _.contains(Autocomplete.RESERVED_KEYS, e.key)) {
      e.preventDefault()
      return
    }

    switch (e.key) {
      case 'Escape':
        props.inputRef.current.blur()
        break
      case 'Enter':
        sendMsg()
        break
    }
  }

  function handleAgentAutocomplete(agent) {
    let i = msg.lastIndexOf('@')

    // If we can't find @, which would be a bug,
    // just replace the whole string.
    if (i === -1) {
      i = 0
    }

    setMsg(msg.slice(0, i) + `@**${agent.person_name}** `)
    props.onAgentAutoCompleted(agent)

    props.inputRef.current.focus()
    props.inputRef.current.scrollLeft = props.inputRef.current.scrollWidth
  }

  function handleZoomLinkAutocomplete(zoomLink) {
    let i = msg.lastIndexOf('#')

    // If we can't find #, which would be a bug,
    // just replace the whole string.
    if (i === -1) {
      i = 0
    }

    setMsg(msg.slice(0, i) + `#**${zoomLink.zoom_user.display_name}** `)

    props.inputRef.current.focus()
    props.inputRef.current.scrollLeft = props.inputRef.current.scrollWidth
  }

  function handleFocus() {
    props.onMessagesRead(props.chatScope)
    onFocus()
  }

  async function getZoomLinks(query) {
    const resp = await Api.zoomLinks.index({query})
    return await resp.json()
  }

  return (
    <div className="chat-box">
      <div className="chat-box__input-container">
        <label htmlFor="chat-box-input" className="display-none">Chat message (@Name is like a tap on the shoulder)</label>

        <Autocomplete
          activationCharacter="@"
          getOptions={ () => props.getMentionableEntities({audioRoomId: audioRoomId}) }
          searchKey="person_name"
          optionComponent={ AvatarAutocompleteOption }
          onChange={ handleAgentAutocomplete }
          onVisiblityChange={ setAutocompleteIsVisible }
          text={ msg }
        />

        <Autocomplete
          activationCharacter="#"
          getAsyncOptions={ getZoomLinks }
          searchKey={["zoom_user", "display_name"]}
          optionComponent={ ZoomLinkAutocompleteOption }
          onChange={ handleZoomLinkAutocomplete }
          onVisiblityChange={ setAutocompleteIsVisible }
          text={ msg }
        />

        <input
          id="chat-box-input"
          ref={ props.inputRef }
          maxLength={ 140 }
          onKeyDown={ handleKeyDown }
          onFocus={ handleFocus }
          onBlur={ onBlur }
          value={ msg }
          onChange={ (e) => setMsg(e.target.value) }
          className="chat-box__text"
          style={{userSelect: "none"}}
          placeholder="@Name to mention. #Zoom Room to link."
          autoComplete="off"
        />
      </div>

      <button onClick={ sendMsg } disabled={ submitting || msg.match(/^\s*$/) } className="floating-button">Send</button>
    </div>
  );
}

function OnlineNow (props) {

  const [currentlyAtRCAvatars, setCurrentlyAtRCAvatars] = useState([])

  async function loadCurrentlyAtRCAvatars() {
    try {
      const resp = await Api.avatars.currently_at_rc()
      const avatars = await resp.json()

      setCurrentlyAtRCAvatars(avatars)
    } catch {
      alert("Something went wrong while loading everyone at RC")
    }
  }

  useEffect(() => {
    loadCurrentlyAtRCAvatars()
  }, [])

  const ourId = props.ourAvatar()?.id

  function score(avatar) {
    let score = 0

    if (avatar.id === ourId) {
      score += 500
    }

    if(avatar.is_active) {
      score += 100
    } else if (avatar.is_idle) {
      score += 25
    }

    if (avatar.zoom_user_id) {
      score += 50
    }

    const { desk } = avatar
    const isStatusValid = desk?.emoji && !isExpired(getExpiresAt(desk))
    const hasStatusText = isStatusValid && desk.status

    // you get 5 points for an emoji, and 5 more points for text
    if (isStatusValid) {
      score += 5
    }

    if (hasStatusText) {
      score += 5
    }

    return score
  }

  const [withoutScore, withScore] = _.partition(props.getPeopleOnline(currentlyAtRCAvatars), (a) => score(a) === 0)

  const peopleToShow = _.sortBy(withScore, score).reverse().concat(_.sortBy(withoutScore, (a) => moment(a.last_seen_at).unix()).reverse())

  return <div className="display-flex flex-direction-column flex-1 overflow-hidden p-x-2 p-b-2">
    <div className="bg-white overflow-auto p-x-1 p-t-1 m-b-1 with-border-radius without-focus-ring" style={{ fontSize: "0.85rem" }}>{
      peopleToShow.map((avatar, i) => {
        const isUs = !i
        const { desk } = avatar
        const isStatusValid = desk?.emoji && !isExpired(getExpiresAt(desk))
        const hasStatusText = isStatusValid && desk.status
        const lastSeen = moment(avatar.last_seen_at)

        let zoomLink = null
        if (avatar.zoom_user_id) {
          zoomLink = _.find(props.getZoomLinks(), l => l.zoom_user?.id === avatar.zoom_user_id)
        }
        const calendarEvent = avatar.zoom_meeting_topic

        const indicatorClassNames = classSet("presence-indicator", {
          "presence-indicator--active": avatar.is_active,
          "presence-indicator--idle": !avatar.is_active && avatar.is_idle,
          "presence-indicator--absent": !avatar.is_active && !avatar.is_idle,
        })

        const statusWrapperProps = desk ? {
          title: "Spotlight desk",
          className: "cursor-pointer",
          onClick: () => props.spotlightEntity(desk),
        } : {}

        return <div key={ avatar.id } className="m-b-1">
          <div className="display-flex align-items-center">
            <div className={ indicatorClassNames } />
            <strong
              className="cursor-pointer"
              onClick={ () => props.spotlightEntity(avatar) }
              title={ `Last seen ${moment(avatar.last_seen_at).format("MMM D [at] h:mma")}` }
            >
              { avatar.person_name } { avatar.flair ? `(${avatar.flair})` : null }
            </strong>
          </div>
          <div
            className="color-gray"
            style={{
              marginLeft: 10,
              paddingTop: "0.125rem",
              paddingLeft: "0.5rem",
            }}
          >
            {
              isStatusValid ?
              <span {...statusWrapperProps}>
                <span style={{marginRight: '0.125rem'}}>{ desk.emoji }</span>{ desk.status }
              </span> :
              null
            }
            { zoomLink && <>
              { !isStatusValid && <span style={{marginRight: '0.125rem'}}>{
                calendarEvent ? "📆" : "🎥"
              }</span> }
              { hasStatusText ? ' in' : 'in' } <span
                className="color-blue hover-color-light-blue cursor-pointer font-weight-bold"
                onClick={ () => props.spotlightEntity(zoomLink) }
              >#{ zoomLink.zoom_user.display_name }</span>
              { calendarEvent && <>
                {' for '}{calendarEvent}
              </> }
            </>}
            {
              score(avatar) === 0 ?
              <span {...statusWrapperProps}><em>{lastSeen.isSame(moment.unix(0)) ? 'Not yet seen' : `Last seen ${lastSeen.fromNow()}`}</em></span> :
              !hasStatusText && !zoomLink ?
              <span {...statusWrapperProps}><em>No status</em></span> :
              null
            }
            { isUs && desk && <button
              className="button button--link p-a-0 no-border m-r-1"
              onClick={ () => props.walkToAndEdit(desk.pos, avatar) }
              title="Edit status"
            >
              <i className="fas fa-pencil-alt color-green hover-color-dark-green font-size-xxsmall p-l-1" />
            </button> }
          </div>
        </div>
      })
    }</div>
  </div>
}

const COLORS = {
  green: '#3dc06c',
  blue: '#4d9bd8',
  purple: '#956bc3',
  pink: '#d95a88',
  orange: '#e6a56e',
  yellow: '#e7dd6f',
  gray: '#919c9c',
  red: '#ff0000',

  'light-yellow': '#fff480',
  'light-green': '#69dc92',
  'light-blue': '#66bdff',
  'light-orange': '#ffb980',
  'light-pink': '#fb70a7',

  'dark-orange': '#d28e56',
  'darkest-orange': '#c17434',
  'dark-green': '#23a050',

  'light-gray': '#b5bfbf',
  'dark-gray': '#626a6a',
  'darkest-gray': '#2a2d2d',
  'off-white': '#f7f8f8',
}

const HIGHLIGHT_COLORS = {
  ZoomLink: "blue",
  Desk: "orange",
  Note: "dark-gray",
  Link: "dark-gray",
  "RC::Calendar": "gray",
  AudioBlock: "pink",
  PhotoBlock: "gray",
}

const ICONS = {
  'video': {codepoint: '\uf03d', font: '900 16px "Font Awesome 6 Free"'},
  'sticky-note': {codepoint: '\uf249', font: '900 16px "Font Awesome 6 Free"'},
  'external-link': {codepoint: '\uf35d', font: '900 16px "Font Awesome 6 Free"'},
  'microphone': {codepoint: '\uf130', font: '900 16px "Font Awesome 6 Free"'},
  'laptop': {codepoint: '\uf109', font: '900 14px "Font Awesome 6 Free"'},
  'stopwatch': {codepoint: '\uf2f2', font: '900 16px "Font Awesome 6 Free"'},
  'times-circle': {codepoint: '\uf057', font: '900 13px "Font Awesome 6 Free"'},
  'times': {codepoint: '\uf00d', font: '900 16px "Font Awesome 6 Free"'},
  'calendar': {codepoint: '\uf073', font: '400 16px "Font Awesome 6 Free"'},
  'user-cog': {codepoint: '\uf4fe', font: '900 16px "Font Awesome 6 Free"'},
  'volume-up': {codepoint: '\uf028', font: '900 16px "Font Awesome 6 Free"'},
  'volume-mute': {codepoint: '\uf6a9', font: '900 16px "Font Awesome 6 Free"'},
  'phone': {codepoint: '\uf095', font: '900 16px "Font Awesome 6 Free"'},
  'camera-retro': {codepoint: '\uf083', font: '900 16px "Font Awesome 6 Free"'}
};

class Rect {
  constructor(origin, size) {
    this.origin = origin;
    this.size = size;
  }

  static centered(center, size) {
    const origin = {x: center.x - Math.floor(size.width/2), y: center.y - Math.floor(size.height/2)}
    return new Rect(origin, size)
  }

  get minX() {
    return this.origin.x;
  }

  get minY() {
    return this.origin.y;
  }

  get maxX() {
    return this.origin.x + this.size.width;
  }

  get maxY() {
    return this.origin.y + this.size.height;
  }

  get width() {
    return this.size.width;
  }

  get height() {
    return this.size.height;
  }

  contains(p) {
    return p.x >= this.minX && p.y >= this.minY && p.x < this.maxX && p.y < this.maxY;
  }

  containedPoints() {
    return _.flatten(_.range(this.minX, this.maxX).map(
      x => _.range(this.minY, this.maxY).map(y => { return {x, y}; })
    ));
  }

  containedEntities(entities) {
    return _.flatten(_.compact(this.containedPoints().map(p => entities[indexKey(p)])));
  }

  overlaps(rect) {
    return this.minX < rect.maxX && this.maxX > rect.minX && this.minY < rect.maxY && this.maxY > rect.minY;
  }

  insetBy(n) {
    const newOrigin = {x: this.origin.x + n, y: this.origin.y + n}
    const newSize = {width: this.width - n*2, height: this.height - n*2}

    return new Rect(newOrigin, newSize)
  }

  movingAwayFrom(point, direction) {
    var directions = []

    if (point.x < this.origin.x) {
      directions.push("left")
    } else if (point.x >= this.origin.x + this.width) {
      directions.push("right")
    }

    if (point.y < this.origin.y) {
      directions.push("up")
    } else if (point.y >= this.origin.y + this.height) {
      directions.push("down")
    }

    return _.contains(directions, direction)
  }
}

function makeRectFromAudioRoom(audioRoom) {
  return new Rect({x: audioRoom.pos.x, y: audioRoom.pos.y}, {width: audioRoom.width, height: audioRoom.height});
}

class AudioRoomDragRect extends Rect {
  isValid(entities, avatarId, audioRooms) {
    const overlapsWithAvatar = _.any(this.containedEntities(entities), e => {
      return e.type === "UnknownAvatar" || (e.type === "Avatar" && e.id !== avatarId);
    });

    if (overlapsWithAvatar) {
      return false;
    }

    const roomRects = audioRooms.map(room => makeRectFromAudioRoom(room))

    if (_.any(roomRects, (rect) => this.overlaps(rect))) {
      return false;
    }

    return true;
  }
}

class MoveGestureRecognizer {
  constructor(target) {
    this.target = target;
    this.onChange = null;
    this.event = null;

    this.enabled = true;

    target.addEventListener('mousemove', this.handleMouseMove);
  }

  viewportPos() {
    return this.viewportPosFor(this.event);
  }

  viewportPosFor(event) {
    if (!event) {
      return null;
    }

    return {
      x: Math.floor(event.clientX/WIDTH),
      y: Math.floor(event.clientY/HEIGHT),
    };
  }

  handleMouseMove = (e) => {
    if (!this.enabled) {
      return;
    }

    const lastEvent = this.event;
    const lastPos = this.viewportPosFor(lastEvent);
    const newPos = this.viewportPosFor(e);

    this.event = e;

    if (_.isEqual(lastPos, newPos)) {
      return;
    }


    if (this.onChange) {
      this.onChange(this);
    }
  }
}

const MODIFIERS = {
  Shift: 'shiftKey',
  Command: 'metaKey',
  Control: 'ctrlKey',
  Option: 'altKey',
};

function eventMatchesModifiers(event, modifiers) {
  for (let m in MODIFIERS) {
    const attr = MODIFIERS[m]

    if (_.include(modifiers, m) !== event[attr]) {
      return false
    }
  }

  return true
}

function toArray(v) {
  if (Array.isArray(v)) {
    return v
  } else if (v) {
    return [v]
  } else {
    return []
  }
}

class DragGestureRecognizer {
  constructor(downTarget, moveTarget, upTarget, options={}) {
    this.downTarget = downTarget
    this.moveTarget = moveTarget
    this.upTarget = upTarget
    this.modifiers = toArray(options.modifiers)

    this.onStart = null;
    this.onChange = null;
    this.onEnd = null;
    this.onCancel = null;

    this.event = null;
    this.initialEvent = null;

    this.dragging = false;
    this.enabled = true;

    downTarget.addEventListener('mousedown', this.handleMouseDown);
    moveTarget.addEventListener('mousemove', this.handleMouseMove);
    upTarget.addEventListener('mouseup', this.handleMouseUp);

    downTarget.addEventListener('touchstart', this.handleTouchStart);
    moveTarget.addEventListener('touchmove', this.handleTouchMove);
    upTarget.addEventListener('touchend', this.handleTouchEnd);
  }

  viewportPos() {
    return this.viewportPosFor(this.event);
  }

  initialViewportPos() {
    return this.viewportPosFor(this.initialEvent);
  }

  previousViewportPos() {
    return this.viewportPosFor(this.previousEvent);
  }

  eventX(event) {
    if (event.type.indexOf('touch') === 0) {
      return event.touches[0].clientX
    } else {
      return event.clientX
    }
  }

  eventY(event) {
    if (event.type.indexOf('touch') === 0) {
      return event.touches[0].clientY
    } else {
      return event.clientY
    }
  }

  viewportPosFor(event) {
    if (!event) {
      return null;
    }

    return {
      x: Math.floor(this.eventX(event)/WIDTH),
      y: Math.floor(this.eventY(event)/HEIGHT),
    };
  }

  cancel() {
    if (this.dragging && this.onCancel) {
      this.onCancel(this);
    }

    this.dragging = false;
  }

  handleTouchStart = (e) => {
    this.handleMouseDown(e)
  }

  handleTouchMove = (e) => {
    this.handleMouseMove(e)
  }

  handleTouchEnd = (e) => {
    this.handleMouseUp(e)
  }

  handleMouseDown = (e) => {
    if (!this.enabled) {
      return;
    }

    if (!eventMatchesModifiers(e, this.modifiers)) {
      return;
    }

    this.event = this.initialEvent = this.previousEvent = e;

    this.dragging = true;

    if (this.onStart) {
      this.onStart(this);
    }
  }

  handleMouseMove = (e) => {
    if (!this.enabled || !this.dragging) {
      return;
    }

    const lastEvent = this.event;
    const lastPos = this.viewportPosFor(lastEvent);
    const newPos = this.viewportPosFor(e);

    this.previousEvent = lastEvent;
    this.event = e;

    if (_.isEqual(lastPos, newPos)) {
      return;
    }

    if (this.onChange) {
      this.onChange(this);
    }
  }

  handleMouseUp = (e) => {
    if (!this.enabled || !this.dragging) {
      return;
    }

    this.previousEvent = this.event;
    this.event = e;

    if (this.onEnd) {
      this.onEnd(this);
    }

    this.dragging = false;
  }
}

class Animation {
  constructor(gridWorld, entity, oldPos, newPos) {
    this.gridWorld = gridWorld
    this.entity = entity

    this.previousPos = null
    this.currentPos = oldPos
    this.startPos = oldPos
    this.path = gridWorld.shortestPath(this.currentPos, newPos)

    this.eventListeners = {}

    this.frameDuration = 80 // milliseconds

    this.startedAt = null
  }

  start() {
    const { animatingEntities } = this.gridWorld.state

    const key = indexKey(this.currentPos)

    const animatingEntity = {
      ...this.entity,
      pos: this.currentPos
    }

    this.gridWorld.setState({
      animatingEntities: {
        ...animatingEntities,
        [key]: addOrUpdateEntity(animatingEntities[key], animatingEntity),
      }
    })

    this.gridWorld.animatingIdToPosIndex[animatingEntity.id] = key

    this.intervalId = setInterval(this.step, this.frameDuration)

    this.startedAt = moment()
  }

  cancel() {
    const { animatingEntities } = this.gridWorld.state

    clearInterval(this.intervalId)

    const key = indexKey(this.currentPos)

    this.gridWorld.setState({
      animatingEntities: {
        ...animatingEntities,
        [key]: _.reject(animatingEntities[key], e => e.id === this.entity.id),
      }
    })

    delete this.gridWorld.animatingIdToPosIndex[this.entity.id]
    delete this.gridWorld.animations[this.entity.id]
  }

  addEventListener(eventType, listener) {
    if (!_.has(this.eventListeners, eventType)) {
      this.eventListeners[eventType] = []
    }

    this.eventListeners[eventType].push(listener)
  }

  finish() {
    this.cancel()

    _.each(this.eventListeners['complete'], listener => listener(this))
  }

  step = () => {
    const { animatingEntities } = this.gridWorld.state

    if (_.isEmpty(this.path)) {
      this.finish()

      return
    }

    const nextPos = this.path.shift()

    const oldKey = indexKey(this.currentPos)
    const newKey = indexKey(nextPos)

    const animatingEntity = {
      ...this.entity,
      pos: nextPos
    }

    this.gridWorld.setState({
      animatingEntities: {
        ...animatingEntities,
        [oldKey]: _.reject(animatingEntities[oldKey], e => e.id === animatingEntity.id),
        [newKey]: addOrUpdateEntity(animatingEntities[newKey], animatingEntity),
      }
    })

    this.gridWorld.animatingIdToPosIndex[animatingEntity.id] = newKey

    this.previousPos = this.currentPos
    this.currentPos = nextPos

    _.each(this.eventListeners['step'], listener => listener(this))
  }
}

function getExpiresAt(entity, field = "expires_at") {
  if (entity[field]) {
    return moment(entity[field]).startOf('second')
  } else {
    return null
  }
}

function isExpired(expiresAt) {
  return expiresAt?.isSameOrBefore(moment())
}

function shouldJiggle(entity) {
  return entity.type === 'Avatar' || entity.type === 'UnknownAvatar';
}

function initials(name) {
  const nameWithoutParens = name.replace(/\(.*?\)|\[.*?\]|\{.*?\}|\<.*?\>/g, '')
  const words = _.reject(nameWithoutParens.split(/\s+/), _.isEmpty)

  if (words.length === 0) {
    return "0" // weird edge case
  } else if (words.length === 1) {
    return _.first(words)[0]
  } else {
    return _.first(words)[0] + _.last(words)[0]
  }
}

const MENTION_REGEXP = /[@#]\*\*([^*]+)\*\*/g
const POS_REGEXP = /#\((\d+), *(\d+)\)/g

function extractChatMessageSegments(message) {
  const entityMatches = [...message.text.matchAll(MENTION_REGEXP)];
  const posMatches = [...message.text.matchAll(POS_REGEXP)]

  let res = []
  let i = 0

  const matches = _.sortBy(entityMatches.concat(posMatches), 'index')
  const entityIds = [...message.mentioned_entity_ids]
  for (let m of matches) {
    const plainText = message.text.slice(i, m.index)

    if (plainText !== "") {
      res.push({text: plainText, type: 'plain'})
    }

    const symbol = message.text.slice(m.index, m.index + 2);
    if (symbol === '#*') {
      const id = entityIds.shift()

      if (id) {
        res.push({text: `#${m[1]}`, type: 'zoom', entityId: id})
      } else {
        res.push({text: m[0], type: 'plain'})
      }
    } else if (symbol === '@*') {
      const id = entityIds.shift()

      if (id) {
        res.push({text: `@${m[1]}`, type: 'mention', entityId: id})
      } else {
        res.push({text: m[0], type: 'plain'})
      }
    } else if (symbol === '#(') {
      res.push({
        text: `#(${m[1]},${m[2]})`,
        type: 'pos',
        pos: {x: parseInt(m[1], 10), y: parseInt(m[2], 10)},
      });
    }

    i = m.index + m[0].length
  }

  res.push({text: message.text.slice(i), type: 'plain'})

  return res
}

function formatMentionsAsPlainText(message) {
  return _.map(extractChatMessageSegments(message), 'text').join('')
}

function directionFacing(newPos, {from: oldPos}) {
  const dx = newPos.x - oldPos.x
  const dy = newPos.y - oldPos.y

  let sx = Math.sign(dx)
  let sy = Math.sign(dy)

  if (sx === 1) {
    return "right"
  } else if (sx === -1) {
    return "left"
  } else if (sy === 1) {
    return "down"
  } else {
    return "up"
  }
}

export default class CanvasWorld {
  constructor(canvas, spotlightCanvas, reactRoot) {
    const data = JSON.parse(canvas.dataset.initialData)

    this.state = {
      connected: false,
      twilioRoom: null,
      entities: data.entities,
      animatingEntities: {},
      viewportOrigin: data.viewport_origin,
      viewportWidth: canvas.offsetWidth,             // pixels
      viewportHeight: canvas.offsetHeight,           // pixels
      jiggles: this.makeJiggles(data.entities),
      fontLoaded: false,
      selectedColor: 'gray',
      audioRoomDragRect: null,
      blockBeingEdited: null,
      posFacing: null,
      mousePos: null,
      spotlightedPos: null,
      spotlightedEntity: null,
      mediaPlayerIndex: {},
      screenShareIndex: {},
      sharedTimerIndex: {},
    };

    this.frame = 0;
    this.animations = {}
    this.sequenceNumber = null
    this.avatarOfflineTimeout = moment.duration(data.avatar_offline_timeout)

    this.avatar_id = data.avatar_id;
    this.desk_id = data.desk_id;
    this.worldRows = data.rows;
    this.worldCols = data.cols;
    this.receivedInitialWorld = false
    this.block_types = data.block_types;
    this.colors = data.colors;
    this.maxAudioRoomWidth = data.max_audio_room_width;
    this.maxAudioRoomHeight = data.max_audio_room_height;

    this.shouldShowBlockTypePickerOnNextTypeChangeForBlock = null
    this.lastViewportMoveStartedAt = null
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');

    // Number of seconds that the client's clock is ahead of the
    // server's clock. Used to correct for bad client clocks when
    // deciding whether avatars are active, idle, or not present,
    // and for displaying a notice asking the user to correct their
    // clock. Positive when the client is ahead of the server.
    // Negative when it's behind.
    this.clientClockSkew = 0

    this.spotlightCanvas = spotlightCanvas
    this.spotlightCtx = spotlightCanvas.getContext('2d')

    this.setupCanvas(this.canvas, this.ctx, this.canvas.offsetWidth, this.canvas.offsetHeight);
    this.setupCanvas(this.spotlightCanvas, this.spotlightCtx, window.innerWidth, window.innerHeight);
    this.connectToActionCable();
    this.setupGestureRecognizers();

    this.canvas.addEventListener('click', this.handleClick)
    this.canvas.addEventListener('dblclick', this.handleDoubleClick)

    this.spotlightCanvas.addEventListener('click', this.clearSpotlight)

    this.startEditingBlockAfterMove = false

    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)

    this.userHasInteracted = false
    document.addEventListener('keydown', this.setUserHasInteracted)
    document.addEventListener('click', this.setUserHasInteracted)
    document.addEventListener('touchstart', this.setUserHasInteracted)

    this.resizeObserver = new ResizeObserver(this.handleResize)
    this.resizeObserver.observe(canvas)

    this.setupPresence()

    this.imageCache = new ImageCache(data.broken_image_src)
    this.addEntitiesToImageCache(data.entities);
    this.idToPosIndex = createPosIndex(data.entities);
    this.animatingIdToPosIndex = createPosIndex(this.state.animatingEntities)

    this.dirty = true;

    this.renderReactUI(reactRoot, data);

    document.fonts.load('16px "Font Awesome 6 Free"').then(() => {
      this.setState({fontLoaded: true});
    });

    requestDesktopNotificationPermission()

    requestAnimationFrame(this.run)
  }

  renderReactUI(reactRoot, data) {
    ReactDOM.render(
      <OurAvatarIdContext.Provider value={ data.avatar_id }>
        <GridWorldUI
          ref={ ui => this.ui = ui }
          entitiesAt={ this.stationaryEntitiesAt }
          getEntityById={ this.getEntity }
          worldToViewport={ this.worldToViewport }
          selectBlockType={ this.selectBlockType }
          stopEditing={ this.stopEditing }
          autoLogin={ data.auto_login }
          getBlockTypes={ this.blockTypesForPosFacing }
          getSelectedColor={ this.getSelectedColor }
          getOurDesk={ this.ourDesk }
          walkToAndEdit={ this.walkToAndEdit }
          realm={ data.realm }
          getOnlineAvatarsWithDeskKey= { this.onlineAvatarsWithDeskKey }
          getMentionableEntities={ this.mentionableEntities }
          initialLastReadMessagesAtByScope={ data.last_read_messages_at_by_scope }
          initialViewport={{width: this.state.viewportWidth, height: this.state.viewportHeight}}
          currentUser={ data.current_user }
          spotlightPos={ this.spotlightPos }
          spotlightEntity={ this.spotlightEntity }
          clearSpotlight={ this.clearSpotlight }
          environment={ data.environment }
          ourAvatar={ this.ourAvatar }
          currentAudioRoom={ this.currentAudioRoom }
          getUserHasInteracted={ this.getUserHasInteracted }
          getZoomLinks={ this.zoomLinks }
          onMicMutedChange={ this.toggleMuteIfInAudioRoom }
          setCanvasNeedsRedraw={ this.setNeedsRedraw }
        />
      </OurAvatarIdContext.Provider>,
    reactRoot);
  }

  stopEditing = () => {
    this.setState({blockBeingEdited: null});
  }

  getSelectedColor = () => {
    return this.state.selectedColor
  }

  setupGestureRecognizers() {
    this.uiGestureRecognizer = new MoveGestureRecognizer(this.canvas);
    this.uiGestureRecognizer.enabled = false;

    this.uiGestureRecognizer.onChange = this.mouseMoved;


    this.audioRoomDragRecognizer = new DragGestureRecognizer(this.canvas, document, document, {modifiers: "Shift"});
    this.audioRoomDragRecognizer.enabled = false;

    this.audioRoomDragRecognizer.onStart = this.audioRoomDragStarted;
    this.audioRoomDragRecognizer.onChange = this.audioRoomDragChanged;
    this.audioRoomDragRecognizer.onEnd = this.audioRoomDragEnded;
    this.audioRoomDragRecognizer.onCancel = this.audioRoomDragCanceled;


    this.viewportDragRecognizer = new DragGestureRecognizer(this.canvas, document, document);
    this.viewportDragRecognizer.enabled = false;

    this.viewportDragRecognizer.onStart = this.viewportDragStarted;
    this.viewportDragRecognizer.onChange = this.viewportDragChanged;
    this.viewportDragRecognizer.onEnd = this.viewportDragEnded;
    this.viewportDragRecognizer.onCancel = this.viewportDragEnded
  }

  mouseMoved = (recognizer) => {
    const pos = this.viewportToWorld(recognizer.viewportPos());

    this.setMousePos(pos);
  }

  audioRoomDragStarted = (recognizer) => {
    const pos = this.viewportToWorld(recognizer.viewportPos());

    if (!this.isInWorld(pos) || this.ui.shouldSuppressInput()) {
      recognizer.cancel();
      return;
    }

    this.uiGestureRecognizer.enabled = false;
    this.setMousePos(null); // make sure we're not showing a tooltip while dragging

    const audioRoomDragRect = new AudioRoomDragRect(pos, {width: 1, height: 1});

    this.setState({audioRoomDragRect});
  }

  audioRoomDragChanged = (recognizer) => {
    const startPos = this.viewportToWorld(recognizer.initialViewportPos());
    const pos = this.clampToWorld(this.viewportToWorld(recognizer.viewportPos()));

    const origin = {
      x: Math.max(Math.min(startPos.x, pos.x), startPos.x - this.maxAudioRoomWidth + 1),
      y: Math.max(Math.min(startPos.y, pos.y), startPos.y - this.maxAudioRoomHeight + 1)
    };

    const size = {
      width: Math.min(Math.abs(pos.x - startPos.x) + 1, this.maxAudioRoomWidth),
      height: Math.min(Math.abs(pos.y - startPos.y) + 1, this.maxAudioRoomHeight),
    };

    const audioRoomDragRect = new AudioRoomDragRect(origin, size);

    this.setState({audioRoomDragRect});
  }

  audioRoomDragEnded = (recognizer) => {
    const { audioRoomDragRect } = this.state;
    const { avatar_id } = this;

    if (audioRoomDragRect.isValid(this.visibleEntities(), avatar_id, this.audioRooms())) {
      this.subscription.createAudioRoom(audioRoomDragRect);
    }

    this.setState({audioRoomDragRect: null});
    this.uiGestureRecognizer.enabled = true;
    this.canvas.style.cursor = null;
  }

  audioRoomDragCanceled = (recognizer) => {
    this.setState({audioRoomDragRect: null});
    this.uiGestureRecognizer.enabled = true;
    this.canvas.style.cursor = null;
  }

  viewportDragStarted = (recognizer) => {
    if (this.ui.shouldSuppressInput()) {
      recognizer.cancel();
      return;
    }

    this.lastViewportMoveStartedAt = moment()
    this.canvas.style.cursor = 'move';
  }

  // also done on the server, update it there if you updated this
  clampViewport(origin) {
    let newOrigin = _.clone(origin)

    if (origin.x < -10) {
      newOrigin.x = -10
    }

    if (origin.x > this.worldCols - 5) {
      newOrigin.x = this.worldCols - 5
    }

    if (origin.y < -10) {
      newOrigin.y = -10
    }

    if (origin.y > this.worldRows - 5) {
      newOrigin.y = this.worldRows - 5
    }

    return newOrigin
  }

  viewportDragChanged = (recognizer) => {
    const { viewportOrigin } = this.state;

    const pos = recognizer.viewportPos();
    const prevPos = recognizer.previousViewportPos();

    const dx = pos.x - prevPos.x;
    const dy = pos.y - prevPos.y;

    const newOrigin = this.clampViewport({
      x: viewportOrigin.x - dx,
      y: viewportOrigin.y - dy,
    });

    this.setState({viewportOrigin: newOrigin});
  }

  viewportDragEnded = (recognizer) => {
    const { viewportOrigin } = this.state
    const avatar = this.ourAvatar()

    this.canvas.style.cursor = null;
    this.updateRealtimeData(avatar.pos, avatar.direction, viewportOrigin, avatar.muted)
  }

  setMousePos(pos) {
    this.setState({mousePos: pos})

    this.ui && this.ui.mouseMoved(pos);
  }

  setupCanvas(canvas, ctx, width, height) {
    const scale = window.devicePixelRatio;

    canvas.width = scale*width;
    canvas.height = scale*height;

    ctx.scale(scale, scale);
  }

  editAvatar(pos) {
    const avatars = this.avatarsAndUnknownAvatarsAt(pos)

    let avatar = _.findWhere(avatars, {id: this.avatar_id}) || _.last(avatars)

    if (avatar) {
      this.ui.setState({avatarBeingEdited: avatar})
    }
  }

  setupPresence() {
    this.lastPresentAt = moment()

    window.addEventListener('keydown', this.setLastPresentAt)
    window.addEventListener('click', this.setLastPresentAt)
    window.addEventListener('mousemove', this.setLastPresentAt)
    window.addEventListener('focus', this.setLastPresentAt)

    this.presenceInterval = setInterval(() => this.subscription.updatePresence(this.lastPresentAt), 60 * 1000)
  }

  setLastPresentAt = (e) => {
    this.lastPresentAt = moment()
  }

  handleClick = (e) => {
    const viewportPos = {
      x: Math.floor(e.clientX/WIDTH),
      y: Math.floor(e.clientY/HEIGHT),
    }

    const pos = this.viewportToWorld(viewportPos)

    const block = this.blockAt(pos)

    // click to delete
    if (eventMatchesModifiers(e, ["Command", "Option", "Shift"])) {
      if (block) {
        this.toggleAnyBlock(pos, null, {deleteNonWalls: true})
      }

      return
    }

    // dev inspector
    if (eventMatchesModifiers(e, ["Command", "Option"])) {
      if (block) {
        console.log("RC Together inspector: ",  _.pick(block, "pos", "type", "id"))
      } else {
        console.log("RC Together inspector: ",  pos)
      }
      return
    }

    if (eventMatchesModifiers(e, ["Option"])) {
      this.editAvatar(pos)
      return
    }

    if (block && _.isEqual(this.ui.state.selectedPos, pos)) {
      this.ui.setState({selectedPos: null, overlayPlacement: null})
    } else if (block) {
      this.ui.setState({selectedPos: pos, overlayPlacement: 'top'})
    } else {
      this.ui.setState({selectedPos: null, overlayPlacement: null})
    }
  }

  handleDoubleClick = (e) => {
    this.ui.setState({selectedPos: null, overlayPlacement: null})

    const viewportPos = {
      x: Math.floor(e.clientX/WIDTH),
      y: Math.floor(e.clientY/HEIGHT),
    }

    const pos = this.viewportToWorld(viewportPos)
    const avatar = this.ourAvatar()

    this.walkToAndEdit(pos, avatar)
  }

  walkToAndEdit = (pos, avatar) => {
    const { viewportOrigin } = this.state

    const doubleClickedOnBlock = !!this.blockAt(pos)

    if (doubleClickedOnBlock && _.isEqual(this.posFacing(), pos)) {
      this.editBlockAt(this.posFacing())
      return
    }

    const adjacentPositions = this.adjacentPositions(pos)

    let destination
    if (doubleClickedOnBlock && _.any(adjacentPositions, p => _.isEqual(p, avatar.pos))) {
      destination = avatar.pos
    } else if (doubleClickedOnBlock) {
      const adjacentPositionsWithoutBlocks = adjacentPositions.filter(p => !this.blockAt(p))
      const paths = _.compact(_.map(adjacentPositionsWithoutBlocks, p => this.shortestPath(avatar.pos, p)))
      destination = _.first(_.sortBy(paths, 'length').map(_.last))
    } else if (this.shortestPath(avatar.pos, pos)) {
      destination = pos
    }

    if (destination) {
      const newMuted = this.mutedForMove(avatar, destination)

      let direction = avatar.direction
      if (doubleClickedOnBlock) {
        direction = directionFacing(pos, {from: destination})
        this.startEditingBlockAfterMove = true
      }

      this.updateRealtimeData(destination, direction, viewportOrigin, newMuted)
    }

  }

  shortestPath(currentPos, destPos) {
    return astar(indexKey(currentPos), indexKey(destPos), this.validForMove)
  }

  audioRoomReconnectData() {
    const { twilioRoom } = this.state

    // There are two situations we're handling here:
    //
    // 1. rctogether.com goes down, but you're still connected to your audio call.
    // 2. You lose your connection to the internet (and thus get disconnected from both).
    //
    // In the first situation, if you've been talking to someone via audio chat while
    // rctogether.com was down, we want to make sure you don't get kicked off your call
    // when rctogether.com comes back online.
    //
    // In the second situation, we only want you to stay in the call if you lost your
    // internet for a very short time. Otherwise, we'll move you out of the audio room.
    if (twilioRoom?.continuouslyConnected()) {
      return {current_audio_room_id: twilioRoom.entityId(), current_audio_room_last_active_at: null}
    } else {
      return {
        current_audio_room_id: localStorage.getItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_ID),
        current_audio_room_last_active_at: localStorage.getItem(LOCAL_STORAGE_KEY_TWILIO_ROOM_LAST_ACTIVE_AT),
      }
    }
  }

  connectToActionCable() {
    this.subscription = consumer.subscriptions.create({channel: "GridWorldChannel", ...this.audioRoomReconnectData()}, {
      connected: () => {
        // intentionally blank
      },

      disconnected: () => {
        this.setState({connected: false});
        this.ui.setState({connected: false});
        this.uiGestureRecognizer.enabled = false;
        this.audioRoomDragRecognizer.enabled = false;
        this.viewportDragRecognizer.enabled = false;

        // HACK: If we lost the WebSocket connection while you were in an audio room,
        // we want the server to not move you out of that audio room when you reconnect.
        // To do this, every time we reconnect we want to send the id of the audio room
        // that you were in to the server. We send this up using Action Cable params on
        // initial connection, but Action Cable doesn't provide an API for changing these
        // when you reconnect. We work around that by reaching into Action Cable internals
        // and updating the subscription identifier (which contains the channel name
        // and params) while our subscription is disconnected.
        const identifier = JSON.parse(this.subscription.identifier)
        this.subscription.identifier = JSON.stringify({...identifier, ...this.audioRoomReconnectData()})
      },

      updatePresence(timestamp) {
        const presence = timestamp.isAfter(moment().subtract(2, 'minutes')) ? "present" : "idle"

        this.perform("update_presence", {presence})
      },

      updateRealtimeData(pos, direction, viewportOrigin, muted, sequenceNumber) {
        this.perform("update_realtime_data", {pos, direction, viewportOrigin, muted, sequenceNumber})
      },

      moveToDesk() {
        this.perform("move_to_desk")
      },

      moveNearZoomLink() {
        this.perform("move_near_zoom_link")
      },

      createAudioRoom(rect) {
        this.perform("create_audio_room", {
          x: rect.minX,
          y: rect.minY,
          width: rect.width,
          height: rect.height
        });
      },

      requestTwilioToken(room) {
        this.perform("request_twilio_token", {roomId: room.id});
      },

      toggleBlock(pos, color, options) {
        const delete_non_walls = options && options.deleteNonWalls;

        this.perform("toggle_block", {pos, color, delete_non_walls});
      },

      setColor(pos, color) {
        this.perform("set_color", {pos, color});
      },

      selectBlockType(pos, type, selectedColor) {
        this.perform('select_block_type', {pos, type, selectedColor})
      },

      getEntityWithAllProperties(id) {
        this.perform('get_entity_with_all_properties', {id})
      },

      pong() {
        this.perform('pong')
      },

      received: (event) => {
        switch (event.type) {
        case 'entity':
          this.updateEntity(event.payload);
          break;
        case 'world':
          this.updateWorld(event.payload);
          break;
        case 'twilio_token':
          this.connectTwilioRoom(event.payload);
          break;
        case 'show_reload_notice':
          this.ui.showReloadNotice()
          break
        case 'ping':
          this.subscription.pong()
          break
        }
      },
    });

    this.deactivationsSubscription = consumer.subscriptions.create("DeactivationsChannel", {
      received(data) {
        window.location.reload()
      },
    })

    this.mediaPlayersSubscription = consumer.subscriptions.create("MediaPlayersChannel", {
      received: (event) => {
        switch(event.type) {
        case 'index':
          this.setState({
            mediaPlayerIndex: _.indexBy(event.payload, 'audio_room_id')
          })
          break
        case 'save':
          this.setState({
            mediaPlayerIndex: {
              ...this.state.mediaPlayerIndex,
              [event.payload.audio_room_id]: event.payload,
            }
          })
          break
        case 'destroy':
          this.setState({
            mediaPlayerIndex: _.omit(this.state.mediaPlayerIndex, event.payload.audio_room_id)
          })
          break
        }
      }
    })

    this.screenSharesSubscription = consumer.subscriptions.create("ScreenSharesChannel", {
      received: (event) => {
        switch(event.type) {
          case 'index':
            this.setState({
              screenShareIndex: _.indexBy(event.payload, 'audio_room_id')
            })
            break
          case 'save':
            this.setState({
              screenShareIndex: {
                ...this.state.screenShareIndex,
                [event.payload.audio_room_id]: event.payload,
              }
            })
            break
          case 'destroy':
            this.setState({
              screenShareIndex: _.omit(this.state.screenShareIndex, event.payload.audio_room_id)
            })
            break
          }
      }
    })

    this.sharedTimersSubscription = consumer.subscriptions.create("SharedTimersChannel", {
      received: (event) => {
        switch(event.type) {
          case 'index':
            this.setState({
              sharedTimerIndex: _.indexBy(event.payload, 'audio_room_id')
            })
            break
          case 'save':
            this.setState({
              sharedTimerIndex: {
                ...this.state.sharedTimerIndex,
                [event.payload.audio_room_id]: event.payload,
              }
            })
            break
          case 'destroy':
            this.setState({
              sharedTimerIndex: _.omit(this.state.sharedTimerIndex, event.payload.audio_room_id)
            })
            break
          }
      }
    })
  }

  toggleBlock(color, options) {
    this.subscription.toggleBlock(this.posFacing(), color, options)
  }

  toggleAnyBlock(pos, color, options) {
    this.subscription.toggleBlock(pos, color, options)
  }

  selectBlockType = (type, options={}) => {
    const { selectedColor } = this.state
    const { showPicker } = options

    const pos = this.posFacing()
    const block = this.blockAt(pos)

    if (!block) { return }

    if (block.type === 'Wall') {
      this.setState({selectedColor: block.color})
    }

    const requestTypeChange = () => {
      if (showPicker) {
        this.shouldShowBlockTypePickerOnNextTypeChangeForBlock = block
      }

      this.subscription.selectBlockType(pos, type, selectedColor)
    }

    // if this isn't your desk, we show you a dialog rather than letting you change it's type
    if (block.type === "Desk" && block.owner) {
      if (block.owner.id !== this.avatar_id) {
        // This is a bit of a hack. You don't actually want to edit it, but in the current state of the
        // block, the only things you can click are "OK" or "Clean up", both of which get you out of the
        // editor. If that ever changes, we'll have to redo this
        this.ui.editBlock(block)
        this.setState({blockBeingEdited: block})

        return
      } else if (block.expires_at) { // and, implicitly, it's your desk
        this.ui.showDialog({
          message: "Are you sure you want to remove your desk?",
          confirmText: "Remove",
          cancelText: "Cancel",
          onConfirm: requestTypeChange,
        })

        return
      }
    }

    requestTypeChange()
  }

  addEntitiesToImageCache(entities) {
    for (const entitiesAtPos of _.values(entities)) {
      for (const e of entitiesAtPos) {
        if (e.image_path) {
          this.imageCache.load(e.image_path, () => this.dirty = true);
        }
      }
    }
  }

  makeJiggles(entities) {
    let jiggles = {};

    for (const entitiesAtPos of _.values(entities)) {
      for (const e of entitiesAtPos) {
        if (shouldJiggle(e)) {
          this.makeJiggle(jiggles, e);
        }
      }
    }

    return jiggles;
  }

  makeJiggle(jiggles, entity) {
    jiggles[entity.id] = {
      offsetX: 0,
      offsetY: 0,
      vx: SPEED * (Math.random() > 0.5 ? 1 : -1),
      vy: SPEED * (Math.random() > 0.5 ? 1 : -1),
    }
  }

  getEntity = (id) => {
    const entity = _.findWhere(this.state.entities[this.idToPosIndex[id]], {id})

    if (entity && this.isVisible(entity)) {
      return entity
    }
  }

  displayMessageIfNecessary(oldAgent, agent) {
    if (!agent.message) {
      return
    }

    const prevMessage = oldAgent && oldAgent.message
    const messageHasChanged = !_.isEqual(prevMessage, agent.message)
    const messageIsRecent = moment(agent.message.sent_at).add(MESSAGE_TIMEOUT).isSameOrAfter(moment())

    if (messageHasChanged && messageIsRecent) {
      this.ui.displayMessageForAgent(agent)

      const isMentioned = _.any(agent.message.mentioned_entity_ids, id => id === this.avatar_id)

      if (!isMentioned) {
        return
      }

      playSound('grid-world-mention-sound')

      const isFocused = document.hasFocus()
      const isOnChat = window.location.pathname === "/chat"
      const senderIsInViewport = this.isInViewport(agent.pos)

      if (isFocused && isOnChat) {
        return
      }

      if (isFocused && senderIsInViewport) {
        return
      }

      sendDesktopNotification(agent.person_name, {body: formatMentionsAsPlainText(agent.message), icon: agent.image_path})
    }
  }

  updateEntity(entity) {
    const { connected, entities } = this.state;

    // We're going to get a notification that we joined before we
    // get the rest of the world streamed to us. Just wait for the
    // rest of the world.
    if (!connected) {
      return
    }

    const { avatar_id } = this;

    const oldEntity = this.getEntity(entity.id)

    // if this is an update about us, and it's realtime data, and its
    // out of date (the sequence number is older than our local sequence number),
    // we "ignore it". Except not really because we need to keep track of the server's
    // understanding of our position (which is tracked in `server_pos`) to make connecting to
    // audio rooms work. This is because if we request a token to connect to an audio room as soon
    // as we predict ourselves moving into it on the client, the server won't give it to us because
    // it doesn't see us as being there yet. So we actually need to also call connectToAudioRoom here also,
    // once the server has had a chance to "catch up"
    if (entity.id === avatar_id && entity.sequence_number && entity.sequence_number <= this.sequenceNumber) {
      let avatar = {...oldEntity, server_pos: entity.server_pos}
      let key = this.idToPosIndex[avatar.id]
      this.setState({
        entities: {
          ...entities,
          [key]: addOrUpdateEntity(entities[key], avatar)
        },
      })
      this.connectToAudioRoom()
      return
    }

    // It is possible for someone to be online on GridWorld but not in our
    // this.state.entities (we don't understand why this happens). This is
    // a problem for avatars because of the real time/non-real time data
    // split: When an avatar first becomes visible, we send all the avatar's
    // data, both real time and non-real time, but all subsequent updates only
    // contain one set of properties or the other. If you miss the update where
    // an avatar becomes visible, later updates would either silently fail to
    // draw (when you're missing non-real time data which contains the profile
    // pic), or log an error in the console (when you're missing real time data,
    // which contains x and y).
    //
    // When we receive partial avatar data, and don't have the full avatar in
    // this.state.entities to merge it with, we stop processing the entity update
    // and request the full avatar from the server.
    if (!oldEntity && entity.type === "Avatar" && !entity.has_all_properties) {
      console.log("New avatar without all properties", entity)

      this.subscription.getEntityWithAllProperties(entity.id)

      return
    }

    const newEntityBeforeMerge = entity

    // this is necessary because avatar updates, unlike other
    // entities, are split into two, updates on "realtime" properties
    // (which the client predicts and the server "catches up" on)
    // and "non-realtime" properties, about which the server is totally authoritative.
    // So when we get an update about an avatar, it's only about *some* of it's properties,
    // not all of them, and so we need to merge what we're hearing about in the update with what we
    // already know
    if (entity.type === "Avatar") {
      entity = {...oldEntity, ...entity}
    }

    if (entity.pos === null || entity.pos === undefined) {
      console.log(`${moment()} Entity does not have pos`)
      console.log("Old entity", oldEntity)
      console.log("New entity before merge", newEntityBeforeMerge)
      console.log("Entity after merge", entity)
    }

    const oldKey = this.idToPosIndex[entity.id];
    const newKey = indexKey(entity.pos);

    this.idToPosIndex[entity.id] = newKey;

    if (entity.id === avatar_id) {
      const oldViewportOrigin = this.state.viewportOrigin;
      const newViewportOrigin = entity.viewport_origin;

      // If the viewport moves, hide the tooltip that was made
      // visible by the mouse if it exists. I tried setting a
      // new mousePos instead, but react was rendering the
      // the tooltip in the wrong place and it didn't seem worth it.
      if (!_.isEqual(oldViewportOrigin, newViewportOrigin)) {
        this.setMousePos(null);
      }

      this.setState({
        entities: {
          ...entities,
          [oldKey]: _.reject(entities[oldKey], e => e.id === entity.id),
          [newKey]: addOrUpdateEntity(entities[newKey], entity),
        },
        viewportOrigin: this.viewportDragRecognizer.dragging ? this.state.viewportOrigin : entity.viewport_origin,
        posFacing: this.findPosFacing(entity),
      });

      this.makeJiggle(this.state.jiggles, entity);
      this.handleMutedChange(entity)
      this.connectToAudioRoom();

      // This handles the case that the server has moved us without our input,
      // e.g. if it's moving us near a Zoom room. When it does this, it bumps the sequence
      // number by 25 to ensure that the client won't ignore the update.
      // When we get one of these updates with a larger sequence number than
      // this.seqenceNumber, we need to update this.sequenceNumber, since if we
      // don't, the next 25 moves the user makes will be ignored by the server
      if (entity.sequence_number > this.sequenceNumber) {
        this.sequenceNumber = entity.sequence_number
      }
    } else if (!entity.deleted) {
      let audioRoomIds = this.state.audioRoomIds;
      if (entity.type === 'AudioRoom') {
        audioRoomIds = _.uniq([...audioRoomIds, entity.id]);
      }

      let zoomLinkIds = this.state.zoomLinkIds
      if (entity.type === 'ZoomLink') {
        zoomLinkIds = _.uniq([...zoomLinkIds, entity.id]);
      }

      this.setState({
        entities: {
          ...entities,
          [oldKey]: _.reject(entities[oldKey], e => e.id === entity.id),
          [newKey]: addOrUpdateEntity(entities[newKey], entity),
        },
        audioRoomIds,
        zoomLinkIds,
      });

      if (entity.type === 'AudioRoom') {
        this.connectToAudioRoom();
      }

      if (entity.type === 'AudioRoom' && entity.expires_at && !this.audioRoomTimerId) {
        this.audioRoomTimerId = setTimeout(this.redrawAudioRooms, 1000);
      }

      if (shouldJiggle(entity)) {
        this.makeJiggle(this.state.jiggles, entity);
      }
    } else {
      let audioRoomIds = this.state.audioRoomIds
      if (entity.type === 'AudioRoom') {
        audioRoomIds = _.without(audioRoomIds, entity.id);
      }

      let zoomLinkIds = this.state.zoomLinkIds
      if (entity.type === 'ZoomLink') {
        zoomLinkIds = _.without(zoomLinkIds, entity.id);
      }

      this.setState({
        entities: {
          ...entities,
          [oldKey]: _.reject(entities[oldKey], e => e.id === entity.id),
        },
        audioRoomIds,
        zoomLinkIds,
      });
    }

    if (entity.image_path) {
      this.imageCache.load(entity.image_path, () => this.dirty = true);
    }

    const movedOrChangedDirection = oldEntity && (manhattanDistance(oldEntity.pos, entity.pos) > 0 || !_.isEqual(oldEntity.direction, entity.direction))

    // All React state changes should happen after startAnimation. Added at some point later: WHY???
    if (isAgent(entity) && oldEntity && this.isVisible(entity) && manhattanDistance(oldEntity.pos, entity.pos) > 1) {
      this.startAnimation(oldEntity, entity)
    } else if (entity.id === avatar_id && this.startEditingBlockAfterMove && movedOrChangedDirection) {
      this.editBlockAt(this.posFacing())
      this.startEditingBlockAfterMove = false
    }

    if (isAgent(entity) && this.isVisible(entity)) {
      this.displayMessageIfNecessary(oldEntity, entity)
    }

    if (entity.id === avatar_id) {
      this.ui.posFacingChanged(this.posFacing());
    }

    if (this.isVisible(entity) && this.shouldShowBlockTypePickerOnNextTypeChangeForBlock?.id === entity.id && entity.type !== oldEntity.type) {
      this.ui.showBlockTypePicker(entity)
      this.shouldShowBlockTypePickerOnNextTypeChangeForBlock = null
    }

    if (entity.id === this.desk_id && entity.owner?.id !== this.avatar_id) {
      this.desk_id = null
    } else if (entity.type === "Desk" && entity.owner?.id === this.avatar_id) {
      this.desk_id = entity.id
    }
  }

  startAnimation(oldEntity, newEntity) {
    const existingAnimation = this.animations[newEntity.id]

    let oldPos
    if (existingAnimation) {
      oldPos = existingAnimation.currentPos
      existingAnimation.cancel()
    } else {
      oldPos = oldEntity.pos
    }

    this.animations[newEntity.id] = new Animation(this, newEntity, oldPos, newEntity.pos)
    this.animations[newEntity.id].addEventListener('complete', () => { this.connectToAudioRoom() })

    if (newEntity.id === this.avatar_id) {
      this.animations[newEntity.id].addEventListener('step', (animation) => {
        // If you start dragging the viewport during an animation, the
        // animation should stop controlling your viewport. If we didn't
        // do this, the viewport would jump in annoying ways.
        if (this.lastViewportMoveStartedAt && this.lastViewportMoveStartedAt.isAfter(animation.startedAt)) {
          return
        }

        const viewport = this.viewport()

        // When we're outside the viewport, but the viewport contains our destination,
        // don't move the viewport. We'll just wait till we enter.
        if (!viewport.contains(animation.previousPos) && viewport.contains(newEntity.pos)) {
          return
        }

        this.setState({
          viewportOrigin: this.viewportOriginForMove(animation.previousPos, animation.currentPos),
        })
      })

      this.animations[newEntity.id].addEventListener('complete', () => {
        // Update the viewport origin on the server once we've finished animating
        this.updateRealtimeData(newEntity.pos, newEntity.direction, this.state.viewportOrigin, newEntity.muted)

        if (this.startEditingBlockAfterMove) {
          this.editBlockAt(this.posFacing())
          this.startEditingBlockAfterMove = false
        }
      })
    }

    this.animations[newEntity.id].start()
  }

  updateWorld(world) {
    this.idToPosIndex = createPosIndex(world.entities);
    this.animatingIdToPosIndex = createPosIndex({})

    for (let animation of _.values(this.animations)) {
      animation.cancel()
    }

    this.animations = {}

    this.setState({
      entities: world.entities,
      animatingEntities: {},
      connected: true,
      viewportOrigin: world.viewport_origin,
      jiggles: this.makeJiggles(world.entities),
      audioRoomIds: world.audio_room_ids,
      zoomLinkIds: world.zoom_link_ids,
      posFacing: this.findPosFacing(this.findOurAvatar(world.entities)),
    });

    this.addEntitiesToImageCache(world.entities);
    this.ui.setState({
      posFacing: this.posFacing(),
      connected: true,
      hasConnectedOnce: true,
    });
    this.uiGestureRecognizer.enabled = true;
    this.audioRoomDragRecognizer.enabled = true;
    this.viewportDragRecognizer.enabled = true;

    this.sequenceNumber = world.sequence_number
    this.clientClockSkew = moment().diff(moment(world.server_time), 'seconds')

    this.ui.setState({clientClockSkew: this.clientClockSkew})

    if (this.anyExpiringAudioRooms() && !this.audioRoomTimerId) {
      this.audioRoomTimerId = setTimeout(this.redrawAudioRooms, 1000);
    }

    // The disconnect counter is used to make sure that during rctogether.com
    // downtime, if we were continuously connected to an audio call, we don't
    // get kicked out of the call when rctogether.com comes back online.
    //
    // After reconnecting to rctogether.com, and after deciding whether you
    // were continuously connected to your audio call, we reset the disconnect
    // counter. This is to ensure that if rctogether.com goes down at some point
    // *after* you lost and regained your internet connection, you would not get
    // kicked out of your audio room after rctogether.com comes back online.
    this.state.twilioRoom?.resetDisconnectCounter()
    this.handleMutedChange(this.ourAvatar())
    this.connectToAudioRoom()

    this.handleSpotlightParams()

    this.receivedInitialWorld = true
  }

  // Spotlight a specific position if specified in query params, e.g.:
  // https://recurse.rctogether.com/chat?x=3&y=10
  handleSpotlightParams() {
    if (this.receivedInitialWorld) {
      return
    }

    const params = parseQueryParams(window.location.search)

    if (params.x && params.y) {
      const x = parseInt(params.x, 10);
      const y = parseInt(params.y, 10);
      if (!isNaN(x) && !isNaN(y)) {
        this.spotlightPos({x, y});
      }
    }
  }

  redrawAudioRooms = () => {
    this.dirty = true;

    if (this.anyExpiringAudioRooms()) {
      this.audioRoomTimerId = setTimeout(this.redrawAudioRooms, 1000);
    } else {
      this.audioRoomTimerId = null;
    }
  }

  updateJiggles() {
    const { jiggles } = this.state;

    let clone = null;
    for (let id in jiggles) {
      if (Math.random() < 0.001) {
        clone = clone || {...jiggles};
        clone[id] = jiggle(jiggles[id]);
      }
    }

    if (clone) {
      this.setState({jiggles: clone});
    }
  }

  setState(obj) {
    this.state = {...this.state, ...obj};
    this.dirty = true;

    if (_.has(obj, 'viewportWidth') || _.has(obj, 'viewportHeight')) {
      this.ui.setState({
        viewport: {
          width: this.state.viewportWidth,
          height: this.state.viewportHeight
        }
      })
    }
  }

  setNeedsRedraw = () => {
    this.dirty = true
  }

  update() {
    this.updateJiggles();
  }

  drawGrid() {
    const viewport = this.viewport();
    const { worldRows, worldCols } = this;

    this.ctx.save();

    this.ctx.strokeStyle = COLORS['dark-gray'];
    this.ctx.lineWidth = 1;

    const yStart = Math.max(0, -1*viewport.minY);
    const yEnd = Math.min(viewport.height, viewport.height - (viewport.maxY - worldRows));
    const xStart = Math.max(0, -1*viewport.minX);
    const xEnd = Math.min(viewport.width, viewport.width - (viewport.maxX - worldCols));

    _.range(yStart, yEnd+1).map((i) => {
      this.ctx.beginPath();
      this.ctx.moveTo(xStart*WIDTH, i*HEIGHT);
      this.ctx.lineTo(xEnd*WIDTH, i*HEIGHT);
      this.ctx.stroke();
    });

    _.range(xStart, xEnd+1).map((i) => {
      this.ctx.beginPath();
      this.ctx.moveTo(i*WIDTH, yStart*HEIGHT);
      this.ctx.lineTo(i*WIDTH, yEnd*HEIGHT);
      this.ctx.stroke();
    });

    this.ctx.restore();
  }

  viewportToWorld(pos) {
    const { viewportOrigin } = this.state;

    return {
      x: viewportOrigin.x + pos.x,
      y: viewportOrigin.y + pos.y,
    };
  }

  worldToViewport = (pos) => {
    const { viewportOrigin } = this.state;

    return {
      x: pos.x - viewportOrigin.x,
      y: pos.y - viewportOrigin.y,
    };
  }

  drawBlock(block) {
    this.ctx.save();

    const { pos } = block;
    const color = this.colorForBlock(block)

    const viewportPos = this.worldToViewport(pos);

    const x = viewportPos.x * WIDTH;
    const y = viewportPos.y * HEIGHT;

    this.ctx.fillStyle = COLORS[color];
    this.ctx.fillRect(x, y, WIDTH, HEIGHT);

    this.ctx.restore();
  }

  drawWall = (wall) => {
    const { blockBeingEdited } = this.state;

    this.drawBlock(wall);

    if (_.isEmpty(wall.wall_text)) {
      return;
    }

    // If we're editing a wall's text, the text is rendered
    // by an <input> tag, so we don't render it here.
    if (blockBeingEdited && blockBeingEdited.id === wall.id) {
      return;
    }

    this.ctx.save();

    const viewportPos = this.worldToViewport(wall.pos);

    const x = viewportPos.x * WIDTH;
    const y = viewportPos.y * HEIGHT;

    this.ctx.font = '20px Arial, sans-serif';
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = COLORS['off-white'];
    this.ctx.fillText(wall.wall_text, x + Math.floor(WIDTH/2), y+20)

    this.ctx.restore();
  }

  drawZoomLinkBadge = (zoomLink) => {
    this.ctx.save()

    const participantCount = zoomLink.participant_count
    const { pos: worldPos } = zoomLink
    const pos = this.worldToViewport(worldPos)

    // we add WIDTH to x to make it drawn in the upper right corner
    const x = (pos.x * WIDTH) + WIDTH
    const y = pos.y * HEIGHT

    this.ctx.beginPath()

    this.ctx.lineCap = 'round'
    this.ctx.strokeStyle = COLORS['light-green']
    this.ctx.lineWidth = 15

    // three-digit numbers are wide enough that we need to draw a pill rather than a circle
    let pad;
    if (participantCount >= 100) {
      pad = 3
    } else {
      pad = 0
    }

    this.ctx.moveTo(x-pad, y)
    this.ctx.lineTo(x+pad, y)

    this.ctx.stroke()

    this.ctx.font = 'bold 9px Arial, sans-serif'
    this.ctx.textAlign = 'center'
    this.ctx.fillStyle = COLORS['off-white']
    this.ctx.fillText(participantCount.toString(), x, y+3)

    this.ctx.restore()
  }

  drawZoomLink = (zoomLink) => {
    this.drawBlock(zoomLink);

    if (!this.state.fontLoaded) {
      return;
    }

    this.ctx.save();

    const { pos: worldPos } = zoomLink;
    const pos = this.worldToViewport(worldPos);

    const x = pos.x * WIDTH;
    const y = pos.y * HEIGHT;

    if (zoomLink.zoom_user) {
      this.ctx.fillStyle = COLORS['off-white'];
    } else {
      this.ctx.fillStyle = COLORS['light-gray'];
    }
    this.ctx.font = ICONS['video'].font
    this.ctx.fillText(ICONS['video'].codepoint, x+5, y+19);

    this.ctx.restore();
  }

  drawPhotoBlock = (photoBlock) => {
    this.drawBlock(photoBlock);

    if (!this.state.fontLoaded) {
      return;
    }

    this.ctx.save();

    const { pos: worldPos } = photoBlock;
    const pos = this.worldToViewport(worldPos);

    const x = pos.x * WIDTH;
    const y = pos.y * HEIGHT;

    if (photoBlock.photo_attached) {
      this.ctx.fillStyle = COLORS['purple'];
    } else {
      this.ctx.fillStyle = this.shouldHighlight(photoBlock) ? COLORS['dark-gray'] : COLORS['gray'];
    }
    this.ctx.font = ICONS['camera-retro'].font
    this.ctx.fillText(ICONS['camera-retro'].codepoint, x+6, y+19);

    this.ctx.restore();
  }

  drawNote = (note) => {
    this.drawBlock(note);

    if (!this.state.fontLoaded) {
      return;
    }

    this.ctx.save();

    const { pos: worldPos } = note;
    const pos = this.worldToViewport(worldPos);

    const x = pos.x * WIDTH;
    const y = pos.y * HEIGHT;

    if (note.note_text) {
      this.ctx.fillStyle = COLORS['light-yellow'];
    } else {
      this.ctx.fillStyle = COLORS['light-gray'];
    }
    this.ctx.font = ICONS['sticky-note'].font
    this.ctx.fillText(ICONS['sticky-note'].codepoint, x+7, y+19);

    this.ctx.restore();
  }

  drawLink = (link) => {
    this.drawBlock(link);

    if (!this.state.fontLoaded) {
      return;
    }

    this.ctx.save();

    const { pos: worldPos } = link;
    const pos = this.worldToViewport(worldPos);

    const x = pos.x * WIDTH;
    const y = pos.y * HEIGHT;

    if (link.url) {
      this.ctx.fillStyle = COLORS['light-green'];
    } else {
      this.ctx.fillStyle = COLORS['light-gray'];
    }
    this.ctx.font = ICONS['external-link'].font
    this.ctx.fillText(ICONS['external-link'].codepoint, x+6, y+19);

    this.ctx.restore();
  }

  drawCalendar = (calendar) => {
    this.drawBlock(calendar);

    this.ctx.save();

    const viewportPos = this.worldToViewport(calendar.pos);

    const x = viewportPos.x * WIDTH;
    const y = viewportPos.y * HEIGHT;

    if (!calendar.zoom_user) {
      this.ctx.fillStyle = this.shouldHighlight(calendar) ? COLORS['dark-gray'] : COLORS['gray'];
    } else if (calendarHasEventNow(calendar)) {
      this.ctx.fillStyle = COLORS['light-yellow'];
    } else {
      this.ctx.fillStyle = COLORS['off-white'];
    }

    this.ctx.font = ICONS['calendar'].font
    this.ctx.fillText(ICONS['calendar'].codepoint, x+7, y+19);

    this.ctx.restore();
  }

  drawDesk = (maybeExpiredDesk) => {
    const desk = deskWithoutExpiredData(maybeExpiredDesk)

    this.drawBlock(desk);

    this.ctx.save()

    const viewportPos = this.worldToViewport(desk.pos)

    const x = viewportPos.x * WIDTH
    const y = viewportPos.y * HEIGHT

    if (!desk.owner) {
      this.ctx.font = ICONS['user-cog'].font
      this.ctx.fillStyle = this.shouldHighlight(desk) ? COLORS['dark-orange'] : COLORS['orange']
      this.ctx.fillText(ICONS['user-cog'].codepoint, x+4, y+19)
      this.ctx.restore()
      return
    }

    if (desk.emoji) {
      const sheetPos = emojiSheetCoordinatesFromNative(desk.emoji)

      if (sheetPos) {
        this.ctx.drawImage(EmojiSheet, sheetPos.x*(EMOJI_SIZE+2), sheetPos.y*(EMOJI_SIZE+2), EMOJI_SIZE, EMOJI_SIZE, x+3, y+3, WIDTH-6, HEIGHT-6)
      }
    } else {
      this.ctx.font = '13px Arial, sans-serif';
      this.ctx.textAlign = 'center';
      this.ctx.fillStyle = COLORS['off-white']
      this.ctx.fillText(initials(desk.owner.name), x + Math.floor(WIDTH/2), y+19)
    }

    this.ctx.restore()
  }

  drawAudioBlock = (audioBlock) => {
    this.drawBlock(audioBlock)

    this.ctx.save()

    const viewportPos = this.worldToViewport(audioBlock.pos)

    const x = viewportPos.x * WIDTH
    const y = viewportPos.y * HEIGHT

    this.ctx.fillStyle = COLORS['off-white']

    const timer = this.state.sharedTimerIndex[audioBlock.audio_room_id]

    let secondsRemaining = 0

    if (timer) {
      const elapsedTime = moment().diff(timer.started_at, 'seconds')
      const durationInSeconds = timer.duration * 60

      if (timer.repeat) {
        const breakDurationInSeconds = timer.break_duration * 60
        const fullDuration = durationInSeconds + breakDurationInSeconds
        const elapsedTimeInCycle = elapsedTime % fullDuration
        const isInBreak = elapsedTimeInCycle >= durationInSeconds
        secondsRemaining = Math.max(0, (isInBreak ? fullDuration : durationInSeconds) - elapsedTimeInCycle)
      } else {
        secondsRemaining = Math.max(0, durationInSeconds - elapsedTime)
      }
    }

    if (secondsRemaining) {
      this.ctx.font = '13px Arial, sans-serif'
      this.ctx.textAlign = 'center'
      this.ctx.fillText(Math.ceil(secondsRemaining/60) + "m", x + Math.floor(WIDTH/2), y+19)
    } else {
      this.ctx.font = ICONS['volume-up'].font
      this.ctx.fillText(ICONS['volume-up'].codepoint, x+4, y+20)
    }

    this.ctx.restore()
  }

  drawAudioBlockTimerEmoji = (audioBlock) => {
    const timer = this.state.sharedTimerIndex[audioBlock.audio_room_id]

    if (!timer) {
      return
    }

    const elapsedTime = moment().diff(timer.started_at, 'seconds')
    const durationInSeconds = timer.duration * 60

    if (!timer.repeat && elapsedTime >= durationInSeconds) {
      return
    }

    this.ctx.save()

    const breakDurationInSeconds = timer.repeat ? timer.break_duration*60 : 0
    const fullDuration = durationInSeconds + breakDurationInSeconds
    const elapsedTimeInCycle = elapsedTime % fullDuration
    const isInBreak = timer.repeat && elapsedTimeInCycle >= durationInSeconds

    let emoji = isInBreak ? "😴" : timer.repeat ? "🍅" : "⏲"

    const viewportPos = this.worldToViewport(audioBlock.pos)

    const x = viewportPos.x * WIDTH
    const y = viewportPos.y * HEIGHT

    this.ctx.font = '13px Arial, sans-serif'
    this.ctx.textAlign = 'center'
    this.ctx.fillText(emoji, x+WIDTH, y+6)

    this.ctx.restore()
  }

  drawUnknownAvatar = (avatar) => {
    this.ctx.save();

    const { pos: worldPos, direction } = avatar;
    const pos = this.worldToViewport(worldPos);
    const jiggle = this.state.jiggles[avatar.id];

    const x = pos.x*WIDTH + jiggle.offsetX;
    const y = pos.y*HEIGHT + jiggle.offsetY;

    this.ctx.beginPath()
    this.ctx.arc(x + WIDTH/2, y + HEIGHT/2, WIDTH/2, 0, Math.PI*2);
    this.ctx.fillStyle = COLORS['off-white']
    this.ctx.fill()

    this.ctx.fillStyle = COLORS['dark-gray']
    if (avatar.person_name && avatar.person_name.match(/\d{7,}/)) { // Probably a phone number
      this.ctx.font = ICONS['phone'].font
      this.ctx.fillText(ICONS['phone'].codepoint, x+4, y+19)
    } else {
      const label = avatar.person_name ? initials(avatar.person_name) : "?"

      this.ctx.font = '13px Arial, sans-serif'
      this.ctx.textAlign = 'center'
      this.ctx.fillText(label, x + Math.floor(WIDTH/2), y+19)
    }

    this.ctx.restore();

    if (avatar.zoom_user_display_name) {
      this.drawZoomRing(x, y);
    }
  }

  drawBot = (bot) => {
    this.ctx.save()

    const { pos, color, direction } = bot

    const viewportPos = this.worldToViewport(pos)

    const x = viewportPos.x * WIDTH
    const y = viewportPos.y * HEIGHT

    this.ctx.beginPath()

    const sheetPos = emojiSheetCoordinatesFromNative(bot.emoji)

    if (sheetPos) {
      this.ctx.drawImage(EmojiSheet, sheetPos.x*(EMOJI_SIZE+2), sheetPos.y*(EMOJI_SIZE+2), EMOJI_SIZE, EMOJI_SIZE, x+3, y+3, WIDTH-6, HEIGHT-6)
    }

    if (!this.isAnimating(bot)) {
      this.drawFacingTriangle(x, y, direction)
    }

    this.ctx.restore()
  }

  drawAvatar = (avatar) => {
    if (!this.imageCache.isLoaded(avatar.image_path)) {
      return;
    }

    this.ctx.save();

    const { pos: worldPos, direction } = avatar;
    const pos = this.worldToViewport(worldPos);
    const jiggle = this.state.jiggles[avatar.id];

    const x = pos.x*WIDTH + jiggle.offsetX;
    const y = pos.y*HEIGHT + jiggle.offsetY;

    this.ctx.beginPath();
    this.ctx.arc(x + WIDTH/2, y + HEIGHT/2, WIDTH/2, 0, Math.PI*2);
    this.ctx.clip();
    this.ctx.drawImage(this.imageCache.get(avatar.image_path), x-1, y-1, WIDTH+2, HEIGHT+2);

    this.ctx.restore();

    if (!this.isAnimating(avatar)) {
      this.drawFacingTriangle(x, y, direction);
    }

    if (avatar.zoom_user_display_name) {
      this.drawZoomRing(x, y)
    }

    const mediaPlayer = this.mediaPlayerForAvatar(avatar)
    if (mediaPlayer && mediaPlayerIsPlaying(mediaPlayer, mediaPlayer?.duration)) {
      this.drawHeadphones(x, y)
    }

    if (this.avatar_id === avatar.id) { // it's our avatar
      this.drawPresenceIndicator(x, y, 'dark-green')
    } else if (this.isAvatarActive(avatar)) {
      this.drawPresenceIndicator(x, y, 'dark-green')
    } else if (this.isAvatarIdle(avatar)) {
      this.drawPresenceIndicator(x, y, 'light-orange', {half: true})
    }

    if (avatar.muted) {
      this.drawMutedIcon(x, y)
    }
  }

  drawZoomRing(x, y) {
    this.ctx.save()

    this.ctx.strokeStyle = COLORS['blue']
    this.ctx.lineWidth = 2.5
    this.ctx.beginPath()
    this.ctx.arc(x + WIDTH/2, y + HEIGHT/2, WIDTH/2, 0, Math.PI*2);
    this.ctx.stroke()

    this.ctx.restore()
  }

  drawHeadphones(x, y) {
    this.ctx.save()

    // Setup.
    const standardLineWidth = 2.5
    const thickLineWidth = 4
    const earRadius = WIDTH/5
    const headbandRadius = WIDTH/2 + standardLineWidth
    const xCenter = x + WIDTH/2
    const yOffset = HEIGHT/8
    const yCenter = y + HEIGHT/2 - yOffset
    this.ctx.lineWidth = standardLineWidth
    this.ctx.strokeStyle = COLORS.red
    this.ctx.fillStyle = COLORS.red

    // Left ear…
    this.ctx.beginPath()
    this.ctx.arc(x, yCenter, earRadius, Math.PI/2, Math.PI*3/2)
    this.ctx.fill()
    // …connected to the headband…
    const xLeftConnector = x - standardLineWidth / 2
    this.ctx.beginPath()
    this.ctx.moveTo(xLeftConnector, yCenter - earRadius)
    this.ctx.lineTo(xLeftConnector, yCenter)
    this.ctx.lineWidth = standardLineWidth
    this.ctx.stroke()
    // …with some cushioning.
    const xLeftCushion = x + standardLineWidth
    this.ctx.beginPath()
    this.ctx.moveTo(xLeftCushion, yCenter - earRadius)
    this.ctx.lineTo(xLeftCushion, yCenter + earRadius)
    this.ctx.lineWidth = standardLineWidth
    this.ctx.stroke()

    // Right ear…
    this.ctx.beginPath()
    this.ctx.arc(x + WIDTH, yCenter, earRadius, Math.PI*3/2, Math.PI/2)
    this.ctx.fill()
    // …connected to the headband…
    const xRightConnector = x + WIDTH + standardLineWidth / 2
    this.ctx.beginPath()
    this.ctx.moveTo(xRightConnector, yCenter - earRadius)
    this.ctx.lineTo(xRightConnector, yCenter)
    this.ctx.lineWidth = standardLineWidth
    this.ctx.stroke()
    // …with some cushioning.
    const xRightCushion = x + WIDTH - standardLineWidth
    this.ctx.beginPath()
    this.ctx.moveTo(xRightCushion, yCenter - earRadius)
    this.ctx.lineTo(xRightCushion, yCenter + earRadius)
    this.ctx.lineWidth = standardLineWidth
    this.ctx.stroke()

    // Headband…
    this.ctx.beginPath()
    this.ctx.arc(xCenter, yCenter, headbandRadius, Math.PI, 0)
    this.ctx.stroke()
    // …with a cushioned top.
    this.ctx.beginPath()
    this.ctx.arc(xCenter, yCenter, headbandRadius, Math.PI*5/4, Math.PI*7/4)
    this.ctx.lineWidth = thickLineWidth
    this.ctx.stroke()

    this.ctx.restore()
  }

  drawPresenceIndicator(x, y, color, options = {}) {
    this.ctx.save();

    const r = 5;

    this.ctx.fillStyle = COLORS[color];
    this.ctx.beginPath();
    this.ctx.arc(x+3, y+3, r, 0, Math.PI*2);
    this.ctx.fill();

    if (options.half) {
      this.ctx.fillStyle = "white"
      this.ctx.beginPath()
      this.ctx.arc(x+3, y+3, r-1, 0, Math.PI, true)
      this.ctx.fill()
    }

    this.ctx.restore();
  }

  drawMutedIcon(x, y) {
    this.ctx.save()

    const r = 5;

    this.ctx.fillStyle = "#ffffff"
    this.ctx.beginPath();
    this.ctx.arc(x+21, y+24, r, 0, Math.PI*2);
    this.ctx.fill();

    this.ctx.restore()

    this.ctx.save()

    this.ctx.fillStyle = COLORS['red']
    this.ctx.font = ICONS['times-circle'].font
    this.ctx.shadowColor = "#ffffff"
    this.ctx.shadowOffsetX = -1
    this.ctx.shadowOffsetY = -1
    this.ctx.shadowBlur = 2
    this.ctx.fillText(ICONS['times-circle'].codepoint, x+15, y+29)

    this.ctx.restore()
  }

  triangle(x, y, direction) {
    const centerX = x + WIDTH/2;
    const centerY = y + HEIGHT/2;
    const radius = WIDTH/2 + 1;
    const width = 8;
    const height = 4;


    switch (direction) {
    case "right": {
      const p1 = {x: centerX + (radius+2), y: centerY - width/2};
      const p2 = {x: centerX + (radius+2), y: centerY + width/2};
      const p3 = {x: centerX + (radius+2) + height, y: centerY};
      return {p1, p2, p3};
    }
    case "left": {
      const p1 = {x: centerX - (radius+2), y: centerY - width/2};
      const p2 = {x: centerX - (radius+2), y: centerY + width/2};
      const p3 = {x: centerX - (radius+2) - height, y: centerY};
      return {p1, p2, p3};
    }
    case "up": {
      const p1 = {x: centerX - width/2, y: centerY - (radius+2)};
      const p2 = {x: centerX + width/2, y: centerY - (radius+2)};
      const p3 = {x: centerX, y: centerY  - (radius+2) - height};
      return {p1, p2, p3};
    }
    case "down": {
      const p1 = {x: centerX - width/2, y: centerY + (radius+2)};
      const p2 = {x: centerX + width/2, y: centerY + (radius+2)};
      const p3 = {x: centerX, y: centerY  + (radius+2) + height};
      return {p1, p2, p3};
    }
    }
  }

  drawFacingTriangle(x, y, direction) {
    this.ctx.save();

    const triangle = this.triangle(x, y, direction);

    this.ctx.beginPath();
    this.ctx.moveTo(triangle.p1.x, triangle.p1.y);
    this.ctx.lineTo(triangle.p2.x, triangle.p2.y);
    this.ctx.lineTo(triangle.p3.x, triangle.p3.y);
    this.ctx.closePath();

    this.ctx.fillStyle = "#000000";
    this.ctx.fill();

    this.ctx.restore();
  }

  drawAudioRoom = (audioRoom) => {
    const expiresAt = getExpiresAt(audioRoom);

    const isScreenSharing = this.screenShareForAudioRoom(audioRoom)
    const sharedTimer = this.sharedTimerForAudioRoom(audioRoom)
    const isTimerActive = sharedTimer && (
      sharedTimer.repeat ||
      moment().diff(sharedTimer.started_at, 'minutes') < sharedTimer.duration
    )

    const icon = ICONS[isScreenSharing ? 'laptop' : isTimerActive ? 'stopwatch' : 'microphone']
    const iconXOffset = isScreenSharing ? 5 : isTimerActive ? 6 : 8
    const iconYOffset = isScreenSharing ? 19 : 20

    this.ctx.save();

    const rect = makeRectFromAudioRoom(audioRoom);

    const viewportPos = this.worldToViewport(rect.origin);
    const rectInViewport = new Rect(viewportPos, rect.size);

    const x = viewportPos.x * WIDTH;
    const y = viewportPos.y * HEIGHT;
    const width = rect.width * WIDTH;
    const height = rect.height * HEIGHT;

    const drawColor = this.shouldHighlightAudioRoom(audioRoom) ? '#a8afaf' : COLORS['light-gray']

    this.ctx.strokeStyle = drawColor
    this.ctx.lineWidth = 4;
    this.ctx.setLineDash([5, 5]);
    this.ctx.strokeRect(x+2, y+2, width-4, height-4);

    let secondsLeft;
    if (expiresAt) {
      this.ctx.font = '16px monospace';
      this.ctx.fillStyle = COLORS['dark-gray'];
      secondsLeft = Math.min(Math.round(expiresAt.diff(moment())/1000+1), 5);
    } else {
      this.ctx.font = icon.font;
      this.ctx.fillStyle = drawColor
    }

    for (let c = rectInViewport.minX; c < rectInViewport.maxX; c++) {
      for (let r = rectInViewport.minY; r < rectInViewport.maxY; r++) {
        if (expiresAt) {
          this.ctx.fillText(secondsLeft, c*WIDTH + 9, r*HEIGHT + 19);
        } else {
          this.ctx.fillText(icon.codepoint, c*WIDTH + iconXOffset, r*HEIGHT + iconYOffset);
        }
      }
    }

    this.ctx.restore();
  }

  drawAudioRoomDragRect() {
    const { audioRoomDragRect } = this.state;
    const { avatar_id } = this;

    if (!audioRoomDragRect) {
      return;
    }

    this.ctx.save();

    const viewportPos = this.worldToViewport(audioRoomDragRect.origin);

    const x = viewportPos.x * WIDTH;
    const y = viewportPos.y * HEIGHT;
    const width = audioRoomDragRect.width * WIDTH;
    const height = audioRoomDragRect.height * HEIGHT;

    const isValid = audioRoomDragRect.isValid(this.visibleEntities(), avatar_id, this.audioRooms());

    this.ctx.strokeStyle = isValid ? COLORS['light-green'] : COLORS['light-pink'];
    this.ctx.lineWidth = 4;
    this.ctx.strokeRect(x, y, width, height);

    const rectInViewport = new Rect(viewportPos, audioRoomDragRect.size);

    this.ctx.fillStyle = isValid ? COLORS['light-green'] : COLORS['light-pink'];
    for (let c = rectInViewport.minX; c < rectInViewport.maxX; c++) {
      for (let r = rectInViewport.minY; r < rectInViewport.maxY; r++) {
        if (isValid) {
          this.ctx.font = ICONS['microphone'].font
          this.ctx.fillText(ICONS['microphone'].codepoint, c*WIDTH + 8, r * HEIGHT + 20);
        } else {
          this.ctx.font = ICONS['times'].font;
          this.ctx.fillText(ICONS['times'].codepoint, c*WIDTH + 7, r * HEIGHT + 19);
        }
      }
    }

    this.ctx.restore();
  }

  viewportRows() {
    const { viewportHeight } = this.state;

    return Math.ceil(viewportHeight/HEIGHT);
  }

  viewportCols() {
    const { viewportWidth } = this.state;

    return Math.ceil(viewportWidth/WIDTH);
  }

  viewportSize() {
    return {
      width: this.viewportCols(),
      height: this.viewportRows(),
    };
  }

  viewport() {
    return new Rect(this.state.viewportOrigin, this.viewportSize());
  }

  setViewportOrigin(origin) {
    const avatar = this.ourAvatar();

    this.lastViewportMoveStartedAt = moment()
    this.updateRealtimeData(avatar.pos, avatar.direction, origin, avatar.muted);
  }

  updateRealtimeData(pos, direction, viewportOrigin, muted) {
    const avatar = this.ourAvatar()

    this.sequenceNumber += 1
    this.subscription.updateRealtimeData(pos, direction, viewportOrigin, muted, this.sequenceNumber)

    this.updateEntity({
      // We need to omit the sequence number here, since updateEntity ignores calls
      // where the sequence number passed in is <= this.sequenceNumber, which in this case
      // it will be. By omiting the sequence number,
      // we're telling updateEntity "just apply this"
      ..._.omit(avatar, "sequence_number"),
      pos: pos,
      direction: direction,
      viewport_origin: viewportOrigin,
      muted: muted,
    })
  }

  mutedForMove(avatar, newPos) {
    const previousAudioRoom = this.currentAudioRoom(avatar)

    // we ignore the server because otherwise we'll never be able to detect entering an audio room, since
    // nextAudioRoom will be null when we step into it (the server won't have had time to catch up)
    const nextAudioRoom = this.currentAudioRoom({...avatar, pos: newPos}, {ignoringServer: true})

    if (previousAudioRoom?.id != nextAudioRoom?.id) {
      // if we're entering an audio room or changing audio rooms, use the mute on entry settings
      // of our new room
      return nextAudioRoom?.mute_on_entry || false
    } else if (nextAudioRoom) {
      // if we're staying in an audio room, don't change muted
      return avatar.muted
    } else { // if we're leaving an audio room, set muted to false
      return false
    }
  }

  move(direction) {
    const avatar = this.ourAvatar()

    const { viewportOrigin } = this.state
    const posFacing = this.posFacing()

    if (direction !== avatar.direction) {
      this.updateRealtimeData(avatar.pos, direction, viewportOrigin, avatar.muted)
      return
    }

    if (this.blockAt(posFacing)) {
      return
    }

    const newPos = this.clampToWorld(posFacing)
    const newMuted = this.mutedForMove(avatar, newPos)

    if (_.isEqual(newPos, avatar.pos)) {
      return
    }

    this.updateRealtimeData(newPos, avatar.direction, this.viewportOriginForMove(avatar.pos, newPos), newMuted)
  }

  moveToDesk() {
    const desk = this.ourDesk();
    if (!desk) return;

    const viewport = this.viewport();
    const avatar = this.ourAvatar();
    const willSkipAnimation = manhattanDistance(avatar.pos, desk.pos) < 3;
    if (!viewport.contains(avatar.pos) && willSkipAnimation) {
      this.setViewportOrigin(Rect.centered(desk.pos, viewport.size).origin);
    }

    this.subscription.moveToDesk()
  }

  viewportOriginForMove(oldPos, newPos) {
    const viewport = this.viewport()

    if (_.isEqual(oldPos, newPos)) {
      return viewport.origin
    }

    const safeArea = viewport.insetBy(VIEWPORT_BUFFER)

    let direction = directionFacing(newPos, {from: oldPos})

    if (viewport.contains(oldPos)) {
      if (safeArea.contains(oldPos)) {
        if (safeArea.contains(newPos)) {
          return viewport.origin
        } else {
          return this.moved(viewport.origin, direction)
        }
      } else { // Here oldPos is in the "unsafe area" - we're in the viewport, but not in the safe area
        if (safeArea.movingAwayFrom(oldPos, direction)) {
          return this.moved(viewport.origin, direction)
        } else {
          return viewport.origin
        }
      }
    } else {
      // Here pos is outside of the viewport
      return Rect.centered(newPos, viewport.size).origin
    }
  }

  // These handlers are called with `this` bound to the
  // CanvasWorld instance. They cannot be arrow functions.
  static KEYDOWN_HANDLERS = {
    Escape(e) {
      if (this.audioRoomDragRecognizer.dragging) {
        this.audioRoomDragRecognizer.cancel()
      }

      if (this.viewportDragRecognizer.dragging) {
        this.viewportDragRecognizer.cancel()
      }
    },

    ArrowUp(e) {
      if (e.shiftKey) {
        const origin = this.moved(this.state.viewportOrigin, "up")
        this.setViewportOrigin(origin);
      } else {
        this.move("up")
      }
    },

    w(e) {
      this.move("up")
    },

    W(e) {
      const origin = this.moved(this.state.viewportOrigin, "up")
      this.setViewportOrigin(origin);
    },

    ArrowDown(e) {
      if (e.shiftKey) {
        const origin = this.moved(this.state.viewportOrigin, "down")
        this.setViewportOrigin(origin);
      } else {
        this.move("down")
      }
    },

    s(e) {
      this.move("down")
    },

    S(e) {
      const origin = this.moved(this.state.viewportOrigin, "down")
      this.setViewportOrigin(origin);
    },

    ArrowLeft(e) {
      if (e.shiftKey) {
        const origin = this.moved(this.state.viewportOrigin, "left")
        this.setViewportOrigin(origin);
      } else {
        this.move("left")
      }
    },

    a(e) {
      this.move("left")
    },

    A(e) {
      const origin = this.moved(this.state.viewportOrigin, "left")
      this.setViewportOrigin(origin);
    },

    ArrowRight(e) {
      if (e.shiftKey) {
        const origin = this.moved(this.state.viewportOrigin, "right")
        this.setViewportOrigin(origin);
      } else {
        this.move("right")
      }
    },

    d(e) {
      this.move("right")
    },

    D(e) {
      const origin = this.moved(this.state.viewportOrigin, "right")
      this.setViewportOrigin(origin);
    },

    h(e) {
      this.moveToDesk();
    },

    f(e) {
      this.spotlightEntity(this.ourAvatar())
    },

    z(e) {
      this.subscription.moveNearZoomLink();
    },

    x(e) {
      const { selectedColor } = this.state

      const block = this.blockAt(this.posFacing())

      if (block?.type === 'Wall') {
        this.setState({selectedColor: block.color})
      }

      this.toggleBlock(selectedColor)
    },

    X(e) {
      const { selectedColor } = this.state

      const block = this.blockAt(this.posFacing())

      if (block?.type === 'Wall') {
        this.setState({selectedColor: block.color})
      }

      // if this isn't your desk, we show you a dialog rather than letting you change it's type
      if (block?.type === "Desk" && block?.owner) {
        if (block.owner.id !== this.avatar_id) {
          // This is a bit of a hack. You don't actually want to edit it, but in the current state of the
          // block, the only things you can click are "OK" or "Clean up", both of which get you out of the
          // editor. If that ever changes, we'll have to redo this
          this.ui.editBlock(block)
          this.setState({blockBeingEdited: block})

          return
        } else {
          this.ui.showDialog({
            message: "Are you sure you want to remove your desk?",
            confirmText: "Remove",
            cancelText: "Cancel",
            onConfirm: () => this.toggleBlock(selectedColor, {deleteNonWalls: true}),
          })

          return
        }
      }

      this.toggleBlock(selectedColor, {deleteNonWalls: true})
    },

    c(e) {
      const { selectedColor } = this.state

      const pos = this.posFacing()
      const block = this.blockAt(pos)

      if (!block || block.type !== "Wall") { return }

      const color = this.nextColor(block.color)

      this.subscription.setColor(pos, color)
      this.setState({selectedColor: color})
    },

    C(e) {
      const { selectedColor } = this.state

      const pos = this.posFacing()
      const block = this.blockAt(pos)

      if (!block || block.type !== "Wall") { return }

      const color = this.previousColor(block.color)

      this.subscription.setColor(pos, color)
      this.setState({selectedColor: color})
    },

    t(e) {
      const { selectedColor } = this.state

      const block = this.blockAt(this.posFacing())

      if (!block) { return }

      this.selectBlockType(this.nextBlockType(block.type), {showPicker: true})
    },

    T(e) {
      const { selectedColor } = this.state

      const block = this.blockAt(this.posFacing())

      if (!block) { return }

      this.selectBlockType(this.previousBlockType(block.type), {showPicker: true})
    },

    e(e) {
      this.editBlockAt(this.posFacing())

      e.preventDefault()
    },

    Enter(e) {
      this.performAction()
    },

    " ": function(e) {
      this.performAction()
    },

    "?": function(e) {
      window.open(HELP_URL, '_blank', 'noopener,noreferrer')
    },

    m(e) {
      this.toggleMuteIfInAudioRoom()
    },

    "+": function(e) {
      if (e.ctrlKey || e.metaKey) return
      this.volumeUp()
    },

    "=": function(e) {
      if (e.ctrlKey || e.metaKey) return
      this.volumeUp()
    },

    "-": function(e) {
      if (e.ctrlKey || e.metaKey) return
      this.volumeDown()
    },

    "_": function(e) {
      if (e.ctrlKey || e.metaKey) return
      this.volumeDown()
    },

    "0": function(e) {
      if (e.ctrlKey || e.metaKey) return
      this.volumeZero()
    },
  }

  volumeUp() {
    const { twilioRoom } = this.state

    if (twilioRoom) {
      const volume = Math.min(1, twilioRoom.volume + 0.0625)
      twilioRoom.setVolume(volume)
      this.ui.setState({
        volume,
        volumeSetAt: Date.now(),
      })
    }
  }

  volumeDown() {
    const { twilioRoom } = this.state

    if (twilioRoom) {
      const volume = Math.max(0, twilioRoom.volume - 0.0625)
      twilioRoom.setVolume(volume)
      this.ui.setState({
        volume,
        volumeSetAt: Date.now(),
      })
    }
  }

  volumeZero() {
    const { twilioRoom } = this.state

    if (twilioRoom) {
      twilioRoom.setVolume(0)
      this.ui.setState({
        volume: 0,
        volumeSetAt: Date.now(),
      })
    }
  }

  setUserHasInteracted = (e) => {
    this.userHasInteracted = true
  }

  getUserHasInteracted = () => {
    return this.userHasInteracted
  }

  updateCursor(e) {
    if (eventMatchesModifiers(e, ["Shift"])) {
      this.canvas.style.cursor = 'cell'
    } else {
      this.canvas.style.cursor = null
    }
  }

  handleKeyDown = (e) => {
    const { connected } = this.state;

    if (!connected) {
      return;
    }

    if (this.ui.shouldSuppressInput()) {
      return;
    }

    this.updateCursor(e)

    const handler = CanvasWorld.KEYDOWN_HANDLERS[e.key]

    if (handler) {
      handler.call(this, e)
    }
  }

  handleKeyUp = (e) => {
    if (this.ui.isEditingBlock()) {
      return;
    }

    this.updateCursor(e)
  }

  handleResize = (e) => {
    this.setupCanvas(this.canvas, this.ctx, this.canvas.offsetWidth, this.canvas.offsetHeight)
    this.setupCanvas(this.spotlightCanvas, this.spotlightCtx, window.innerWidth, window.innerHeight)

    this.setState({viewportWidth: this.canvas.offsetWidth, viewportHeight: this.canvas.offsetHeight})
  }

  nextColor(color) {
    const { colors } = this;

    const i = _.indexOf(colors, color);

    return colors[(i+1) % colors.length];
  }

  previousColor(color) {
    const { colors } = this;

    const i = _.indexOf(colors, color);

    // -1 % 5 == -1 in JavaScript
    const n = colors.length;
    const newI = (((i-1) % n) + n) % n;

    return colors[newI];
  }

  blockTypesForPosFacing = () => {
    const { block_types } = this
    const audioRoom = _.find(this.audioRooms(), r => makeRectFromAudioRoom(r).contains(this.posFacing()))
    if (audioRoom?.has_audio_block || !audioRoom) {
      return _.without(block_types, "AudioBlock")
    } else {
      return block_types
    }
  }

  nextBlockType(type) {
    const blockTypes = this.blockTypesForPosFacing()

    const i = _.indexOf(blockTypes, type)

    return blockTypes[betterModulo(i+1, blockTypes.length)]
  }

  previousBlockType(type) {
    const blockTypes = this.blockTypesForPosFacing()

    const i = _.indexOf(blockTypes, type)

    return blockTypes[betterModulo(i-1, blockTypes.length)]
  }

  performAction() {
    if (this.ui.shouldSuppressSpaceAndEnter()) {
      return
    }

    const pos = this.posFacing()
    const avatar = this.ourAvatar()

    if (!_.isEqual(this.ui.state.selectedPos, pos)) {
      this.ui.setState({selectedPos: pos, overlayPlacement: DIRECTION_TO_PLACEMENT[avatar.direction]})
    }
  }

  isAvatarActive(avatar) {
    return moment(avatar.last_present_at).isAfter(moment().subtract(this.clientClockSkew, 'seconds').subtract(this.avatarOfflineTimeout))
  }

  isAvatarIdle(avatar) {
    return moment(avatar.last_idle_at).isAfter(moment().subtract(this.clientClockSkew, 'seconds').subtract(this.avatarOfflineTimeout))
  }

  isAvatarActiveOrIdle(avatar) {
    return this.isAvatarActive(avatar) || this.isAvatarIdle(avatar)
  }

  isVisible = (entity) => {
    if (entity.id === this.avatar_id) {
      return true
    } else if (entity.deleted) {
      return false
    } else if (entity.type === "Avatar") {
      return !!entity.zoom_user_display_name || this.isAvatarActiveOrIdle(entity)
    } else if (entity.type === "UnknownAvatar"){
      return !!entity.zoom_user_display_name
    } else if (entity.type === "AudioRoom") {
      return !isExpired(getExpiresAt(entity))
    } else {
      return true
    }
  }

  isMentionable = (entity) => {
    if (entity.id === this.avatar_id) {
      return true
    } else if (entity.type === "Avatar") {
      return this.isAvatarActiveOrIdle(entity)
    } else if (entity.type === "Bot") {
      return entity.can_be_mentioned
    } else {
      return false
    }
  }

  visibleEntities() {
    return transformValues(this.state.entities, entities => _.filter(entities, this.isVisible))
  }

  onlineAvatarsWithDeskKey = (otherAvatars) => {
    const { avatars, desksByAvatarId } = _.flatten(_.values(this.state.entities)).reduce((acc, entity) => {
      if (entity.type === "Avatar" && (this.isAvatarActiveOrIdle(entity) || entity.zoom_user_id)) {
        acc.avatars.push(entity)
      } else if (entity.type === "Desk" && entity.owner) {
        acc.desksByAvatarId[entity.owner.id] = entity
      }
      return acc
    }, { avatars: [], desksByAvatarId: {} })

    otherAvatars = otherAvatars.filter((a) => !_.contains(_.pluck(avatars, 'id'), a.id))

    return avatars.concat(otherAvatars).map((avatar) => ({
      ...avatar,
      desk: desksByAvatarId[avatar.id],
      is_active: this.isAvatarActive(avatar),
      is_idle: this.isAvatarIdle(avatar),
    }))
  }

  mentionableEntities = (options) => {
    const audioRoomId = options?.audioRoomId
    const audioRoom = audioRoomId && this.getEntity(audioRoomId)

    let entities = _.filter(_.flatten(_.values(this.state.entities)), this.isMentionable)

    if (audioRoom) {
      const rect = makeRectFromAudioRoom(audioRoom)
      entities = entities.map(e => ({
        ...e,
        isInOurAudioRoom: rect.contains(e.pos),
      }))
    }

    return entities.map((e) => {
      if (e.type === "Avatar") {
        return {...e, is_active: this.isAvatarActive(e), is_idle: this.isAvatarIdle(e)}
      } else {
        return e
      }
    })
  }

  allEntitiesAt(pos) {
    const { entities } = this.state;

    return entities[indexKey(pos)]
  }

  entitiesAt(pos) {
    return _.filter(this.allEntitiesAt(pos), e => this.isVisible(e))
  }

  animatingEntitiesAt(pos) {
    const { animatingEntities } = this.state;

    return _.filter(animatingEntities[indexKey(pos)], e => this.isVisible(e))
  }

  stationaryEntitiesAt = (pos) => {
    return _.reject(this.entitiesAt(pos), e => this.isAnimating(e))
  }

  isAnimating = (entity) => {
    return _.has(this.animatingIdToPosIndex, entity.id)
  }

  getAnimatingEntityForEntity(entity) {
    const { animatingEntities } = this.state;

    const key = this.animatingIdToPosIndex[entity.id]

    if (!key) {
      return null
    }

    return _.findWhere(animatingEntities[key], {id: entity.id})
  }

  blockAt = (pos) => {
    const { block_types } = this;

    return _.find(this.entitiesAt(pos), e => _.contains(block_types, e.type));
  }

  editBlockAt(pos) {
    const block = this.blockAt(pos)

    if (block && isEditable(block)) {
      this.ui.editBlock(block)
      this.setState({blockBeingEdited: block})
    }
  }

  validForMove = (pos) => {
    const withinXbounds = pos.x >= 0 && pos.x < this.worldCols
    const withinYbounds = pos.y >= 0 && pos.y < this.worldRows
    return !this.blockAt(pos) && withinXbounds && withinYbounds
  }

  botsAt(pos) {
    const stationaryBots = _.select(this.entitiesAt(pos), e => e.type === "Bot" && !this.isAnimating(e))

    const animatingBots = _.select(this.animatingEntitiesAt(pos), e => e.type === "Bot")

    return [...stationaryBots, ...animatingBots]
  }

  avatarsAndUnknownAvatarsAt(pos) {
    const stationaryAvatars = _.select(this.entitiesAt(pos), e => _.contains(['Avatar', 'UnknownAvatar'], e.type) && !this.isAnimating(e))

    const animatingAvatars = _.select(this.animatingEntitiesAt(pos), e => _.contains(['Avatar'], e.type))

    return [...stationaryAvatars, ...animatingAvatars]
  }

  toggleMuteIfInAudioRoom = () => {
    const { twilioRoom, viewportOrigin } = this.state
    const avatar = this.ourAvatar()

    if (!twilioRoom || !avatar) {
      return
    }

    this.updateRealtimeData(avatar.pos, avatar.direction, viewportOrigin, !avatar.muted)
  }

  handleMutedChange(entity) {
    const { twilioRoom } = this.state;

    this.ui.setState({micMuted: entity.muted})

    if (!twilioRoom) {
      return
    }

    if (entity.muted) {
      twilioRoom.mute()
    } else {
      twilioRoom.unmute()
    }
  }

  currentAudioRoom = (avatar, options={}) => {
    if (!avatar) {
      return null
    }

    let room
    let animation = this.animations[avatar.id]

    room = _.find(this.audioRooms(), r => {
      let rect = makeRectFromAudioRoom(r)

      // When we're animating, we also check if startPos is in the rect. This
      // handles the case where you're moving from one location in Audio Room X
      // to another location, also in Audio Room X. We want to make sure we
      // don't disconnect you if you're just moving around inside a room.
      if (options.ignoringServer) {
        return rect.contains(avatar.pos) && (!animation || rect.contains(animation.startPos))
      } else {
        // This line of code is why server_pos exists. We need to wait
        // Until the server sees us as in the room to request a Twilio token,
        // since if request it when the server doesn't think we're there yet,
        // it won't give it to us (for security reasons)
        return rect.contains(avatar.pos) && rect.contains(avatar.server_pos) && (!animation || rect.contains(animation.startPos))
      }
    })

    return room
  }

  connectToAudioRoom() {
    const avatar = this.ourAvatar()

    if (!avatar) {
      return
    }

    const room = this.currentAudioRoom(avatar)

    const { twilioRoom } = this.state;

    // in a room and staying in it
    if (room && twilioRoom && room.id === twilioRoom.room.id) {
      return;
    // wasn't in a room and still not in a room
    } else if (!room && !twilioRoom) {
      return;
    }

    // At this point we're definitely transitioning.
    // We're either joining a room, leaving a room or
    // changing rooms (which is both a join and a leave).

    let newTwilioRoom = null;

    // Leave our old room
    if (twilioRoom) {
      twilioRoom.disconnect();
    }

    // Join a new room
    if (room) {
      newTwilioRoom = new TwilioRoom(room)

      newTwilioRoom.onConnect = () => {
        this.ui.setState({twilioConnected: true})
      }

      newTwilioRoom.onReconnecting = () => {
        this.ui.setState({twilioConnected: false})
      }

      newTwilioRoom.onDisconnect = () => {
        this.ui.setState({twilioRoom: null, twilioConnected: false})
        this.setState({twilioRoom: null})
      }

      this.subscription.requestTwilioToken(room);
    }

    this.ui.setState({ twilioRoom: newTwilioRoom });
    this.setState({ twilioRoom: newTwilioRoom });
  }

  connectTwilioRoom(token) {
    const { twilioRoom } = this.state;
    const avatar = this.ourAvatar()

    if (twilioRoom) {
      twilioRoom.connect(token, {startMuted: avatar?.muted})
    }
  }

  zoomLinks = () => {
    const { zoomLinkIds, entities } = this.state

    if (!zoomLinkIds) {
      return []
    }

    const indexKeys = zoomLinkIds.map(id => this.idToPosIndex[id])

    return _.compact(indexKeys.map(key => {
      return _.find(entities[key], e => e.type === 'ZoomLink' && this.isVisible(e))
    }))
  }

  audioRooms = () => {
    const { audioRoomIds, entities } = this.state;

    if (!audioRoomIds) {
      return [];
    }

    const indexKeys = audioRoomIds.map(id => this.idToPosIndex[id]);

    return _.compact(indexKeys.map(key => {
      return _.find(entities[key], e => e.type === 'AudioRoom' && this.isVisible(e));
    }));
  }

  anyExpiringAudioRooms() {
    return _.any(this.audioRooms(), r => r.expires_at);
  }

  isInAudioRoom(entity) {
    return _.any(this.audioRooms(), r => {
      const rect = makeRectFromAudioRoom(r)

      return rect.contains(entity.pos);
    })
  }

  mediaPlayerForAudioRoom(audioRoom) {
    return this.state.mediaPlayerIndex[audioRoom.id] ?? null
  }

  screenShareForAudioRoom(audioRoom) {
    return this.state.screenShareIndex[audioRoom.id] ?? null
  }

  sharedTimerForAudioRoom(audioRoom) {
    return this.state.sharedTimerIndex[audioRoom.id] ?? null
  }

  mediaPlayerForAvatar(avatar) {
    const audioRoom = this.currentAudioRoom(avatar, {ignoringServer: true})

    if (audioRoom) {
      return this.mediaPlayerForAudioRoom(audioRoom)
    } else {
      return null
    }
  }

  ourAvatar = () => {
    const { entities } = this.state;

    return this.findOurAvatar(entities)
  }

  findOurAvatar(entities) {
    const { avatar_id } = this;

    const key = this.idToPosIndex[avatar_id];

    if (!key) {
      return null;
    }

    return _.find(entities[key], e => e.id === avatar_id);
  }

  ourDesk = () => {
    const { desk_id } = this;
    const { entities } = this.state;

    const key = this.idToPosIndex[desk_id];

    if (!key) {
      return null;
    }

    return _.find(entities[key], e => e.id === desk_id);
  }

  posFacing() {
    return this.state.posFacing
  }

  isFacing(pos) {
    return _.isEqual(this.state.posFacing, pos)
  }

  isMouseOver(pos) {
    return _.isEqual(this.state.mousePos, pos)
  }

  findPosFacing(avatar) {
    if (!avatar) {
      return null;
    }

    const { direction } = avatar;
    return this.moved(avatar.pos, direction)
  }

  shouldHighlight(block) {
    return this.isFacing(block.pos) || this.isMouseOver(block.pos)
  }

  shouldHighlightAudioRoom(audioRoom) {
    const { posFacing, mousePos } = this.state
    const facingBlock = posFacing ? this.blockAt(posFacing) : null
    const mouseBlock = mousePos ? this.blockAt(mousePos) : null
    return _.any(_.compact([facingBlock, mouseBlock]), b => b.type === "AudioBlock" && b.audio_room_id === audioRoom.id)
  }

  colorForBlock(block) {
    if (block.type === "Wall") {
      return block.color
    } else {
      return (this.shouldHighlight(block) && HIGHLIGHT_COLORS[block.type]) || block.color
    }
  }

  moved(point, direction) {
    const { x, y } = point

    switch (direction) {
      case "up":
      return {x, y: y-1};
      case "down":
      return {x, y: y+1};
      case "left":
      return {x: x-1, y};
      case "right":
      return {x: x+1, y};
    }
  }

  adjacentPositions(pos) {
    return [
      {x: pos.x-1, y: pos.y},
      {x: pos.x+1, y: pos.y},
      {x: pos.x, y: pos.y-1},
      {x: pos.x, y: pos.y+1},
    ].filter(p => this.isInWorld(p))
  }

  ENTITIES = {
    "Avatar": this.drawAvatar,
    "Wall": this.drawWall,
    "UnknownAvatar": this.drawUnknownAvatar,
    "ZoomLink": this.drawZoomLink,
    "Note": this.drawNote,
    "Link": this.drawLink,
    "RC::Calendar": this.drawCalendar,
    "AudioRoom": this.drawAudioRoom,
    "Desk": this.drawDesk,
    "AudioBlock": this.drawAudioBlock,
    "Bot": this.drawBot,
    "PhotoBlock": this.drawPhotoBlock,
  }

  drawEntities(entities) {
    _.each(entities, e => {
      const draw = this.ENTITIES[e.type];
      if (draw) {
        draw(e);
      }
    });
  }

  bringIntoView(pos) {
    const viewport = this.viewport()

    if (!viewport.contains(pos)) {
      const newViewport = Rect.centered(pos, viewport.size)
      const ourAvatar = this.ourAvatar()

      this.updateRealtimeData(ourAvatar.pos, ourAvatar.direction, this.clampViewport(newViewport.origin), ourAvatar.muted)
    }
  }

  spotlightPos = (pos) => {
    this.bringIntoView(pos)
    this.setState({spotlightedPos: pos, spotlightedEntity: null})
    clearTimeout(this.spotlightTimeout)
    this.spotlightTimeout = setTimeout(this.clearSpotlight, 2000)
  }

  spotlightEntity = (entity, options={}) => {
    if (!entity) {
      return
    }

    const {permanent} = options

    // If we're spotlighting an avatar, we reorder the entities at this pos so that the
    // avatar being spotlit is last this ensures they're drawn on top of the other avatars
    // sharing this pos when the spotlight happens
    if (entity.type === "Avatar") {
      let entities = this.entitiesAt(entity.pos)

      entities = _.reject(entities, e => e.id === entity.id).concat([entity])

      this.setState({
        entities: {
          ...this.state.entities,
          [indexKey(entity.pos)]: entities,
        }
      })
    }

    const width = entity.width || 1
    const height = entity.height || 1

    const center = {
      x: entity.pos.x + Math.floor(width/2),
      y: entity.pos.y + Math.floor(height/2),
    }

    this.bringIntoView(center)

    if (permanent) {
      this.setState({permanentSpotlightedEntity: entity})
    } else {
      this.setState({spotlightedEntity: entity, spotlightedPos: null})
      clearTimeout(this.spotlightTimeout)
      this.spotlightTimeout = setTimeout(this.clearSpotlight, 2000)
    }
  }

  clearSpotlight = (options={}) => {
    const {permanent} = options

    if (permanent) {
      this.setState({permanentSpotlightedEntity: null})
    } else {
      this.setState({spotlightedPos: null, spotlightedEntity: null})

      clearTimeout(this.spotlightTimeout)
      this.spotlightTimeout = null
    }

    const {permanentSpotlightedEntity, spotlightedEntity, spotlightedPos} = this.state
    const otherSpotlightedEntity = spotlightedEntity || permanentSpotlightedEntity

    if (otherSpotlightedEntity || spotlightedPos ) {
      this.bringIntoView(otherSpotlightedEntity?.pos || spotlightedPos)
    } else {
      this.spotlightCanvas.classList.remove('spotlight--visible', 'pointer-events-none')
    }
  }

  drawSpotlight(x, y) {
    const { viewportWidth, viewportHeight } = this.state

    const viewportPos = this.worldToViewport({x, y})

    x = viewportPos.x*WIDTH + WIDTH/2
    y = viewportPos.y*HEIGHT + HEIGHT/2

    this.spotlightCtx.save()

    this.spotlightCtx.clearRect(0, 0, viewportWidth, viewportHeight)
    this.spotlightCtx.fillStyle = 'rgba(0, 0, 0, 0.6)'
    this.spotlightCtx.fillRect(0, 0, viewportWidth, viewportHeight)

    this.spotlightCtx.beginPath()
    this.spotlightCtx.arc(x, y, WIDTH, 0, 2*Math.PI)
    this.spotlightCtx.clip()
    this.spotlightCtx.clearRect(0, 0, viewportWidth, viewportHeight)

    this.spotlightCtx.restore()
  }

  drawSpotlightRect(x, y, width, height) {
    const { viewportWidth, viewportHeight } = this.state

    const viewportPos = this.worldToViewport({x, y})

    // Add a half-tile border around the highlighted room.
    x = (viewportPos.x - 0.5) * WIDTH
    y = (viewportPos.y - 0.5) * HEIGHT

    this.spotlightCtx.save()

    this.spotlightCtx.clearRect(0, 0, viewportWidth, viewportHeight)
    this.spotlightCtx.fillStyle = 'rgba(0, 0, 0, 0.6)'
    this.spotlightCtx.fillRect(0, 0, viewportWidth, viewportHeight)

    drawRoundedRectPath(this.spotlightCtx, x, y, (width + 1) * WIDTH, (height + 1) * HEIGHT, 4)
    this.spotlightCtx.clip()
    this.spotlightCtx.clearRect(0, 0, viewportWidth, viewportHeight)

    this.spotlightCtx.restore()
  }

  isInWorld(pos) {
    const { worldRows, worldCols } = this;

    return pos.y >= 0 && pos.y < worldRows && pos.x >= 0 && pos.x < worldCols;
  }

  clampToWorld(pos) {
    const { worldRows, worldCols } = this;

    return {
      x: Math.max(0, Math.min(pos.x, worldCols-1)),
      y: Math.max(0, Math.min(pos.y, worldRows-1)),
    };
  }

  isInViewport(pos) {
    return this.viewport().contains(pos);
  }

  draw() {
    if (!this.dirty) {
      return;
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    const rooms = this.audioRooms();
    const viewport = this.viewport();

    for (let r of rooms) {
      let rect = makeRectFromAudioRoom(r);

      if (rect.overlaps(viewport)) {
        this.drawAudioRoom(r);
      }
    }

    const blocks = [], zoomLinksWithBadges = [], audioBlocks = [], bots = [], otherAvatars = []
    const { avatar_id } = this

    // To draw, we first iterate over all the squares in the world and pull out the entities
    // into lists according to what "layer" they're drawn at (you can think of them like z-indexes).
    _.times(this.viewportCols(), c => {
      _.times(this.viewportRows(), r => {
        const worldPos = this.viewportToWorld({x: c, y: r});

        // It is possible to create entities that are outside of the world.
        // Skip drawing them.
        if (!this.isInWorld(worldPos)) {
          return;
        }

        // blocks
        const block = this.blockAt(worldPos);

        if (block) {
          blocks.push(block)
        }

        // ZoomLink badges
        if (block?.type == "ZoomLink" && block.participant_count > 0) {
          zoomLinksWithBadges.push(block)
        }

        // Audio Block timer emoji
        if (block?.type == "AudioBlock") {
          audioBlocks.push(block)
        }

        // bots
        const botsHere = this.botsAt(worldPos)

        if (botsHere) {
          bots.push(...botsHere)
        }

        // avatars that are not our own
        let avatars = this.avatarsAndUnknownAvatarsAt(worldPos)
        const ourSquare = indexKey(worldPos) === this.idToPosIndex[avatar_id]
        const avatar = this.ourAvatar()

        if (avatar && ourSquare && !this.isAnimating(avatar)) {
          avatars = _.reject(avatars, a => a.id === avatar_id)
        }

        otherAvatars.push(...avatars)
      })
    })

    // Then, we draw the lists in the z-order we want the different entity types to appear on the map
    // Note: keep this order in sync with the order in EntityHoverOverlay().

    // blocks
    this.drawEntities(blocks)

    // grid
    this.drawGrid();

    // bots
    this.drawEntities(bots)

    // non-us avatars
    this.drawEntities(otherAvatars)

    // our avatar
    const avatar = this.ourAvatar();
    if (avatar && this.isInViewport(avatar.pos) && !this.isAnimating(avatar)) {
      this.drawEntities([avatar]);
    }

    // ZoomLink badges
    _.each(zoomLinksWithBadges, this.drawZoomLinkBadge)
    _.each(audioBlocks, this.drawAudioBlockTimerEmoji)

    // audio room drag rects
    this.drawAudioRoomDragRect();

    const { spotlightedEntity, spotlightedPos, permanentSpotlightedEntity } = this.state

    // Order of precedence:
    // - spotlightedEntity (temporary)
    // - spotlightedPos    (temporary)
    // - permanentSpotlightedEntity
    if (spotlightedEntity || (!spotlightedPos && permanentSpotlightedEntity)) {
      const stationaryEntity = spotlightedEntity || permanentSpotlightedEntity

      const entity = this.getAnimatingEntityForEntity(stationaryEntity) || stationaryEntity

      if (entity.width > 1 || entity.height > 1) {
        this.drawSpotlightRect(entity.pos.x, entity.pos.y, entity.width, entity.height)
      } else {
        this.drawSpotlight(entity.pos.x, entity.pos.y)
      }

      this.spotlightCanvas.classList.add('spotlight--visible')

      if (spotlightedEntity) {
        this.spotlightCanvas.classList.remove('pointer-events-none')
      } else {
        this.spotlightCanvas.classList.add('pointer-events-none')
      }
    } else if (this.state.spotlightedPos) {
      const pos = this.state.spotlightedPos
      this.drawSpotlight(pos.x, pos.y)

      this.spotlightCanvas.classList.add('spotlight--visible')
      this.spotlightCanvas.classList.remove('pointer-events-none')
    }

    this.dirty = false;
  }

  run = () => {
    this.update()
    if (this.frame % N_FRAMES == 0) {
      this.draw();
    }
    this.frame = (this.frame + 1) % N_FRAMES
    requestAnimationFrame(this.run)
  }
}
