import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment';
import _ from 'underscore';

import { classSet, focusNextTabIndex } from './utils'

function isInvalidDate(date) {
  return _.isNaN(date.getTime());
}

function parseDate(dateStr) {
  dateStr = dateStr.replace(/(\d+)(st|nd|rd|th)/, (match, num) => num);

  const date = new Date(dateStr);

  if (isInvalidDate(date)) {
    return null;
  } else {
    return date;
  }
}

function formatDate(date, format = 'MMMM Do, YYYY') {
  return moment(date).format(format);
}

function normalizedDateString(dateStr, format) {
  if (!dateStr || dateStr.trim() === '') {
    return '';
  }

  return formatDate(parseDate(dateStr) || new Date(), format);
}

function equalOrContains(n1, n2) {
  return n1 && n2 && (n1 === n2 || n1.contains(n2));
}

class DayCell extends Component {
  // We use mouse down instead of click so that we can prevent default
  // to keep the event from blurring the input field and closing the
  // calendar
  handleMouseDown = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.day);
  }

  render() {
    const { day, selectedDate, calendarMonth } = this.props;

    const classes = classSet({
      'calendar__cell': true,
      'calendar__cell--is-button': true,
      'calendar__cell--is-other-month': day.getMonth() !== calendarMonth.getMonth(),
      'calendar__cell--is-selected': moment(day).isSame(selectedDate, 'day'),
    });

    return (
      <button type="button" className={ classes } key={ moment(day).format('M/D/YYYY') } onMouseDown={ this.handleMouseDown }>
        { day.getDate() }
      </button>
    );
  }
}

function calendarMonth(date) {
  return moment(date || new Date()).startOf('month').toDate();
}

export class Calendar extends Component {
  state = {
    month: calendarMonth(this.props.selectedDate || new Date()),
  };

  componentDidUpdate(prevProps) {
    const oldDate = prevProps.selectedDate
    const newDate = this.props.selectedDate;

    if (newDate && !moment(oldDate).isSame(newDate, 'month')) {
      this.setState({ month: calendarMonth(newDate) });
    }
  }

  nextMonth = (e) => {
    e.preventDefault();
    this.setState({
      month: moment(this.state.month).add(1, 'month').toDate(),
    });
  }

  prevMonth = (e) => {
    e.preventDefault();
    this.setState({
      month: moment(this.state.month).subtract(1, 'month').toDate(),
    });
  }

  selectDate = (date) => {
    this.setState({month: calendarMonth(date)});
    this.props.selectDate(date);
  }

  selectToday = (e) => {
    e.preventDefault();
    this.selectDate(new Date());
  }

  cancelEvent = (e) => {
    e.preventDefault();
  }

  dayTable() {
    const { month } = this.state;

    const startOfRange = moment(month).startOf('month').startOf('week');
    const endOfRange = moment(month).endOf('month').endOf('week');

    let table = [];

    for (let m = startOfRange; m.isBefore(endOfRange); m = m.clone().add(1, 'day')) {
      if (m.day() === 0) {
        table.push([m.toDate()]);
      } else {
        _.last(table).push(m.toDate());
      }
    }

    return table;
  }

  render() {
    const table = this.dayTable();
    const dayHeaders = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    return (
      <div className="calendar" ref="calendar" onMouseDown={ this.cancelEvent }>
        <div className="calendar__row calendar__row--is-month-row">
          <button type="button" className="calendar__month-nav" onMouseDown={ this.prevMonth }>
            <i className="fa fa-angle-left" />
          </button>

          <span className="calendar__month">
            { moment(this.state.month).format('MMMM ’YY') }
          </span>

          <button type="button" className="calendar__month-nav" onMouseDown={ this.nextMonth }>
            <i className="fa fa-angle-right" />
          </button>
        </div>

        <div className="calendar__row">
          {
            dayHeaders.map(h =>
              <span key={ h } className="calendar__cell calendar__cell--is-header">
                { h }
              </span>
            )
          }
        </div>

        {
          table.map(week =>
            <div className="calendar__row" key={ moment(week[0]).format('[week-]M/D/YYYY') }>
              {
                week.map(day =>
                  <DayCell
                    key={ moment(day).format('M/D/YYYY') }
                    day={ day }
                    selectedDate={ this.props.selectedDate }
                    calendarMonth={ this.state.month }
                    onClick={ this.selectDate }
                  />
                )
              }
            </div>
          )
        }

        <div className="calendar__row">
          <button type="button" className="calendar__today" onMouseDown={ this.selectToday }>
            Today
          </button>
        </div>
      </div>
    );
  }
}

export default class DatePicker extends Component {
  state = {
    value: this.parseValue(this.props.value),
    showCalendar: false,
  };

  parseValue(value) {
    if (!value) {
      return '';
    } else if (_.isObject(value)) {
      return formatDate(moment(value).toDate(), this.props.format);
    } else {
      return normalizedDateString(value, this.props.format);
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClick)
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.parseValue(this.props.value) });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClick)
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  handleBlur = () => {
    const { onChange } = this.props;
    const value = normalizedDateString(this.state.value, this.props.format);

    if (onChange) {
      onChange(value);
    }

    this.setState({value, showCalendar: false})
    this.props.onHide()
  }

  handleGlobalClick = (e) => {
    const cal = ReactDOM.findDOMNode(this.refs.calendar);
    const calButton = ReactDOM.findDOMNode(this.refs.calendarButton);
    const input = ReactDOM.findDOMNode(this.refs.input);

    if (!equalOrContains(cal, e.target) && !equalOrContains(calButton, e.target) && !equalOrContains(input, e.target)) {
      this.setState({ showCalendar: false })
      this.props.onHide()
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Escape' && this.state.showCalendar) {
      this.setState({ showCalendar: false })
      this.props.onHide()
    }
  }

  toggleCalendar = (e) => {
    e.preventDefault();
    this.setState(prevState => {
      const showCalendar = !prevState.showCalendar
      showCalendar ? this.props.onShow() : this.props.onHide()
      return { showCalendar }
    });
  }

  showCalendar = () => {
    this.setState({ showCalendar: true })
    this.props.onShow()
  }


  selectDate = (date) => {
    this.setState({
      value: formatDate(date, this.props.format),
    }, () => {
      focusNextTabIndex(this.refs.input);
    });
  }

  date() {
    return parseDate(this.state.value);
  }

  render() {
    const { name } = this.props;
    const { value, showCalendar } = this.state;

    return (
      <div className="position-relative display-flex align-items-center full-width">
        <input
          ref="input"
          type="text"
          className="form__input"
          name={ name }
          value={ value }
          onChange={ this.handleChange }
          onBlur={ this.handleBlur }
          onFocus={ this.showCalendar }
          onClick={ this.showCalendar }
        />

        {
          showCalendar ?
          <Calendar
            ref="calendar"
            selectedDate={ this.date() }
            selectDate={ this.selectDate }
          /> :
          null
        }
      </div>
    );
  }
}

const validTimes = Array.from({length: 48}, (_, i) => {
  const isMorning = i < 24
  const minutes = (i % 2) * 30
  const hours = Math.floor(i / 2)

  return {
    value: [hours, minutes].join(':'),
    timeString: `${(hours % 12) || '12'}:${minutes || '00'} ${isMorning ? 'AM' : 'PM'}`
  }
})

export const TimePicker = ({time, onChange}) => <select
  value={time.join(':')}
  onChange={e => onChange(e.target.value.split(':').map(n => parseInt(n, 10)))}
  className="form__input"
>
  {validTimes.map(validTime => (
    <option key={validTime.timeString} value={validTime.value}>
      {validTime.timeString}
    </option>
  ))}
</select>
