import React, { useEffect, useRef } from 'react'
import _ from 'underscore'

import consumer from './channels/consumer'

function stringRepresentation(channelName) {
  if (_.isString(channelName)) {
    return channelName
  } else {
    return _.keys(channelName).sort().map(k => JSON.stringify(k) + ":" + JSON.stringify(channelName[k])).join(",")
  }
}

export default function useActionCable(channelName, mixin, subscribed=true) {
  const subscription = useRef(null)
  const handlers = useRef(null)

  handlers.current = mixin

  useEffect(() => {
    if (!subscribed) {
      return
    }

    const proxy = {}

    for (let property of _.keys(mixin)) {
      proxy[property] = function(...args) {
        handlers.current[property].call(subscription.current, ...args)
      }
    }

    subscription.current = consumer.subscriptions.create(channelName, proxy)

    return () => {
      subscription.current.unsubscribe()
      subscription.current = null
    }
  }, [subscribed, stringRepresentation(channelName)])

  return subscription.current
}
