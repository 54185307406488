import { useState, useEffect } from 'react'

export default function useStickyState(defaultValue, key) {
    let storedValue = null
    if (window.localStorage) {
        storedValue = JSON.parse(localStorage.getItem(key))
    }

    const [value, setValue] = useState(storedValue ?? defaultValue)

    useEffect(() => {
        if (window.localStorage) {
            localStorage.setItem(key, JSON.stringify(value))
        }
    }, [value])

    return [value, setValue];
}
