import React from 'react'

import { classSet } from './utils';

const { Component } = React;

export default class Tooltip extends Component {
  static defaultProps = {
    placement: 'top',
  };

  state = {
    style: null,
  }

  tooltipRef = React.createRef()
  wrappedRef = React.createRef()

  componentDidMount() {
    // Ensure browser layout has completed so that widths are correct
    setTimeout(() => this.setTooltipPosition(), 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.text !== this.props.text || prevProps.placement !== this.props.placement) {
      this.setTooltipPosition();
    }
  }

  setTooltipPosition() {
    const tooltip = this.tooltipRef.current
    const wrapped = this.wrappedRef.current

    const { placement } = this.props;
    let { offset } = this.props;

    if (offset) {
      offset = `+ ${offset}`;
    } else {
      offset = "";
    }

    if (tooltip && wrapped) {
      if (placement === 'top') {
        this.setState({
          style: {
            left: (wrapped.offsetWidth - tooltip.offsetWidth) / 2 + 'px',
            bottom: `calc(100% + 0.3rem ${offset})`,
          }
        })
      } else if (placement === 'bottom') {
        this.setState({
          style: {
            left: (wrapped.offsetWidth - tooltip.offsetWidth) / 2 + 'px',
            top: `calc(100% + 0.3rem ${offset})`,
          }
        })
      } else if (placement === 'right') {
        this.setState({
          style: {
            top: (wrapped.offsetHeight - tooltip.offsetHeight) / 2 + 'px',
            left: `calc(100% + 0.6rem ${offset})`,
          }
        })
      } else if (placement === 'left') {
        this.setState({
          style: {
            top: (wrapped.offsetHeight - tooltip.offsetHeight) / 2 + 'px',
            right: `calc(100% + 0.6rem ${offset})`,
          }
        })
      } else if (placement === 'bottom-right') {
        this.setState({
          style: {
            left: '0px',
            top: `calc(100% + 0.3rem ${offset})`,
          }
        })
      }
    }
  }

  render() {
    const { text, disabled, displayInline, className, placement, alwaysVisible, contentClassName, wrapperStyle } = this.props;
    const { style } = this.state

    if (disabled && alwaysVisible) {
      console.warn("Tooltip is declared `disabled` and `alwaysVisible`, which doesn't make sense");
    }

    const wrapperClassName = classSet({
      'tooltip': true,
      'tooltip--disabled': disabled,
      'tooltip--always-visible': alwaysVisible && style,
      [this.props.wrapperClassName]: !!this.props.wrapperClassName,
      'tooltip--inline': displayInline,
    });

    const tooltipClassName = classSet({
      'tooltip__tooltip': true,
      [`tooltip__tooltip-${placement}`]: true,
      [className]: !!className,
      'visibility-hidden': !style,
    });

    return (
      <div className={ wrapperClassName } style={ wrapperStyle }>
        <div className={ tooltipClassName } ref={ this.tooltipRef } style={ style }>
          { text }
        </div>

        <div className={ contentClassName } ref={ this.wrappedRef }>
          { this.props.children }
        </div>
      </div>
    );
  }
}
