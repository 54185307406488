import React from 'react'

import Tooltip from '../tooltip'

export default function SafeModeIndicator({safeMode}) {
  return (
    <Tooltip text="Apps in safe mode can only modify and delete blocks they create">
      <div className="font-size-xsmall color-gray position-relative">
        {
          safeMode ?
          <><i className="fas fa-circle color-green font-size-xxsmall m-r-half" />Safe mode on</> :
          <><i className="fas fa-circle color-orange font-size-xxsmall m-r-half" />Safe mode off</>
        }
      </div>
    </Tooltip>
  )
}
