import React, { useState } from 'react'

// Rails 6.1: Make sure the interface to DirectUpload hasn't changed.
import { DirectUpload } from '@rails/activestorage'
import { destroy } from '@rails/request.js'

class ActiveStorageBlob {
  constructor(attributes) {
    this.attributes = attributes
  }

  get signedId() {
    return this.attributes.signed_id
  }

  get fileName() {
    return this.attributes.filename
  }

  get url() {
    return `/rails/active_storage/blobs/${this.signedId}/${this.fileName}`
  }

  async destroy() {
    return destroy(`/blobs/${this.signedId}`)
  }
}

export default function useDirectUpload() {
  const [directUpload, setDirectUpload] = useState(null)
  const [progress, setProgress] = useState(0)

  async function upload(file) {
    if (directUpload) {
      return Promise.reject("There can only be one direct upload at a time.")
    }

    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads', {
      directUploadWillCreateBlobWithXHR(xhr) {
      },

      directUploadWillStoreFileWithXHR(xhr) {
        xhr.upload.addEventListener('progress', event => {
          const newProgress = Math.ceil(100* event.loaded/event.total)
          setProgress(newProgress)
        })
      }
    })

    setDirectUpload(upload)

    return new Promise((resolve, reject) => {
      upload.create((error, attributes) => {
        setDirectUpload(null)
        setProgress(0)

        if (error) {
          reject(error)
        } else {
          resolve(new ActiveStorageBlob(attributes))
        }
      })
    })
  }

  const uploading = !!directUpload

  return [upload, uploading, progress]
}
