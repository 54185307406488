import { Controller } from "@hotwired/stimulus"

import CanvasWorld from "../grid_world"

// Connects to data-controller="grid-world"
export default class extends Controller {
  static targets = ["canvas", "spotlightCanvas", "reactRoot"]

  connect() {
    const canvas = this.canvasTarget
    const spotlightCanvas = this.spotlightCanvasTarget
    const reactRoot = this.reactRootTarget

    window.g = new CanvasWorld(canvas, spotlightCanvas, reactRoot)
  }
}
