import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore'

import { classSet } from './utils'

const { PureComponent } = React

export default class Modal extends PureComponent {
  el = document.createElement('div')

  componentDidMount() {
    document.body.appendChild(this.el)

    this.scrollTop = document.body.scrollTop;
    document.body.style.top = `${-this.scrollTop}px`

    this.alreadyOverflowHidden = _.contains(document.body.classList, 'overflow-hidden')

    if (!this.alreadyOverflowHidden) {
      document.body.classList.add('overflow-hidden')
    }

    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)

    document.body.style.top = ''

    if (!this.alreadyOverflowHidden) {
      document.body.classList.remove('overflow-hidden')
    }

    document.body.scrollTop = this.scrollTop

    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        if (this.props.onClose) {
          this.props.onClose()
        }
        break
    }
  }

  render() {
    const { children, minWidth, maxWidth, contentClassName, noscroll } = this.props;

    const classes = classSet({
      modal: true,
      "modal--noscroll": noscroll,
    })

    const contentClasses = classSet({
      'modal__content': true,
      [contentClassName]: !!contentClassName
    })

    return (
      ReactDOM.createPortal(
        <div className={ classes }>
          <div className="modal__window" style={{ minWidth, maxWidth }}>
            <div className={ contentClasses }>
              { children }
            </div>
          </div>
        </div>,
      this.el)
    )
  }
}
