import _ from 'underscore'

// classSet("foo", {bar: true, baz: false}, "qux")
//   => "foo bar qux"
export function classSet(...args) {
  const classes = args.map(a => {
    if (_.isObject(a)) {
      return _.keys(_.pick(a, (v, k) => !!v));
    } else {
      return a;
    }
  }).flat();

  return classes.join(' ');
}

const singularizations = {
  companies: 'company',
  people: 'person',
}

export function singularize(s) {
  if (singularizations[s]) {
    return singularizations[s]
  } else if (_.last(s) === 's') {
    return s.slice(0, s.length - 1)
  } else {
    return s
  }
}

const pluralizations = {
  company: 'companies',
  person: 'people',
}

export function pluralize(count, word) {
  if (count === 1) return "1 " + word
  if (pluralizations[word]) return count + " " + pluralizations[word]
  return count + " " + word + "s"
}

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

// This causes negative numbers to wrap around up to a max of `right`, e.g.,
// betterModulo(-1, 5) == 4 vs. JavaScript's normal operator: -1 % 5 == -1
export function betterModulo(left, right) {
  if (left >= 0) {
    return left % right
  }
  if (left >= -right) {
    return left + right
  }

  return ((left % right) + right) % right
}

export function transformValues(o, f) {
  return _.object(_.map(o, (v, k) => [k, f(v)]))
}

export function sendDesktopNotification(title, opts, onClick) {
  if (window.Notification) {
    if (Notification.permission === 'default') {
      Notification.requestPermission(function () { sendDesktopNotification(title, opts) })
    } else if (Notification.permission === 'granted') {
      var n = new Notification(title, opts)
      n.onclick = function (e) {
        if (onClick) {
          onClick(n, e)
        } else {
          window.focus()
        }
      }
    } // else denied
  }
}

export function requestDesktopNotificationPermission() {
  if (window.Notification && Notification.permission === 'default') {
    Notification.requestPermission()
  }
}

export function dig(o, ...keys) {
  for (let k of keys) {
    o = o[k];

    if (!o) {
      return null;
    }
  }

  return o;
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8)
    return v.toString(16)
  })
}


export function replaceBy(xs, x, key) {
  let newXs
  if (_.includes(_.map(xs, key), x[key])) {
    newXs = xs.map(oldX => {
      if (oldX[key] === x[key]) {
        return x
      } else {
        return oldX
      }
    })
  } else {
    newXs = xs.concat(x)
  }

  return newXs
}

export function focusNextTabIndex(element) {
  const next = document.querySelector(`[tabindex="${element.tabIndex+1}"]`);
  if (next) {
    next.focus();
  } else {
    element.blur();
  }
}

export function playSound(id) {
  document.getElementById(id).play();
}
