import React from 'react'

// A component that allows you to register a click handler for
// anything outside its children. Useful for closing modals and
// popups.
export default class ClickOutside extends React.Component {
  handleClick = (e) => {
    const node = this.container

    if (node === e.target || node.contains(e.target)) {
      return
    }

    this.props.onClick(e)
  }

  componentDidMount() {
    const trigger = this.props.trigger || 'click'

    document.addEventListener(trigger, this.handleClick)
  }

  componentWillUnmount() {
    const trigger = this.props.trigger || 'click'

    document.removeEventListener(trigger, this.handleClick)
  }

  render() {
    return (
      <div ref={ el => this.container = el }>
        { this.props.children }
      </div>
    )
  }
}
