import { useEffect, useRef } from 'react'

// Note: this probably won’t clean up properly if multiple sibling components
//       simultaneously use this hook.
export default function useTitle(title) {
  const initialTitle = useRef(document.title)
  useEffect(() => () => document.title = initialTitle.current, [])

  useEffect(() => {
    document.title = title ? `${title} | ${initialTitle.current}` : initialTitle.current
  }, [title])
}
